import { action, computed, observable } from "mobx";

import { KnownLocale } from "nvent-web/i18n";

import { UserStore } from "./User";

export class AppStore {
  @observable
  isSideMenuVisible = false;

  @computed
  get locale(): KnownLocale {
    return this.user.locale;
  }

  constructor(private user: UserStore) {}

  @action.bound
  toggleSideMenu(visible = !this.isSideMenuVisible) {
    this.isSideMenuVisible = visible;
  }

  showSideMenu = () => this.toggleSideMenu(true);
  hideSideMenu = () => this.toggleSideMenu(false);
}

export default AppStore;
