import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { TextField } from "nvent-web/components/form/TextField";

interface RoomAreasProps extends InjectedIntlProps {
  roomAreaName?: string;
  installableAreaName?: string;
  roomAreaLabel?: React.ReactNode;
  installableAreaLabel?: React.ReactNode;
  required?: boolean;
}

const RoomAreas: FunctionComponent<RoomAreasProps> = (props) => {
  const {
    roomAreaName = "area",
    installableAreaName = "installableArea",
    roomAreaLabel,
    installableAreaLabel,
    required,
  } = props;

  const roomAreaLabelValue = roomAreaLabel || <FormattedMessage id="form.roomArea" />;
  const installableAreaLabelValue = installableAreaLabel || <FormattedMessage id="form.installableArea" />;

  return (
    <>
      <TextField name={roomAreaName} label={roomAreaLabelValue} required={required} />
      <TextField name={installableAreaName} label={installableAreaLabelValue} required={required} />
    </>
  );
};

export default injectIntl(RoomAreas);
