import classnames from "classnames";
import { Component } from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps, withRouter } from "react-router";

import { ReactComponent as ArrowBackIcon } from "nvent-web/assets/svg/arrow-left.svg";

import LocalePicker from "../../../scenes/Auth/components/LocalePicker";
import Header from "../Header";

import style from "./HeaderTitle.module.scss";

export interface HeaderTitleProps extends RouteComponentProps {
  hideBackButton?: boolean;
}

class HeaderTitle extends Component<HeaderTitleProps> {
  private el: HTMLElement;

  constructor(props: HeaderTitleProps) {
    super(props);

    this.el = document.createElement("div");
    this.el.classList.add(style.headerContent);
  }

  componentDidMount() {
    const title = Header.getTitleContainer();

    if (title) {
      title.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    const title = Header.getTitleContainer();

    if (title) {
      title.removeChild(this.el);
    }
  }

  renderHeaderTitle() {
    const { hideBackButton } = this.props;

    return (
      <>
        {!hideBackButton && (
          <div className={style.arrowIconContainer} onClick={this.onGoBack} role="button" tabIndex={0}>
            <ArrowBackIcon />
          </div>
        )}
        <div className={classnames({ [style.titleContainer]: !hideBackButton })}>{this.props.children}</div>
        <div className={style.picker}>
          <LocalePicker dark />
        </div>
      </>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderHeaderTitle(), this.el);
  }

  private onGoBack = () => {
    this.props.history.goBack();
  };
}

export default withRouter(HeaderTitle);
