import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { compose } from "recompose";

import AppStore from "nvent-web/stores/App";

import style from "./SidebarMenuItem.module.scss";

interface SidebarMenuItemProps {
  path: string;
  icon: React.ReactNode;
  count?: number;
  isExternal?: boolean;
}

interface SidebarMenuItemInnerProps extends SidebarMenuItemProps, InjectedIntlProps {
  app: AppStore;
}

class SidebarMenuItemInner extends Component<SidebarMenuItemInnerProps, Record<string, unknown>> {
  render() {
    const { app, path, isExternal = false } = this.props;

    return isExternal ? (
      <a className={style.menuItem} target="_blank" rel="noopener noreferrer" href={path} key={path}>
        {this.renderMenuItem()}
      </a>
    ) : (
      <NavLink
        className={style.menuItem}
        activeClassName={style.activeLink}
        exact={false}
        to={path}
        key={path}
        onClick={app.hideSideMenu}
      >
        {this.renderMenuItem()}
      </NavLink>
    );
  }

  private renderMenuItem() {
    const { icon, children, count } = this.props;

    return (
      <>
        <div className={style.simpleItem}>
          <div className={style.itemIcon}>{icon}</div>
          <div className={style.itemText}>{children}</div>
        </div>
        {typeof count !== "undefined" && <div className={style.itemCounter}>{count}</div>}
      </>
    );
  }
}

export const SidebarMenuItem = compose<SidebarMenuItemInnerProps, SidebarMenuItemProps>(
  injectIntl,
  inject("app"),
  observer
)(SidebarMenuItemInner);

export default SidebarMenuItem;
