import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

export type CopyActionProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const CopyAction: FunctionComponent<CopyActionProps> = ({ className, ...buttonProps }) => (
  <button {...buttonProps} type="button" className={className}>
    <FormattedMessage id="actions.copy" />
  </button>
);
