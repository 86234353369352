import classNames from "classnames";
import { ChangeEvent, Component, ReactNode } from "react";

import style from "./Checkbox.module.scss";

interface CheckboxProps {
  name: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  type?: "checkbox" | "radio";
  className?: string;
  label: ReactNode;
}

export class Checkbox extends Component<CheckboxProps> {
  render() {
    const { type = "checkbox", className, name, value, label } = this.props;

    return (
      <div className={style.wrapper}>
        <div
          className={classNames({
            [style.checkboxContainer]: type === "checkbox",
            [style.radioContainer]: type === "radio",
          })}
        >
          <input
            id={name}
            type={type}
            name={name}
            checked={value}
            onChange={this.handleChange}
            className={classNames(style.input, className)}
          />
          <label className={style.label} htmlFor={name}>
            {label}
          </label>
        </div>
      </div>
    );
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    this.props.onChange(checked);
  };
}
