import { ComponentType, createContext, useContext } from "react";

import { AppAuth0 } from "./useCreateAppAuth0";

export const AppAuth0Context = createContext<{ value: AppAuth0 }>({
  get value(): never {
    throw new Error("Missing AppAuth0Provider");
  },
});

export const useAppAuth0 = () => {
  return useContext(AppAuth0Context).value;
};

export function withAppAuth0<ComponentProps extends { auth0: AppAuth0 }>(Component: ComponentType<ComponentProps>) {
  return (props: Omit<ComponentProps, "auth0">) => {
    const auth0 = useAppAuth0();
    const componentProps = { ...props, auth0 } as ComponentProps;

    return <Component {...componentProps} />;
  };
}
