import { FunctionComponent } from "react";

import style from "./Progress.module.scss";

export interface ProgressProps {
  progress: number;
}

const Progress: FunctionComponent<ProgressProps> = ({ progress }) => (
  <div className={style.progressWrapper}>
    <div className={style.progress} style={{ width: `${progress * 100}%` }} />
  </div>
);

export default Progress;
