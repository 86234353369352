import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { DropdownField } from "nvent-web/components/form/DropdownField";
import AppStore from "nvent-web/stores/App";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { createOption } from "nvent-web/utils/create-option";

interface FloorConstructionFieldProps {
  name?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
}

interface FloorConstructionFieldInnerProps extends FloorConstructionFieldProps, InjectedIntlProps {
  app: AppStore;
  rooms: RoomsStore;
}

class FloorConstructionFieldInner extends Component<FloorConstructionFieldInnerProps> {
  @computed
  private get floorConstructionOptions(): DropdownOption[] {
    const { floorConstructionOptions } = this.props.rooms;
    const { locale } = this.props.app;

    return floorConstructionOptions.map((option) => createOption(option, locale));
  }

  render() {
    const { intl, name = "floorConstruction", label, placeholder, required } = this.props;

    const labelValue = label || <FormattedMessage id="form.floorConstruction" />;
    const placeholderValue = placeholder || intl.formatMessage({ id: "form.floorConstruction.selectPlaceholder" });

    return (
      <DropdownField
        name={name}
        label={labelValue}
        options={this.floorConstructionOptions}
        placeholder={placeholderValue}
        required={required}
      />
    );
  }
}

const FloorConstructionField = compose<FloorConstructionFieldInnerProps, FloorConstructionFieldProps>(
  injectIntl,
  inject("app", "rooms"),
  observer
)(FloorConstructionFieldInner);

export default FloorConstructionField;
