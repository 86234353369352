import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import { ReactComponent as ArrowDownIcon } from "nvent-web/assets/svg/arrowDown.svg";
import { SearchInput } from "nvent-web/components/form/SearchInput";
import { ProjectList } from "nvent-web/components/ProjectList";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { UserStore } from "nvent-web/stores/User";

import ProjectsTabs from "./components/ProjectsTabs";
import style from "./FinishedProjects.module.scss";

interface ProjectsProps extends InjectedIntlProps {
  projects: ProjectsStore;
  user: UserStore;
}

class FinishedProjects extends Component<ProjectsProps> {
  @observable
  private archivedProjectsVisible = true;

  private search = this.props.projects.buildSearch({
    status: "finished",
    owner: "my",
  });

  render() {
    const {
      intl,
      user,
      projects: { projects },
    } = this.props;

    const teamProjects = projects.filter(({ finished, archivedAt }) => finished && !archivedAt);
    const myProjects = teamProjects.filter(({ installerId }) => installerId === user.id);
    const archivedProjects = this.search.results.filter(({ archivedAt }) => archivedAt);
    const finishedProjects = this.search.results.filter(({ archivedAt }) => !archivedAt);

    return (
      <>
        <PageTitle hideBackButton>
          <FormattedMessage id="projects.finished.title" />
        </PageTitle>

        <ProjectsTabs
          selected={this.search.owner}
          onSelect={this.search.ownerChanged}
          counts={{
            my: myProjects.length,
            team: teamProjects.length,
          }}
          classNames={{ tabs: style.tabsWrapper }}
        />

        <SearchInput
          name="q"
          placeholder={intl.formatMessage({ id: "projects.finished.search" })}
          onSearch={this.search.searchChanged}
          className={{ wrapper: style.searchWrapper }}
        />

        <div className={style.listWrapper}>
          <ProjectList
            projectsList={finishedProjects}
            isLoading={!finishedProjects.length && this.search.areResultsLoading}
            emptyMessage={<FormattedMessage id="projects.finished.noContent" />}
          />
          {archivedProjects.length > 0 && (
            <section>
              <div className={style.archivedProjectsHeader}>
                <FormattedMessage id="projects.archivedProjects" />
                <div className={style.arrowWrapper} onClick={this.toggleArchivedProjects} role="button" tabIndex={0}>
                  <ArrowDownIcon className={this.archivedProjectsVisible ? style.up : style.down} />
                </div>
              </div>

              {this.archivedProjectsVisible && (
                <ProjectList
                  projectsList={archivedProjects}
                  isLoading={!archivedProjects.length && this.search.areResultsLoading}
                />
              )}
            </section>
          )}
        </div>
      </>
    );
  }
  private toggleArchivedProjects = () => {
    this.archivedProjectsVisible = !this.archivedProjectsVisible;
  };
}

export default compose<ProjectsProps, Record<string, unknown>>(
  injectIntl,
  inject("projects", "user"),
  observer
)(FinishedProjects);
