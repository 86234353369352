import { History } from "history";
import { useHistory } from "react-router-dom";

export function getBaseUrl(history: History) {
  return window.location.origin + history.createHref({}).slice(0, -1);
}

export function useBaseUrl() {
  const history = useHistory();

  return getBaseUrl(history);
}
