import { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { compose } from "recompose";

import LoginPage from "./components/LoginPage";

export type LoginInnerProps = RouteComponentProps;

export class LoginInner extends Component<LoginInnerProps> {
  render() {
    const { path, url } = this.props.match;

    return (
      <Switch>
        <Route path={path} exact={true} component={LoginPage} />
        <Redirect to={url} />
      </Switch>
    );
  }
}

export const Login = compose<LoginInnerProps, Record<string, unknown>>(withRouter)(LoginInner);
export default Login;
