import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { PrimaryBlueButton, SecondaryButton } from "nvent-web/components/Button";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";

import Modal, { ModalProps } from "..";

import style from "./ConfirmationModal.module.scss";

interface ConfirmationProps {
  title: ReactNode;
  description: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  descriptionClass?: string;
  loading?: boolean;
}

interface ConfirmationModalProps extends ModalProps, ConfirmationProps {}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  descriptionClass,
  loading,
  ...modalProps
}) => (
  <Modal {...modalProps}>
    <h3 className={style.title}>{title}</h3>
    <p className={classNames(style.description, descriptionClass)}>{description}</p>
    <div className={style.actions}>
      <SecondaryButton className={style.cancelBtn} onClick={onCancel}>
        <FormattedMessage id="actions.cancel" />
      </SecondaryButton>
      <PrimaryBlueButton
        className={classNames(style.yesBtn, { [style.loading]: loading })}
        onClick={onConfirm}
        disabled={loading}
      >
        {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.yes" />}
      </PrimaryBlueButton>
    </div>
  </Modal>
);

export default ConfirmationModal;
