import { History } from "history";
import { Component, ComponentType } from "react";
import GoogleAnalytics from "react-ga";
import { RouteComponentProps, withRouter } from "react-router";

export interface TrackerProps extends RouteComponentProps {
  history: History;
}

const withTracker = <P extends RouteComponentProps>(WrappedComponent: ComponentType<P>, options = {}) => {
  const gaApiKey = process.env.REACT_APP_GA_API_KEY;

  const trackPage = (page: string) => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  class HOC extends Component<P & TrackerProps> {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps: P & TrackerProps) {
      const { pathname, search } = this.props.location;
      const { pathname: prevPathname, search: prevSearch } = prevProps.location;

      const currentPage = prevPathname + prevSearch;
      const nextPage = pathname + search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return gaApiKey ? withRouter(HOC) : WrappedComponent;
};

export default withTracker;
