import classNames from "classnames";
import { Field, FieldConfig, FieldProps } from "formik";
import { ChangeEvent, Component } from "react";

import style from "./CheckboxField.module.scss";
import { FieldError } from "./FieldError";

type InputProps = Omit<React.InputHTMLAttributes<HTMLElement>, keyof FieldConfig | "className" | "onChange">;

export interface CheckboxFieldProps extends InputProps, FieldConfig {
  type?: CheckboxProps["type"];
  label: React.ReactNode;
  className?: {
    wrapper?: string;
    container?: string;
    label?: string;
    input?: string;
    inputContainer?: string;
  };
  prompt?: React.ReactNode;
  onChange?: (name: string, checked: boolean) => void;
}

export class CheckboxField extends Component<CheckboxFieldProps> {
  render() {
    const { type = "checkbox", name, label, prompt, required, className = {}, onChange } = this.props;

    return (
      <div className={classNames(style.wrapper, className.wrapper)}>
        <div
          className={classNames(className.inputContainer, className.container, {
            [style.checkboxContainer]: type === "checkbox",
            [style.radioContainer]: type === "radio",
          })}
        >
          <Field
            name={name}
            render={(fieldProps: FieldProps) => (
              <Checkbox type={type} className={className.input} {...fieldProps} onChange={onChange} />
            )}
          />
          <label className={classNames(style.label, className.label)} htmlFor={name}>
            {label}
            {required && "*"}
          </label>
          {prompt && <p className={style.prompt}>{prompt}</p>}
          <FieldError name={name} />
        </div>
      </div>
    );
  }
}

interface CheckboxProps extends FieldProps {
  type?: "checkbox" | "radio";
  className?: string;
  onChange?: (name: string, checked: boolean) => void;
}

class Checkbox extends Component<CheckboxProps> {
  render() {
    const { type = "checkbox", className } = this.props;
    const { name, value, onBlur } = this.props.field;

    return (
      <input
        id={name}
        type={type}
        name={name}
        checked={value}
        onChange={this.handleChange}
        onBlur={onBlur}
        className={classNames(style.input, className)}
      />
    );
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const { name } = this.props.field;
    const { setFieldValue, setFieldTouched } = this.props.form;

    setFieldTouched(name, true);
    setFieldValue(name, checked);

    if (this.props.onChange) {
      this.props.onChange(name, checked);
    }
  };
}
