import { computed } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { DropdownOption } from "nvent-web/types/DropdownOption";
import { countryCodes } from "nvent-web/utils/countryCodes";
import { getCountryNameByCode } from "nvent-web/utils/getCountryNameByCode";

import { DropdownField } from "./DropdownField";
import { DropdownFieldProps } from "./DropdownField";

type CountriesFieldProps = Omit<DropdownFieldProps, "options">;

interface CountriesFieldInnerProps extends CountriesFieldProps, InjectedIntlProps {}

class CountriesFieldInner extends Component<CountriesFieldInnerProps> {
  @computed
  get countriesWithIsos(): DropdownOption[] {
    const { locale } = this.props.intl;
    return countryCodes.map((iso) => {
      return {
        key: iso,
        title: getCountryNameByCode(iso, locale),
      };
    });
  }

  render() {
    return <DropdownField options={this.countriesWithIsos} {...this.props} />;
  }
}

const CountriesField = compose<CountriesFieldInnerProps, CountriesFieldProps>(
  injectIntl,
  observer
)(CountriesFieldInner);
export default CountriesField;
