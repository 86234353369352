import Resource from "./Resource";

export class AuthResource extends Resource {
  async signIn(idToken: string) {
    return this.http.post<void>("auth", undefined, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
  }
}
