import isUndefined from "lodash-es/isUndefined";
import { charsets, PasswordPolicy } from "password-sheriff";
import * as yup from "yup";

import { buildMessage } from "nvent-web/i18n/yup";
import { COUNTRY_CODES } from "nvent-web/utils/constants";

declare module "yup" {
  export interface StringSchema {
    phoneNumber(message?: TestOptionsMessage): StringSchema;
    password(messages?: Partial<PasswordMessages>): StringSchema;
  }
}

const locale = {
  string: {
    phoneNumber: buildMessage("error.string.phoneNumber"),
    password: {
      min: buildMessage("error.string.min"),
      policy: buildMessage("error.string.password.policy"),
    },
  },
};

const isPhoneNumber = (phoneNumber: string) => {
  const sorting = (arr: string[]) => arr.sort((a, b) => Number(b) - Number(a));
  const countryCodes = sorting(COUNTRY_CODES);

  const countryCode = countryCodes.find((code) => phoneNumber.startsWith(code));
  if (!countryCode) {
    return false;
  }

  const phoneNumberWithoutCode = phoneNumber.substring(countryCode.length);
  return /^[0-9]([0-9 -])*[0-9]$/.test(phoneNumberWithoutCode);
};

yup.addMethod<yup.StringSchema>(yup.string, "phoneNumber", function (message = locale.string.phoneNumber) {
  return this.transform((value?: string) => value && value.replace(/[/+\s()-]+/g, "")).test({
    name: "phone-number",
    message,
    test: (value?: string): value is string => isUndefined(value) || isPhoneNumber(value),
  });
});

// Password validation has to be consistent with the Auth0 policy, which currently is set to good
// https://auth0.com/docs/connections/database/password-strength#password-policies
// - at least 8 characters
// - at least 3 of 4 kinds of characters (lower, upper, digit, special)
const passwordPolicy = new PasswordPolicy({
  length: { minLength: 8 },
  containsAtLeast: {
    atLeast: 3,
    expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
  },
});

export interface PasswordMessages {
  min: yup.TestOptionsMessage;
  policy: yup.TestOptionsMessage;
}

yup.addMethod<yup.StringSchema>(yup.string, "password", function (messages: Partial<PasswordMessages> = {}) {
  const mergedMessages = { ...locale.string.password, ...messages };
  return this.min(8, mergedMessages.min).test({
    name: "password-policy",
    message: mergedMessages.policy,
    test: (value?: string): value is string => isUndefined(value) || passwordPolicy.check(value),
  });
});
