import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { CountryFlag } from "nvent-web/components/CountryFlag/CountryFlag";
import { UserStore } from "nvent-web/stores/User";
import contactDataForCountries from "nvent-web/utils/contactDataForCountries";

import style from "./CustomerSupportNumbers.module.scss";

interface CustomerSupportNumbersInnerProps extends RouteComponentProps {
  user: UserStore;
}

const PREFERRED_COUNTRY_CODE_KEY = "support.preferredCountry";
class CustomerSupportNumbersInner extends Component<CustomerSupportNumbersInnerProps> {
  @computed
  get defaultPhoneNumber() {
    const { companyAddress } = this.props.user;
    return (
      contactDataForCountries.find(
        ({ countryCode }) => countryCode === (this.getSavedDefaultNumber() || companyAddress.countryCode)
      ) || contactDataForCountries[0]
    );
  }

  render() {
    return (
      <section className={style.wrapper}>
        <div className={style.numbersList}>
          <div className={style.numbersHeader}>
            <FormattedMessage id="customerSupport.yourCountry" />
          </div>
          {this.renderNumber(this.defaultPhoneNumber.countryCode, this.defaultPhoneNumber.phoneNumber)}
          <hr />
        </div>
        <div className={style.numbersList}>
          <div className={style.numbersHeader}>
            <FormattedMessage id="customerSupport.otherCountries" />
          </div>
          {contactDataForCountries
            .filter(({ countryCode }) => countryCode !== this.defaultPhoneNumber.countryCode)
            .map(({ countryCode, phoneNumber }) => this.renderNumber(countryCode, phoneNumber))}
        </div>
      </section>
    );
  }

  private renderNumber = (countryCode: string, phoneNumber: string) => {
    return (
      <div className={style.countryWithNumber} key={countryCode}>
        <CountryFlag countryCode={countryCode} className={style.biggerFlag} />
        <a href={`tel:${phoneNumber}`} className={style.phoneNumber} onClick={() => this.chooseNumber(countryCode)}>
          {phoneNumber}
        </a>
      </div>
    );
  };

  private goBack = () => this.props.history.goBack();

  private saveDefaultNumber = (countryCode: string) => {
    localStorage.setItem(PREFERRED_COUNTRY_CODE_KEY, countryCode);
  };

  private chooseNumber = (countryCode: string) => {
    // Changing default phone number should happen only once when choosing number for different country
    if (!this.getSavedDefaultNumber()) {
      this.saveDefaultNumber(countryCode);
    }
    this.goBack();
  };

  private getSavedDefaultNumber = () => localStorage.getItem(PREFERRED_COUNTRY_CODE_KEY);
}

const CustomerSupportNumbers = compose<CustomerSupportNumbersInnerProps, Record<string, unknown>>(
  withRouter,
  inject("user"),
  observer
)(CustomerSupportNumbersInner);

export default CustomerSupportNumbers;
