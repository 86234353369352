import classnames from "classnames";
import { autorun, computed, observable, reaction } from "mobx";
import { observer } from "mobx-react";
import { Component, createRef } from "react";

import { Notification, NotificationsStore } from "nvent-web/stores/Notifications";

import { Notification as NotificationComponent } from "./Notification";
import style from "./Notifications.module.scss";

export interface NotificationsProps {
  notifications: NotificationsStore;
}

class AnimatedNotification {
  @observable notification: Notification;
  @observable animationClassName?: "animateIn" | "animateOut";
  ref = createRef<HTMLLIElement>();

  @computed
  get key() {
    return this.notification.key;
  }

  @computed
  get isVisible() {
    return Boolean(this.notification.isVisible || this.animationClassName);
  }

  constructor(notification: Notification) {
    this.notification = notification;

    reaction(
      () => notification.isVisible,
      (isVisible) => {
        if (!isVisible && this.ref.current) {
          this.ref.current.style.height = `${this.ref.current.offsetHeight}px`;
        }

        this.animationClassName = isVisible ? "animateIn" : "animateOut";
      },
      { fireImmediately: true }
    );
  }

  animationEnded = () => {
    this.animationClassName = undefined;
  };
}

@observer
export class Notifications extends Component<NotificationsProps> {
  private animationsMap = observable.map<number, AnimatedNotification>();

  @computed
  get animations() {
    const { notifications } = this.props;
    return notifications.all.map(({ key }) => this.animationsMap.get(key));
  }

  @computed
  get visibleAnimations() {
    return this.animations.filter((animation): animation is AnimatedNotification => Boolean(animation?.isVisible));
  }

  constructor(props: NotificationsProps) {
    super(props);

    autorun(() => {
      this.props.notifications.all.forEach((notification) => {
        if (!this.animationsMap.has(notification.key)) {
          this.animationsMap.set(notification.key, new AnimatedNotification(notification));
        }
      });
    });
  }

  render() {
    return (
      <div className={style.container}>
        <ul className={style.list}>
          {this.visibleAnimations.map((animation) => (
            <li
              key={animation.key}
              ref={animation.ref}
              className={classnames(
                style.listItem,
                animation.animationClassName && style[animation.animationClassName]
              )}
              onAnimationEnd={animation.animationEnded}
            >
              <NotificationComponent notification={animation.notification} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
