import { NewProductSpec } from "nvent-web/types/NewProductSpec";
import { CategoryRecommendationVariant } from "nvent-web/types/ProductRecommendation";

export const recommendationToProductSpecs = (recommendation: CategoryRecommendationVariant): NewProductSpec[] => {
  const { productSkus, accessorySkus } = recommendation;

  return (accessorySkus ? [...productSkus, ...accessorySkus] : productSkus).map(
    ({ id, customLength = 0, quantity, extraConnectionLength = 0 }) => {
      const length = Number(customLength) + Number(extraConnectionLength);

      return {
        productSkuId: id,
        quantity,
        length: length ? length : undefined,
      };
    }
  );
};
