import classnames from "classnames";
import { ComponentType, FunctionComponent, ReactHTML } from "react";

import style from "./LoadingSpinner.module.scss";

export interface LoadingSpinnerProps {
  component?: keyof ReactHTML | ComponentType<{ className?: string }>;
  small?: boolean;
  className?: string;
}

export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  component: Component = "span",
  className,
  small,
}) => <Component className={classnames(className, style.spinner, { [style.small]: small })} />;
