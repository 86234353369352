import { decamelizeKeys } from "humps";

import { Accessory } from "nvent-web/types/Accessory";
import { Product } from "nvent-web/types/Product";
import { ProductCategory } from "nvent-web/types/ProductCategory";
import { ProductSku } from "nvent-web/types/ProductSku";
import { Thermostat } from "nvent-web/types/Thermostat";

import Resource from "./Resource";

export interface ProductCatalog {
  productCategories: ProductCategory[];
  products: Array<Product | Accessory | Thermostat>;
  productSkus: ProductSku[];
}

export default class ProductCatalogResource extends Resource {
  async getCatalog() {
    return this.http.get<ProductCatalog>("products/catalog");
  }

  async getCatalogForCountry(countryCode: string) {
    return this.http.get<ProductCatalog>("catalog", {
      params: decamelizeKeys({
        countryCode: countryCode.toUpperCase(),
      }),
    });
  }
}
