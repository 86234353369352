import { computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { Installer } from "nvent-web/types/Installer";

export class TeamInstallersStore {
  @observable installersPromise?: IPromiseBasedObservable<Installer[]>;

  @computed
  get installers() {
    if (!this.installersPromise) {
      return [];
    }

    return this.installersPromise.case({
      fulfilled: (installers) => installers,
      pending: () => [],
      rejected: () => [],
    });
  }

  @computed
  get installersById() {
    return observable.map<string, Installer>(this.installers.map((installer) => [installer.id, installer]));
  }

  @computed
  get hasMultipleInstallers() {
    return this.installers.length > 1;
  }

  constructor(private api: Api) {}

  loadInstallers(force = false) {
    if (force || !this.installersPromise) {
      this.installersPromise = fromPromise(this.api.team.installers.getAll().then(({ data }) => data));
    }

    return this.installersPromise;
  }
}
