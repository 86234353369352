import { FunctionComponent } from "react";

import DesktopTitle from "../DesktopTitle";
import HeaderTitle, { HeaderTitleProps } from "../HeaderTitle";

type PageTitleProps = Pick<HeaderTitleProps, "hideBackButton">;

const PageTitle: FunctionComponent<PageTitleProps> = ({ hideBackButton = false, children }) => {
  return (
    <>
      <HeaderTitle hideBackButton={hideBackButton}>{children}</HeaderTitle>
      <DesktopTitle>{children}</DesktopTitle>
    </>
  );
};

export default PageTitle;
