import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { compose } from "recompose";

import { KnownLocale, knownLocaleOptions } from "nvent-web/i18n";
import { UserStore } from "nvent-web/stores/User";

import style from "./LocalePicker.module.scss";

interface LocalePickerOuterProps {
  dark?: boolean;
}

interface LocalePickerInnerProps {
  user: UserStore;
  dark?: boolean;
}

class LocalePicker extends Component<LocalePickerInnerProps & LocalePickerOuterProps> {
  render() {
    const { user, dark } = this.props;

    return (
      <select
        className={classNames(style.select, { [style.dark]: dark })}
        value={user.locale}
        onChange={this.handleChange}
      >
        {knownLocaleOptions.map(({ key, title }) => (
          <option key={key} value={key}>
            {title}
          </option>
        ))}
      </select>
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { user } = this.props;

    user.setLocale(e.target.value as KnownLocale);
  };
}

export default compose<LocalePickerInnerProps, LocalePickerOuterProps>(inject("user"), observer)(LocalePicker);
