import classnames from "classnames";
import { FieldProps } from "formik";
import { ChangeEvent, Component, createRef, FocusEvent } from "react";

import { DropdownOption } from "nvent-web/types/DropdownOption";

import { AbstractField, AbstractFieldProps } from "./AbstractField";
import style from "./AutocompleteField.module.scss";

export interface AutocompleteOption extends DropdownOption {
  searchValue: string;
}

export interface AutocompleteFieldProps<T extends AutocompleteOption>
  extends Omit<AbstractFieldProps, "type" | "render" | "component" | "children" | "onChange"> {
  options: T[];
  filter: (value: string, option: T) => boolean;
  onChange?: (value: string) => void;
}

export class AutocompleteField<T extends AutocompleteOption> extends Component<AutocompleteFieldProps<T>> {
  render() {
    const { options, filter, onChange, ...fieldProps } = this.props;

    return (
      <AbstractField {...fieldProps}>
        {(props: FieldProps) => <Autocomplete onChange={onChange} options={options} filter={filter} {...props} />}
      </AbstractField>
    );
  }
}

interface AutocompleteProps<T extends AutocompleteOption>
  extends FieldProps,
    Pick<AbstractFieldProps, "stateColor" | "success"> {
  options: T[];
  placeholder?: string;
  className?: string;
  filter: (value: string, option: T) => boolean;
  onChange?: (value: string) => void;
}

interface AutocompleteState<T extends AutocompleteOption> {
  predictions: T[];
  arePredictionsVisible: boolean;
}

class Autocomplete<T extends AutocompleteOption> extends Component<AutocompleteProps<T>, AutocompleteState<T>> {
  private dom = {
    input: createRef<HTMLInputElement>(),
    predictions: createRef<HTMLDivElement>(),
  };

  constructor(props: AutocompleteProps<T>) {
    super(props);

    this.state = {
      predictions: [],
      arePredictionsVisible: false,
    };
  }

  render() {
    const { placeholder, className, stateColor, success } = this.props;
    const { name, value } = this.props.field;
    const { predictions, arePredictionsVisible } = this.state;

    return (
      <>
        <input
          ref={this.dom.input}
          className={classnames(style.input, className, {
            [style.isCorrect]: stateColor && success,
            [style.isInvalid]: stateColor && !success,
          })}
          name={name}
          value={value || ""}
          type="text"
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.showPredictions}
        />
        <div ref={this.dom.predictions} className={style.predictions} tabIndex={0} onBlur={this.handleBlur}>
          {arePredictionsVisible &&
            predictions.map((option) => (
              <div key={option.key} className={style.prediction} onClick={() => this.handleSelect(option)}>
                {option.title}
              </div>
            ))}
        </div>
      </>
    );
  }

  private handleBlur = (event: FocusEvent<HTMLElement>) => {
    const { target, relatedTarget } = event;
    const { input, predictions } = this.dom;

    if (target === input.current) {
      this.props.field.onBlur(event);
    }

    if (!relatedTarget || (relatedTarget !== input.current && relatedTarget !== predictions.current)) {
      this.hidePredictions();
    }
  };

  private showPredictions = () => {
    this.setState({ arePredictionsVisible: true });
  };

  private hidePredictions = () => {
    this.setState({ arePredictionsVisible: false });
  };

  private handleSelect = ({ searchValue: value }: T) => {
    const { setFieldValue, setFieldTouched } = this.props.form;
    const { name } = this.props.field;
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }

    setFieldValue(name, value);
    setFieldTouched(name, true);

    this.setState({ arePredictionsVisible: false });
  };

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange, options, filter } = this.props;
    const { setFieldValue } = this.props.form;
    const { name } = this.props.field;
    const { value } = e.target;

    const predictions = options.filter((option) => filter(value, option)).slice(0, 5);
    this.setState({ predictions });

    if (onChange) {
      onChange(value);
    }

    setFieldValue(name, value || "");
  };
}
