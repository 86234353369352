import omit from "lodash-es/omit";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { SecondaryBlueButton } from "nvent-web/components/Button";
import { ProductsStore } from "nvent-web/stores/Products";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { CategoryRecommendationVariant, ProductRecommendation } from "nvent-web/types/ProductRecommendation";

import ProductRecommendationCard from "../../components/ProductRecommendationCard/ProductRecommendationCard";

import { DownloadBOMModal } from "./components/DownloadBOMModal";
import style from "./RecommendationCard.module.scss";
import ThermostatModal from "./ThermostatModal";

interface RecommendationCardProps {
  recommendation: CategoryRecommendationVariant;
  onDownloadBom: (values: { products: CategoryRecommendationVariant; comments: string }) => void;
  onCreateProject?: (recommendation: CategoryRecommendationVariant) => void;
  thermostatSkuOptions: DropdownOption[];
  countryCode?: string;
}

interface RecommendationCardInnerProps extends RecommendationCardProps {
  products: ProductsStore;
}

class RecommendationCardInner extends Component<RecommendationCardInnerProps> {
  @observable
  private thermostatModalOpen = false;

  @observable
  private thermostatId?: string;

  @observable
  private showBOMModal = false;

  @computed
  get thermostat(): ProductRecommendation | undefined {
    if (!this.thermostatId) {
      return undefined;
    }

    const thermostatSku = this.props.products.productSkus.find(
      (productSku) => productSku.id === Number(this.thermostatId)
    );

    if (!thermostatSku) {
      return undefined;
    }

    const thermostatRecommendation = {
      ...omit(thermostatSku, ["seNumber", "noNumber", "customLength"]),
      quantity: 1,
    };

    return thermostatRecommendation;
  }

  @computed
  get recommendationWithThermostat(): CategoryRecommendationVariant {
    const { recommendation } = this.props;

    if (!this.thermostat) {
      return recommendation;
    }

    const accessorySkus = recommendation.accessorySkus
      ? [...recommendation.accessorySkus, this.thermostat]
      : [this.thermostat];

    return {
      ...recommendation,
      accessorySkus,
    };
  }

  render() {
    const { onCreateProject, recommendation } = this.props;

    return (
      <>
        <ProductRecommendationCard
          recommendation={recommendation}
          additionalThermostat={this.thermostat}
          onRemoveAdditionalThermostat={this.removeThermostat}
          actions={
            <div className={style.actionsContainer}>
              <SecondaryBlueButton className={style.actionButton} onClick={this.openThermostatPicker}>
                {this.thermostat ? (
                  <FormattedMessage id="selectionGuide.recommendations.changeThermostat" />
                ) : (
                  <FormattedMessage id="selectionGuide.recommendations.addThermostat" />
                )}
              </SecondaryBlueButton>
              {onCreateProject && (
                <SecondaryBlueButton className={style.actionButton} onClick={this.createProject}>
                  <FormattedMessage id="actions.createProject" />
                </SecondaryBlueButton>
              )}
              <SecondaryBlueButton className={style.actionButton} onClick={this.openBOMModal}>
                <FormattedMessage id="selectionGuide.getBOMButton" />
              </SecondaryBlueButton>
            </div>
          }
        />
        <ThermostatModal
          center
          isOpen={this.thermostatModalOpen}
          handleClose={this.closeThermostatPicker}
          onSubmit={this.addThermostat}
          thermostatSkuOptions={this.props.thermostatSkuOptions}
        />
        <DownloadBOMModal
          handleClose={this.closeBOMModal}
          isOpen={this.showBOMModal}
          center
          onSubmit={this.downloadBOM}
        />
      </>
    );
  }

  private downloadBOM = ({ comments }: { comments: string }) => {
    this.props.onDownloadBom({
      products: this.recommendationWithThermostat,
      comments,
    });
    this.closeBOMModal();
  };

  private createProject = () => {
    const { onCreateProject } = this.props;

    if (onCreateProject) {
      onCreateProject(this.recommendationWithThermostat);
    }
  };

  private openThermostatPicker = () => {
    this.thermostatModalOpen = true;
  };

  private closeThermostatPicker = () => {
    this.thermostatModalOpen = false;
  };

  private addThermostat = ({ productSkuId }: { productSkuId: string }) => {
    this.thermostatId = productSkuId;
    this.closeThermostatPicker();
  };

  private removeThermostat = () => {
    this.thermostatId = undefined;
  };

  private openBOMModal = () => {
    this.showBOMModal = true;
  };

  private closeBOMModal = () => {
    this.showBOMModal = false;
  };
}

export const RecommendationCard = compose<RecommendationCardInnerProps, RecommendationCardProps>(
  inject("products"),
  observer
)(RecommendationCardInner);
