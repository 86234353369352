import { decamelizeKeys } from "humps";

import { Promotion } from "nvent-web-admin/types/Promotion";

import Resource from "./Resource";

export default class PromotionsResource extends Resource {
  getAll(countryCode?: string) {
    return this.http
      .get<{ data: Promotion[] }>("/promotions", {
        params: decamelizeKeys({
          countryCode,
        }),
      })
      .then(({ data }) => data);
  }
}
