import { inject, observer } from "mobx-react";
import { FunctionComponent, ReactNode } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { compose } from "recompose";

import { UserStore } from "nvent-web/stores/User";
import { Role } from "nvent-web/types/Role";

interface RequiredRoleProps {
  userRole: Role;
  fallback?: ReactNode;
}

interface RequiredRoleInnerProps extends RequiredRoleProps {
  user: UserStore;
}

const RequiredRoleInner: FunctionComponent<RequiredRoleInnerProps> = ({
  user,
  userRole,
  fallback = null,
  children,
}) => {
  return <>{user.role === Role.superAdmin || user.role === userRole ? children : fallback}</>;
};
export const RequiredRole = compose<RequiredRoleInnerProps, RequiredRoleProps>(
  inject("user"),
  observer
)(RequiredRoleInner);

interface RequiredRoleRouteProps extends RouteProps, RequiredRoleProps {}

export const RequiredRoleRoute: FunctionComponent<RequiredRoleRouteProps> = ({
  userRole,
  fallback = <Redirect to="/admin" />,
  ...routeProps
}) => {
  return (
    <RequiredRole userRole={userRole} fallback={fallback}>
      <Route {...routeProps} />
    </RequiredRole>
  );
};
