import { KnownLocale } from "nvent-web/i18n";

type CatalogLinks = {
  [key in KnownLocale]: string;
};

export const catalogLinks: CatalogLinks = {
  sv: "http://raychemgolvvarme.se/kunskapsbank/installationsmanualer",
  en: "https://nvent.widen.net/s/llhfczf6gq/raychem-th-eu1001-floorheating-en",
  fr: "https://nvent.widen.net/s/gkgjmgz5df/raychem-th-eu1001-floorheating-fr",
  nn: "https://nvent.widen.net/s/grnfgsqjt6/raychem-sb-eu1846-flh-no",
  pl: "https://nvent.widen.net/s/snrltgbnmf/raychem-th-eu1001-floorheating-pl",
  fi: "https://nvent.widen.net/s/shk6h9flgl/raychem-th-cde0430-technicalhanbook-fi",
  ru: "https://nvent.widen.net/s/6jx7w5tljw/raychem-th-eu1001-floorheating-ru",
  cs: "https://nvent.widen.net/s/rpczhdp9nz/raychem-th-cde1430-floorheating-cz",
};
