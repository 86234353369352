import { FormattedMessage } from "react-intl";
import * as yup from "yup";

export type Message = FormattedMessage.Props;
type MessageOption<T> = Message | ((params: MessageParams<T>) => Message);
type MessageParams<T> = T &
  Partial<{
    path: string;
    value: any;
    originalValue: any;
    label: string;
  }>;

yup.setLocale({
  mixed: {
    default: buildMessage("error.invalid"),
    required: buildMessage("error.required"),

    // HACK:
    // For some reason specifying typeError message doesn't work,
    // instead notType must be provided which is missing in type definitions.
    // https://github.com/jquense/yup/issues/394
    notType: castMessage((values: MessageParams<{ type: string }>) => ({ id: `error.${values.type}.type`, values })),
  },
  string: {
    min: buildMessage<{ min: number }>("error.string.min"),
    max: buildMessage<{ max: number }>("error.string.max"),
    matches: buildMessage<{ regex: string }>("error.string.matches"),
    email: buildMessage("error.string.email"),
    url: buildMessage("error.string.url"),
    trim: buildMessage("error.string.trim"),
    lowercase: buildMessage("error.string.lowercase"),
    uppercase: buildMessage("error.string.uppercase"),
  },
  number: {
    min: buildMessage<{ min: number }>("error.number.min"),
    max: buildMessage<{ max: number }>("error.number.max"),
    lessThan: buildMessage<{ less: number }>("error.number.lessThan"),
    moreThan: buildMessage<{ more: number }>("error.number.moreThan"),
    positive: buildMessage("error.number.positive"),
    negative: buildMessage("error.number.negative"),
    integer: buildMessage("error.number.integer"),
  },
  date: {
    min: buildMessage<{ min: Date }>("error.date.min"),
    max: buildMessage<{ max: Date }>("error.date.max"),
  },
  object: {
    noUnknown: buildMessage("error.object.noUnknown"),
  },
  array: {
    min: buildMessage<{ min: number }>("error.array.min"),
    max: buildMessage<{ max: number }>("error.array.max"),
  },
});

export function buildMessage<T = Record<string, unknown>>(id: string) {
  return castMessage((values: MessageParams<T>) => ({ id, values }));
}

/**
 * Workaround for yup types not allowing non-string messages which
 * should work just fine, as mentioned by the author in
 * https://github.com/jquense/yup/issues/71#issuecomment-264212246
 */
export function castMessage<T = Record<string, unknown>>(message: MessageOption<T>): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return message as any;
}
