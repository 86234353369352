import classNames from "classnames";
import { inject } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import buttonStyles from "nvent-web/components/Button/Button.module.scss";
import { UserStore } from "nvent-web/stores/User";

import style from "./SelectionGuideSection.module.scss";

const { button, medium, primaryBlue } = buttonStyles;

enum ExternalSelectionGuideLocales {
  pl = "en-pl",
  cs = "en-cz",
  en = "en-gb",
  fi = "fi-fi",
  fr = "fr-fr",
  nn = "no-no",
  ru = "ru-ru",
  sv = "sv-se",
}

interface SelectionGuideSectionInnerProps {
  user: UserStore;
}

class SelectionGuideSectionInner extends Component<SelectionGuideSectionInnerProps> {
  render() {
    const { locale } = this.props.user;
    return (
      <div className={style.wrapper}>
        <p className={style.text}>
          <FormattedMessage id="login.selectionGuideSection.description" />
        </p>
        <a
          href={
            "https://raychem.nvent.com/" +
            ExternalSelectionGuideLocales[locale] +
            "/resources/technology/floor-heating-product-selection-guide"
          }
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(button, medium, primaryBlue, style.button)}
        >
          <FormattedMessage id="login.selectionGuideSection.button" />
        </a>
      </div>
    );
  }
}

const SelectionGuideSection = compose<SelectionGuideSectionInnerProps, Record<string, unknown>>(inject("user"))(
  SelectionGuideSectionInner
);

export default SelectionGuideSection;
