import humps from "humps";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as CheckMark } from "nvent-web/assets/svg/check-mark.svg";
import { ReactComponent as CrossMark } from "nvent-web/assets/svg/cross-mark.svg";
import { Test, TestType } from "nvent-web/types/Test";

import style from "./TestProgress.module.scss";

interface TestProgressItemProps {
  test: Test;
  onClick: (type: TestType) => void;
  hideHeatResistance: boolean;
  showLine: boolean;
}

const TestProgressItem: FunctionComponent<TestProgressItemProps> = ({
  test,
  onClick,
  hideHeatResistance,
  showLine,
}) => (
  <div onClick={() => onClick(test.type)} className={style.info}>
    <h3 className={style.title}>
      <FormattedMessage id={`tests.${humps.camelize(test.type)}.title`} />
    </h3>
    <div className={style.testDataWrapper}>
      <div className={style.testData}>
        {!hideHeatResistance && (
          <div className={style.heatResistanceRow}>
            <p className={style.label}>
              <FormattedMessage id="form.heatResistance" />
            </p>
            <span className={style.value}>{test.heatResistance}</span>
          </div>
        )}
        <div className={style.heatResistanceRow}>
          <p className={style.label}>
            <FormattedMessage id="form.insulationResistance" />
          </p>
          <span className={style.value}>{test.insulationResistance}</span>
        </div>
      </div>
      <div className={style.checkCircle}>
        {test.completed ? <CheckMark /> : <CrossMark />}
        {showLine && <div className={style.line} />}
      </div>
    </div>
  </div>
);

export default TestProgressItem;
