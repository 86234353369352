import { Address } from "nvent-web/types/Address";

import { Auth0Address, auth0CompanyClaim, auth0GdprConsentClaim, Auth0User } from "./Auth0User";

export type AppAuth0User = ReturnType<typeof toAppAuth0User>;

export function toAppAuth0User(user: Partial<Auth0User>) {
  const {
    sub: auth0Id = "",

    email = "",
    email_verified: isEmailVerified = false,
    phone_number: phoneNumber = "",

    given_name: firstName = "",
    family_name: lastName = "",
    name = "",
    nickname = "",
    picture: pictureUrl = "",

    [auth0CompanyClaim]: companyName = "",

    updated_at: updatedAt = "",

    [auth0GdprConsentClaim]: gaveGdprConsent = false,
  } = user;

  const companyAddress = toAppAddress(user.address || {});

  return {
    auth0Id,
    updatedAt,

    email,
    isEmailVerified,
    phoneNumber,

    firstName,
    lastName,
    name,
    nickname,
    pictureUrl,

    companyName,
    companyAddress,

    gaveGdprConsent: gaveGdprConsent === true || gaveGdprConsent === "true",
  };
}

function toAppAddress(address: Partial<Auth0Address>): Address {
  const {
    country: countryCode = "",
    region = "",
    locality = "",
    street_address: streetAddress = "",
    postal_code: postalCode = "",
  } = address;

  return { countryCode, region, locality, streetAddress, postalCode };
}
