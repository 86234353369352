import { Component } from "react";

import LocalePicker from "nvent-web/scenes/Auth/components/LocalePicker";

import style from "./DesktopTitle.module.scss";

class DesktopTitle extends Component {
  render() {
    return (
      <div className={style.wrapper}>
        <h1 className={style.title}>{this.props.children}</h1>
        <div className={style.picker}>
          <LocalePicker />
        </div>
      </div>
    );
  }
}

export default DesktopTitle;
