import { History } from "history";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import RoomForm from "nvent-web/components/RoomForm";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";
import { NoopOuterProps } from "nvent-web/types/NoopOuterProps";

interface AddRoomsParams {
  projectId: string;
}

interface AddRoomFormInnerProps extends RouteComponentProps<AddRoomsParams>, InjectedIntlProps {
  history: History;
  rooms: RoomsStore;
  notifications: NotificationsStore;
}

const initialValues: NewRoomFormValues = {
  name: "",
  area: null,
  installableArea: null,
  floorConstruction: null,
  floorFinish: null,
  areaType: "dry",
};

class AddRoomFormInner extends Component<AddRoomFormInnerProps> {
  render() {
    return (
      <RoomForm
        isSubmitting={this.props.rooms.isRoomCreating}
        onCancel={this.handleCancel}
        initialValues={initialValues}
        handleSubmit={this.handleSubmit}
      />
    );
  }

  private handleCancel = () => {
    this.props.history.goBack();
  };

  private handleSubmit = (formValues: NewRoomFormValues) => {
    try {
      const { rooms, location } = this.props;
      const levelIdSearchParam = new URLSearchParams(location.search).get("levelId");
      const levelId = levelIdSearchParam ? parseInt(levelIdSearchParam, 10) : undefined;

      rooms.createRoom({
        values: formValues,
        projectId: parseInt(this.props.match.params.projectId, 10),
        levelId,
      });
    } catch (err) {
      logger.error(err);
      this.props.notifications.createError(<FormattedMessage id="error.createRoomFailed" />);
    }
  };
}

const AddRoomForm = compose<AddRoomFormInnerProps, NoopOuterProps>(
  injectIntl,
  withRouter,
  inject("rooms", "notifications"),
  observer
)(AddRoomFormInner);

export default AddRoomForm;
