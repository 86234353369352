import { Form, Formik } from "formik";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { PrimaryBlueButton, SecondaryButton } from "nvent-web/components/Button";
import { TextField } from "nvent-web/components/form/TextField";
import Modal, { ModalProps } from "nvent-web/components/Modal";

import style from "./DownloadBOMModal.module.scss";

interface DownloadBOMModalProps extends ModalProps {
  onSubmit: (values: { comments: string }) => void;
}

const initialValues = {
  comments: "",
};

const validationSchema = yup.object({
  comments: yup.string(),
});

export const DownloadBOMModal: FunctionComponent<DownloadBOMModalProps> = ({ onSubmit, ...modalProps }) => {
  return (
    <Modal {...modalProps} bodyClass={style.modal}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {() => (
          <Form noValidate className={style.content}>
            <h3 className={style.title}>
              <FormattedMessage id="selectionGuide.downloadBOMModal.title" />
            </h3>
            <TextField
              component="textarea"
              name="comments"
              label={<FormattedMessage id="selectionGuide.comments.label" />}
              disabled={false}
              className={{ input: style.textarea }}
            />
            <div className={style.actions}>
              <SecondaryButton className={style.cancelButton} onClick={modalProps.handleClose}>
                <FormattedMessage id="actions.cancel" />
              </SecondaryButton>
              <PrimaryBlueButton type="submit" className={style.confirmButton}>
                <FormattedMessage id="selectionGuide.downloadBOMModal.button" />
              </PrimaryBlueButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
