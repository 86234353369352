import classNames from "classnames";
import { FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";

import { buttonClassNames, ButtonProps } from "..";

import style from "./LinkButton.module.scss";

type ForwardedButtonProps = Omit<ButtonProps, keyof LinkProps>;

export interface LinkButtonProps extends LinkProps, ForwardedButtonProps {}

const LinkButton: FunctionComponent<LinkButtonProps> = ({
  children,
  size,
  fixedWidth,
  theme,
  className,
  ...linkProps
}) => (
  <Link {...linkProps} className={classNames(buttonClassNames({ size, fixedWidth, theme }), style.link, className)}>
    {children}
  </Link>
);

export default LinkButton;
