import { BreadcrumbType } from "@bugsnag/js";

import bugsnag from "./bugsnag";

export function error(message: any, metaData?: any) {
  console.error(message);

  if (bugsnag) {
    bugsnag.notify(normalizeMessage(message), (event) => {
      event.severity = "error";
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      event.addMetadata("metaData", metaData);
    });
  }
}

export function warning(message: any, metaData?: any) {
  console.warn(message);

  if (bugsnag) {
    bugsnag.notify(normalizeMessage(message), (event) => {
      event.severity = "warning";
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      event.addMetadata("metaData", metaData);
    });
  }
}

export function info(message: any, metaData?: any) {
  console.info(message);

  if (bugsnag) {
    bugsnag.notify(normalizeMessage(message), (event) => {
      event.severity = "info";
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      event.addMetadata("metaData", metaData);
    });
  }
}

export function breadcrumb(name: string, metaData?: any, type?: BreadcrumbType) {
  if (bugsnag) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    bugsnag.leaveBreadcrumb(name, metaData, type);
  }
}

function normalizeMessage(message: any): string | Error {
  if (message instanceof Error) {
    return message;
  }

  if (typeof message === "object") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return message.error_description || message.error || message.message || JSON.stringify(message);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return message.toString();
}
