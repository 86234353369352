import { Component } from "react";

import { Product } from "nvent-web/types/Product";
import { TestType } from "nvent-web/types/Test";

import style from "./TestProgress.module.scss";
import TestProgressItem from "./TestProgressItem";

interface TestProgressProps {
  product: Product;
  step?: TestType;
  onTestItemClick: (type: TestType) => void;
}

interface TestProgressState {
  heatResistance: string;
  insulationResistance: string;
}

export default class TestProgress extends Component<TestProgressProps, TestProgressState> {
  state = {
    heatResistance: "",
    insulationResistance: "",
  };

  render() {
    const { product, step } = this.props;

    const slicer = product.tests.findIndex((t) => t.type === step);

    let tests = product.tests;
    if (slicer !== -1) {
      tests = product.tests.slice(0, slicer);
    }

    return (
      <div className={style.container}>
        {tests.map((test, i) => (
          <TestProgressItem
            key={i}
            test={test}
            hideHeatResistance={!product.maxResistance || !product.minResistance}
            showLine={i !== tests.length - 1}
            onClick={this.onTestItemClick}
          />
        ))}
      </div>
    );
  }

  private onTestItemClick = (type: TestType) => {
    this.props.onTestItemClick(type);
  };
}
