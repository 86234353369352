import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import SimpleCard from "nvent-web/components/SimpleCard";

import { BundledThermostat } from "../../types";

interface ThermostatItemProps {
  thermostat: BundledThermostat;
  onRemove: (id: number) => void;
  disableActions: boolean;
}

interface ThermostatItemState {
  isRemoveConfirmationOpen: boolean;
}

class ThermostatItem extends Component<ThermostatItemProps, ThermostatItemState> {
  state = { isRemoveConfirmationOpen: false };

  render() {
    const { isRemoveConfirmationOpen } = this.state;
    const { description, source } = this.props.thermostat;
    const thermostatActions = this.props.disableActions
      ? []
      : [<RemoveAction onClick={this.openRemoveConfirmation} key="remove" />];
    return (
      <>
        <SimpleCard
          title={
            <>
              {description} {source && <small>- {source.description}</small>}
            </>
          }
          actions={thermostatActions}
        />
        <ConfirmationModal
          isOpen={isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"thermostat.removeModal.title"} />}
          description={<FormattedMessage id={"thermostat.removeModal.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.handleRemove}
        />
      </>
    );
  }

  private handleRemove = () => {
    const {
      onRemove,
      thermostat: { id },
    } = this.props;

    onRemove(id);
  };

  private openRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: true,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: false,
    });
  };
}

export default ThermostatItem;
