import classNames from "classnames";
import { LocationDescriptor } from "history";
import { Children, cloneElement, Component, ReactElement } from "react";
import { Link } from "react-router-dom";

import ProgressFull from "nvent-web/components/Progress/FullProgress";

import style from "./DesktopCard.module.scss";

interface DesktopCardProps {
  progress?: number;
  actions: React.ReactNode[];
  to?: LocationDescriptor;
  title: string;
  image?: React.ReactNode;
  className?: {
    wrapper?: string;
    title?: string;
  };
  isFinished?: boolean;
}

class DesktopCard extends Component<DesktopCardProps> {
  render() {
    const { progress, actions, to, children, className = {}, isFinished } = this.props;

    const actionButtons = Children.map(actions, (item) => {
      const props = { className: style.button };
      return cloneElement(item as ReactElement, props);
    });

    return (
      <div className={classNames(style.wrapper, className.wrapper)}>
        <div className={style.header}>
          {this.renderImage()}
          <div>
            {to ? (
              <Link to={to} className={style.link}>
                {this.renderTitle()}
              </Link>
            ) : (
              <>{this.renderTitle()}</>
            )}
            {children}
          </div>
          <div className={style.actionsWrapper}>{actionButtons}</div>
        </div>
        {!isFinished && progress !== undefined && <ProgressFull borderRadius="none" progress={progress} />}
      </div>
    );
  }

  private renderTitle() {
    const { title, className = {} } = this.props;
    return <h2 className={classNames(style.title, className.title)}>{title}</h2>;
  }

  private renderImage() {
    const { image } = this.props;

    if (!image) {
      return null;
    }

    return <div className={style.imageWrapper}>{image}</div>;
  }
}

export default DesktopCard;
