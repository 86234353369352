import classNames from "classnames";
import { Component } from "react";

import { ReactComponent as ZoomIcon } from "nvent-web/assets/svg/zoom.svg";

import style from "./SearchInput.module.scss";

interface SearchInputProps {
  name: string;
  placeholder?: string;
  className?: {
    wrapper?: string;
    input?: string;
    inputContainer?: string;
  };
  onSearch: (value: string) => void;
  value?: string;
}

export class SearchInput extends Component<SearchInputProps> {
  render() {
    const { className = {}, name, placeholder } = this.props;

    return (
      <div className={classNames(style.wrapper, className.wrapper)}>
        <div className={classNames(style.inputContainer, className.inputContainer)}>
          <ZoomIcon className={style.icon} />
          <input
            type="search"
            id={name}
            name={name}
            onChange={this.handleInputChange}
            className={classNames(style.input, className.input)}
            placeholder={placeholder}
            value={this.props.value}
          />
        </div>
      </div>
    );
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onSearch(event.target.value);
  };
}
