import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import discountIcon from "nvent-web/assets/png/discount-icon.png";
import AppStore from "nvent-web/stores/App";
import { PromotionsStore } from "nvent-web/stores/Promotions";
import { UserStore } from "nvent-web/stores/User";

import { Banner } from "./components/Banner";
import { CatalogBar } from "./components/CatalogBar";
import { Promotion } from "./components/Promotion";
import { getEmailByCountryCode } from "./getEmailByCountryCode";
import style from "./NonCertifiedProPage.module.scss";

interface NonCertifiedProPagePropsInner {
  user: UserStore;
  app: AppStore;
  promotions: PromotionsStore;
}

class NonCertifiedProPageInner extends Component<NonCertifiedProPagePropsInner> {
  render() {
    const features = [
      {
        iconClass: "awardIcon",
        descriptionMessageId: "becomeAccredited",
      },
      {
        iconClass: "warrantyIcon",
        descriptionMessageId: "obtainRights",
      },
      {
        iconClass: "computerIcon",
        descriptionMessageId: "obtainReferrals",
      },
      {
        iconClass: "discountIcon",
        descriptionMessageId: "quarterlyPromotions",
      },
      {
        iconClass: "handsIcon",
        descriptionMessageId: "attendEvents",
      },
    ];
    const stepsToGetStarted = [
      {
        stepNumber: 1,
        title: "toBegin",
        messageIdsList: ["signUpForTraining", "attendCertifiedProTraining"],
      },
      {
        stepNumber: 2,
        title: "whatWillYouLearn",
        messageIdsList: ["floorHeatingOverview", "installationDemonstration", "testingAndWarranty", "salesTechniques"],
      },
      {
        stepNumber: 3,
        title: "onceCompleted",
        messageIdsList: ["licenseNumber", "CertifiedProInstaller", "totalCareWarranty", "promotions"],
      },
    ];

    const bannerClasses = {
      image: style.bannerImage,
      text: style.bannerText,
    };

    const { user, promotions } = this.props;

    const promotionsToShow = promotions.promotionsForCurrentUser;

    return (
      <>
        <PageTitle />

        <section className={style.wrapper}>
          <h1>
            <FormattedMessage id="certifiedPage.nonCertified.certifiedPro" />
          </h1>

          <Banner classes={bannerClasses}>
            <FormattedMessage id="certifiedPage.nonCertified.banner" />
          </Banner>
        </section>

        <section className={style.wrapper}>
          <div className={style.row} id="offers">
            <div className={style.iconWrapper}>
              <img src={discountIcon} alt="" className={style.iconImg} />
            </div>
            <div className={style.columnRight}>
              <h1 className={style.promotionsHeader}>
                <FormattedMessage id="certifiedPage.certified.activePromotions" />
              </h1>
              {!promotions.arePromotionsLoading && promotionsToShow.length === 0 && (
                <FormattedMessage id="certifiedPage.certified.activePromotions.empty" />
              )}
              {!promotions.arePromotionsLoading &&
                promotionsToShow.map((promotion) => <Promotion promotion={promotion} key={promotion.id} />)}
            </div>
          </div>
        </section>

        <CatalogBar />

        <section className={style.wrapper}>
          <div className={style.iconsRow}>
            {features.map((feature) => {
              const { iconClass, descriptionMessageId } = feature;
              return (
                <div className={style.column} key={iconClass}>
                  <div className={style[iconClass]} />
                  <p className={style.columnDescription}>
                    <FormattedHTMLMessage id={`certifiedPage.nonCertified.${descriptionMessageId}`} />
                  </p>
                </div>
              );
            })}
          </div>
        </section>

        <section className={style.signUpBar}>
          <div className={style.signUpBarContent}>
            <h2 className={style.signUpTitle}>
              <FormattedMessage id="certifiedPage.nonCertified.signUp" />
            </h2>
            <p className={style.signUpSubtitle}>
              <FormattedMessage id="certifiedPage.nonCertified.signUpDescription" />
            </p>
            <a className={style.signUpButton} href={`mailto:${getEmailByCountryCode(user.companyAddress.countryCode)}`}>
              <FormattedMessage id="certifiedPage.nonCertified.signUpNow" />
            </a>
          </div>
        </section>

        <section className={style.wrapper}>
          <h1 className={style.stepsHeader}>
            <FormattedMessage id="certifiedPage.nonCertified.steps" />
          </h1>
          {stepsToGetStarted.map((step) => {
            const { stepNumber, title, messageIdsList } = step;
            return (
              <div className={style.stepsRow} key={stepNumber}>
                <div className={style.stepNumber}>
                  <div className={style.step}>{stepNumber}</div>
                </div>
                <div>
                  <h2 className={style.stepHeader}>
                    <FormattedMessage id={`certifiedPage.nonCertified.${title}`} />
                  </h2>
                  <ul className={style.stepList}>
                    {messageIdsList.map((messageId) => (
                      <li key={messageId}>
                        <FormattedHTMLMessage id={`certifiedPage.nonCertified.${messageId}`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </section>
      </>
    );
  }
}

export const NonCertifiedProPage = compose<NonCertifiedProPagePropsInner, Record<string, unknown>>(
  inject("user", "app", "promotions"),
  observer
)(NonCertifiedProPageInner);
