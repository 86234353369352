export function getPlatform(): string {
  if (navigator.userAgent.match(/android/i)) {
    return "android";
  }
  if (navigator.platform.match(/iphone|ipad|ipod/i)) {
    return "ios";
  }

  return "unknown";
}
