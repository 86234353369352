import { inject, observer } from "mobx-react";
import { FC, ReactNode } from "react";
import MediaQuery from "react-responsive";
import { compose } from "recompose";

import { SidebarLayout } from "nvent-web/components/SidebarLayout";
import { gridBreakpoints } from "nvent-web/lib/constants";
import AppStore from "nvent-web/stores/App";

import SidebarItems from "./components/SidebarItems/SidebarItems";

export interface SidebarInnerProps {
  app: AppStore;
}

export const SidebarInner = observer<FC<SidebarInnerProps>>(({ app, children }) => {
  const { isSideMenuVisible, toggleSideMenu } = app;

  return (
    <MediaQuery minWidth={gridBreakpoints.lg}>
      {(isDesktop) => (
        <SidebarLayout
          sidebar={<SidebarItems />}
          open={isSideMenuVisible}
          onSetOpen={toggleSideMenu}
          docked={isDesktop}
          transitions={!isDesktop}
        >
          {children}
        </SidebarLayout>
      )}
    </MediaQuery>
  );
});

export const Sidebar = compose<SidebarInnerProps, { children: ReactNode }>(inject("app"))(SidebarInner);
