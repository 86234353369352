import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import NewProjectForm from "./components/NewProjectForm";
import style from "./NewProject.module.scss";

export const NewProject: FunctionComponent = () => (
  <div className={style.container}>
    <PageTitle>
      <FormattedMessage id="projects.new.title" />
    </PageTitle>
    <NewProjectForm />
  </div>
);

export default NewProject;
