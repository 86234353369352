import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { isKnownLocale, KnownLocale } from "nvent-web/i18n";
import { Photo } from "nvent-web/types/Photo";
import { Profile } from "nvent-web/types/Profile";

import Resource from "./Resource";

export type ProfileUpdate = Omit<Partial<Profile>, "id" | "certifiedProNumber" | "role">;

const defaultLocale: KnownLocale = "en";
const oldLocales: { [K in KnownLocale]?: string } = {
  sv: "se",
  nn: "no",
};

/**
 * Replaces the profile locale with a known one by looking up oldLocales or falling back to the default en locale.
 */
async function fixLocale(promise: Promise<AxiosResponse<Profile>>): Promise<AxiosResponse<Profile>> {
  const response = await promise;
  let { locale } = response.data;

  // TODO: remove once the backend migrates to new locales
  Object.keys(oldLocales).forEach((newLocale) => {
    const oldLocale = oldLocales[newLocale as KnownLocale];

    if (locale === oldLocale) {
      locale = newLocale as KnownLocale;
    }
  });

  if (!isKnownLocale(locale)) {
    locale = defaultLocale;
  }

  return { ...response, data: { ...response.data, locale } };
}

export class ProfileResource extends Resource {
  async get() {
    return fixLocale(this.http.get<Profile>("profile"));
  }

  async update(profile: ProfileUpdate, idToken: string) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };

    try {
      return await fixLocale(this.http.post<Profile>("profile", profile, config));
    } catch (e) {
      // retry with old locale if we got a 422
      // TODO: remove once the backend migrates to the new locales
      const error = e as AxiosError;

      if (!profile.locale || !oldLocales[profile.locale] || !error.response || error.response.status !== 422) {
        throw e;
      }

      const oldLocale = oldLocales[profile.locale];
      return await this.http.post<Profile>("profile", { ...profile, locale: oldLocale }, config);
    }
  }

  async setLocale(locale: KnownLocale) {
    try {
      return await this.http.patch("profile/set_locale", { locale });
    } catch (e) {
      // retry with old locale if we got a 422
      // TODO: remove once the backend migrates to the new locales
      const error = e as AxiosError;
      const oldLocale = oldLocales[locale];

      if (oldLocale && error.response && error.response.status === 422) {
        return await this.http.patch("profile/set_locale", { locale: oldLocale });
      } else {
        throw e;
      }
    }
  }

  async createLogo(logo: File) {
    const logoData = new FormData();
    logoData.append("image", logo);

    return this.http.post<Photo>(`profile/logo`, logoData);
  }

  async deleteLogo(logoId: number) {
    return this.http.delete(`profile/logo/${logoId}`);
  }

  async createAvatar(avatar: File) {
    const avatarData = new FormData();
    avatarData.append("image", avatar);

    return this.http.post<Photo>(`profile/avatar`, avatarData);
  }

  async deleteAvatar(avatarId: number) {
    return this.http.delete(`profile/avatar/${avatarId}`);
  }
}
