import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import FormattedMeasurement from "nvent-web/components/FormattedMeasurement/FormattedMeasurement";
import { HelpTip } from "nvent-web/components/HelpTip";
import AppStore from "nvent-web/stores/App";
import { FeatureDescription } from "nvent-web/types/FeatureDescription";
import { CategoryRecommendationVariant, ProductRecommendation } from "nvent-web/types/ProductRecommendation";

import style from "./ProductRecommendationCard.module.scss";

interface ProductRecommendationCardProps {
  recommendation: CategoryRecommendationVariant;
  additionalThermostat?: ProductRecommendation;
  onRemoveAdditionalThermostat?: () => void;
  featureDescription?: FeatureDescription;
  actions?: ReactNode;
}

interface ProductRecommendationCardInnerProps extends ProductRecommendationCardProps {
  app: AppStore;
}

class ProductRecommendationCardInner extends Component<ProductRecommendationCardInnerProps> {
  render() {
    const {
      recommendation,
      app: { locale },
      actions,
    } = this.props;
    const {
      metrics: { totalOutput, cableLength, cableSpacing, powerPerM2 },
      metadata: { imageUrl, featureDescription = {}, heading },
      productSkus,
      accessorySkus,
    } = recommendation;

    const additionalLength = productSkus
      .map(({ extraConnectionLength }) => Number(extraConnectionLength))
      .reduce((prev, current) => prev + current, 0);

    const hasCusttomLength = Boolean(productSkus.find(({ customLength }) => customLength));

    return (
      <div className={style.wrapper} key={productSkus[0].id}>
        <div className={style.container}>
          {imageUrl && <div className={style.productImg} style={{ backgroundImage: `url(${imageUrl})` }} />}
          <div className={style.product}>
            <h3 className={style.title}>{heading}</h3>
            <div className={style.commercialDescription}>{featureDescription[locale] || ""}</div>
          </div>
          <div className={style.productsList}>
            {this.renderProducts(<FormattedMessage id="roomCard.details.products" />, productSkus)}
            {this.renderProducts(<FormattedMessage id="selectionGuide.accessories" />, accessorySkus)}
            {this.props.additionalThermostat && (
              <div className={style.productItem}>
                <div className={style.productIdentifier}>
                  {this.props.additionalThermostat.description || this.props.additionalThermostat.identifier}
                  {this.props.onRemoveAdditionalThermostat && (
                    <button onClick={this.props.onRemoveAdditionalThermostat} className={style.removeButton}>
                      <FormattedMessage id="selectionGuide.recommendations.removeThermostat" />
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={style.bundleSpecs}>
            {totalOutput && (
              <p className={style.spec}>
                <FormattedMessage id="selectionGuide.recommendations.total" />
                <FormattedMeasurement
                  value={totalOutput}
                  fractionDigits={0}
                  unit="W"
                  className={{ container: style.measurement, unit: style.unit }}
                />
              </p>
            )}
            {powerPerM2 && (
              <p className={style.spec}>
                <FormattedMessage id="selectionGuide.recommendations.outputM2" />
                <FormattedMeasurement
                  value={powerPerM2}
                  fractionDigits={0}
                  unit="W/m²"
                  className={{ container: style.measurement, unit: style.unit }}
                />
              </p>
            )}
            {cableLength && (
              <div className={style.spec}>
                <FormattedMessage id="selectionGuide.recommendations.cableLength" />
                <FormattedMeasurement
                  value={cableLength + additionalLength}
                  fractionDigits={0}
                  unit="m"
                  className={{ container: style.measurement, unit: style.unit }}
                />
                {hasCusttomLength && (
                  <HelpTip direction="right" className={style.helpTip}>
                    <FormattedMessage id="selectionGuide.additionalCableLengthWarning" />{" "}
                    {cableLength > 100 && <FormattedMessage id="selectionGuide.maxCircuitLengthWarning" />}
                  </HelpTip>
                )}
              </div>
            )}
            {cableSpacing && (
              <p className={style.spec}>
                <FormattedMessage id="selectionGuide.recommendations.cableSpacing" />
                <FormattedMeasurement
                  value={cableSpacing * 1000}
                  fractionDigits={0}
                  unit="mm"
                  className={{ container: style.measurement, unit: style.unit }}
                />
              </p>
            )}
          </div>
          {actions}
        </div>
      </div>
    );
  }

  private readonly renderProducts = (title: ReactNode, products: ProductRecommendation[] | null) => {
    if (!products) {
      return null;
    }
    return (
      <>
        <h3 className={style.title}>{title}:</h3>
        {products.map((product) => {
          const { cableLength = 0, customLength = 0, extraConnectionLength = 0 } = product;
          const totalCableLength = [cableLength, customLength, extraConnectionLength]
            .map((length) => Number(length))
            .reduce((prev, current) => prev + current, 0);
          return (
            <div className={style.productItem} key={product.id}>
              <div className={style.productIdentifier}>
                {product.description || product.identifier}
                {totalCableLength > 0 && <span className={style.productCableLength}>{totalCableLength} m</span>}
              </div>
              {product.quantity > 1 && <div className={style.quantity}>x{product.quantity}</div>}
            </div>
          );
        })}
      </>
    );
  };
}

const ProductRecommendationCard = compose<ProductRecommendationCardInnerProps, ProductRecommendationCardProps>(
  inject("app"),
  observer
)(ProductRecommendationCardInner);

export default ProductRecommendationCard;
