import { CommissioningFormValues } from "nvent-web/types/CommissioningFormValues";
import { CommissionReportGeneration } from "nvent-web/types/CommissionReportGeneration";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";
import { Project } from "nvent-web/types/Project";
import { QuickReportGeneration } from "nvent-web/types/QuickReportGeneration";
import { SearchState } from "nvent-web/types/SearchState";

import Resource from "./Resource";
export interface ProjectFilters {
  search?: string;
  state?: SearchState;
}

export default class ProjectsResource extends Resource {
  getAll() {
    return this.http.get<Project[]>("projects");
  }
  create(data: NewProjectFormValues) {
    return this.http.post<Project>("projects", data);
  }
  getOne(id: number) {
    return this.http.get<DetailedProject>(`projects/${id}`);
  }
  updateOne(data: NewProjectFormValues, id: number) {
    return this.http.patch<DetailedProject>(`projects/${id}`, data);
  }
  remove(projectId: number) {
    return this.http.delete(`projects/${projectId}`);
  }
  sendCommission(projectId: number) {
    return this.http.post(
      `projects/${projectId}/commission`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V3_URL,
      }
    );
  }
  requestReportOnEmail(projectId: number, data: CommissioningFormValues) {
    return this.http.post(`/projects/${projectId}/commission_reports_emails`, data, {
      baseURL: process.env.REACT_APP_API_V3_URL,
    });
  }

  generateQuickReport(projectId: number) {
    return this.http.post(
      `/projects/${projectId}/quick_reports_generation`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V3_URL,
      }
    );
  }

  fetchQuickReportStatus(projectId: number) {
    return this.http.get<QuickReportGeneration>(`/projects/${projectId}/quick_reports_generation`, {
      baseURL: process.env.REACT_APP_API_V3_URL,
    });
  }

  generateCommissionReport(projectId: number) {
    return this.http.post(
      `/projects/${projectId}/commission_reports_generation`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V3_URL,
      }
    );
  }

  fetchCommissionReportStatus(projectId: number) {
    return this.http.get<CommissionReportGeneration>(`/projects/${projectId}/commission_reports_generation`, {
      baseURL: process.env.REACT_APP_API_V3_URL,
    });
  }

  async downloadCommissionReport(projectId: number) {
    return this.http.get<{ url: string }>(`projects/${projectId}/report`);
  }

  async downloadCommissionReportV2(projectId: number) {
    return this.http.post<{ url: string }>(
      `projects/${projectId}/commission_report`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_URL,
      }
    );
  }

  downloadCommissionReportV3(url: string) {
    return this.http.get<BlobPart>(url, {
      responseType: "blob",
      baseURL: process.env.REACT_APP_API_V3_URL,
    });
  }
  async downloadBillOfMaterials(projectId: number) {
    return this.http.get<{ url: string }>(`projects/${projectId}/bom`);
  }
  async downloadBillOfMaterialsV2(projectId: number) {
    return this.http.post<{ url: string }>(
      `projects/${projectId}/bill_of_materials`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_URL,
      }
    );
  }
  archive(projectId: number) {
    return this.http.post(`projects/${projectId}/archive`);
  }
  copy(projectId: number) {
    return this.http.post(`projects/${projectId}/duplicate`);
  }
  search({ state, search }: ProjectFilters) {
    return this.http.get("projects", {
      params: { state, q: search },
    });
  }
}
