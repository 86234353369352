import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { FunctionComponent } from "react";
import { compose } from "recompose";

import AppStore from "nvent-web/stores/App";

import style from "./Banner.module.scss";
import { getLogoByCountryCode } from "./getLogoByCountryCode";

interface BannerProps {
  classes: {
    image: string;
    text: string;
  };
}

interface BannerPropsInner extends BannerProps {
  app: AppStore;
}

const BannerInner: FunctionComponent<BannerPropsInner> = ({ children, app, classes }) => {
  const countryCode = getLogoByCountryCode(app.locale);
  return (
    <div className={style.banner}>
      <div className={classNames(style.bannerImg, classes.image)} />
      <div className={classNames(style.bannerText, classes.text)}>{children}</div>
      <div className={style.bannerIcon} style={{ backgroundImage: `url(${countryCode})` }} />
    </div>
  );
};

export const Banner = compose<BannerPropsInner, BannerProps>(inject("app"), observer)(BannerInner);
