import { FunctionComponent } from "react";

import { Promotion as PromotionType } from "nvent-web-admin/types/Promotion";

import style from "./Promotion.module.scss";

interface PromotionProps {
  promotion: PromotionType;
}

export const Promotion: FunctionComponent<PromotionProps> = ({ promotion }) => {
  const { title, url, description, images, showTitle } = promotion;
  const image = images && images.find(({ type }) => type === "offer");
  const imageSrc = image ? image.urls.large : "";

  const renderContent = () => (
    <>
      {showTitle && <h3 className={style.header}>{title}</h3>}
      <p className={style.content}>{description}</p>
      {imageSrc && <img className={style.image} src={imageSrc} alt={title} />}
    </>
  );

  return (
    <div className={style.wrapper}>
      {url ? (
        <a href={url} rel="noopener noreferrer" target="_blank" className={style.link}>
          {renderContent()}
        </a>
      ) : (
        renderContent()
      )}
    </div>
  );
};
