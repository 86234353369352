import { Form, Formik } from "formik";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { PrimaryBlueButton, SecondaryButton } from "nvent-web/components/Button";
import { DropdownField } from "nvent-web/components/form/DropdownField";
import { DropdownOption } from "nvent-web/types/DropdownOption";

import style from "./AddThermostatForm.module.scss";

interface FormValues {
  productSkuId: string;
}

const initialValues: FormValues = {
  productSkuId: "",
};

interface ThermostatFormProps {
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
  thermostatOptions: DropdownOption[];
  isSubmitting?: boolean;
}

interface ThermostatFormInnerProps extends ThermostatFormProps, InjectedIntlProps {}

class ThermostatFormInner extends Component<ThermostatFormInnerProps> {
  render() {
    const { intl, onSubmit, onCancel, isSubmitting } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ dirty }) => (
          <Form noValidate={true}>
            <h3 className={style.title}>
              <FormattedMessage id="thermostat.add.title" />
            </h3>

            <p className={style.notice}>
              <FormattedMessage id="thermostat.add.notice" />
            </p>

            <DropdownField
              name="productSkuId"
              label={<FormattedMessage id="form.thermostatName" />}
              options={this.props.thermostatOptions}
              placeholder={intl.formatMessage({ id: "form.thermostatName.selectPlaceholder" })}
              required
            />

            <div className={style.actions}>
              <SecondaryButton className={style.cancelBtn} onClick={onCancel}>
                <FormattedMessage id="actions.cancel" />
              </SecondaryButton>
              <PrimaryBlueButton
                disabled={!dirty || isSubmitting}
                className={style.yesBtn}
                type="submit"
                loading={isSubmitting}
              >
                <FormattedMessage id="actions.add" />
              </PrimaryBlueButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const ThermostatForm = compose<ThermostatFormInnerProps, ThermostatFormProps>(injectIntl)(ThermostatFormInner);

export default ThermostatForm;
