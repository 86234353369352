import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { PrimaryBlueButton } from "nvent-web/components/Button";

import style from "./CableSpacingWarningModal.module.scss";

interface CableSpacingWarningModalProps {
  onClose: () => void;
}

export const CableSpacingWarningModal: FunctionComponent<CableSpacingWarningModalProps> = ({ onClose }) => (
  <div className={style.content}>
    <h2 className={style.title}>
      <FormattedMessage id="rooms.warning" />
    </h2>
    <p className={style.message}>
      <FormattedMessage id="rooms.cableSpacingWarning" />
    </p>
    <PrimaryBlueButton onClick={onClose} className={style.button}>
      <FormattedMessage id="actions.ok" />
    </PrimaryBlueButton>
  </div>
);
