import classNames from "classnames";
import { Form, Formik } from "formik";
import { History } from "history";
import { inject } from "mobx-react";
import qs from "query-string";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as yup from "yup";

import { PrimaryBlueButton } from "nvent-web/components/Button";
import CountriesField from "nvent-web/components/form/CountriesField";
import { TextField } from "nvent-web/components/form/TextField";
import AppStore from "nvent-web/stores/App";
import { UserStore } from "nvent-web/stores/User";
import { BuildingType } from "nvent-web/types/BuildingType";

import ProjectInsulationCard from "../SelectionGuide/components/ProjectInsulationStep/components/ProjectInsulationCard/ProjectInsulationCard";
import { PROJECT_INSULATION_ITEMS } from "../SelectionGuide/components/ProjectInsulationStep/ProjectInsulationStep";
import StepHeading from "../SelectionGuide/components/StepHeading/StepHeading";

import style from "./InsulationStep.module.scss";

const validationSchema = yup.object({
  countryCode: yup.string().required(),
  projectName: yup.string(),
});

interface InsulationStepProps {
  fullWidth?: boolean;
}
interface InsulationStepInnerProps extends RouteComponentProps, InsulationStepProps {
  app: AppStore;
  user: UserStore;
  history: History;
}

interface InsulationStepState {
  selectedBuildingType: BuildingType | null;
}

class InsulationStepInner extends Component<InsulationStepInnerProps, InsulationStepState> {
  state = {
    selectedBuildingType: null,
  };

  switchBuildingType = (buildingType: BuildingType) => {
    this.setState({
      selectedBuildingType: buildingType,
    });
  };

  redirectToHomepage = () => {
    this.props.history.push("/");
  };

  submit = ({ countryCode, projectName }: { countryCode: string; projectName: string }) => {
    if (!this.state.selectedBuildingType) {
      return;
    }

    this.props.history.push({
      pathname: `/selection-guide/room`,
      search: qs.stringify({
        countryCode: this.props.user.isReady ? undefined : countryCode,
        buildingType: this.state.selectedBuildingType,
        projectName: projectName ? projectName : undefined,
      }),
    });
  };

  getInitialCountryCode = () => {
    const { locale } = this.props.app;

    if (locale === "en") {
      return "GB";
    }
    if (locale === "nn") {
      return "NO";
    }

    return locale.toUpperCase();
  };

  render() {
    return (
      <>
        <StepHeading step={1} />
        <Formik
          initialValues={{
            countryCode: this.getInitialCountryCode(),
            projectName: "",
          }}
          onSubmit={this.submit}
          validationSchema={validationSchema}
        >
          {() => (
            <Form noValidate>
              {!this.props.user.isReady && (
                <CountriesField name="countryCode" label={<FormattedMessage id="form.address.country" />} required />
              )}
              <div
                className={classNames(style.container, {
                  [style.smallTiles]: this.props.fullWidth,
                })}
              >
                <TextField label={<FormattedMessage id="selectionGuide.projectName.label" />} name="projectName" />
                {PROJECT_INSULATION_ITEMS.map(({ buildingType, icon, title, description }) => (
                  <ProjectInsulationCard
                    buildingType={buildingType}
                    key={buildingType}
                    icon={icon}
                    title={title}
                    description={description}
                    selected={this.state.selectedBuildingType === buildingType}
                    onSelect={this.switchBuildingType}
                    containerClassName={style.insulationCard}
                  />
                ))}
              </div>
              <PrimaryBlueButton
                type="submit"
                disabled={!this.state.selectedBuildingType}
                className={style.submitButton}
              >
                <FormattedMessage id="actions.next" />
              </PrimaryBlueButton>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export const InsulationStep = compose<InsulationStepInnerProps, InsulationStepProps>(
  inject("app", "user"),
  withRouter
)(InsulationStepInner);
