import axios, { AxiosError, AxiosInstance, AxiosResponseTransformer } from "axios";
import humps from "humps";
import debounce from "lodash-es/debounce";
import qs from "query-string";
import React from "react";
import { FormattedMessage } from "react-intl";

import { NotificationsStore } from "../../stores/Notifications";
import { buildTransformers } from "../../utils/transformers";

import CommissionReportGenerationResource from "./resources/CommissionReportGenerationResource";
import CommissionReportResource from "./resources/CommissionReportResource";

export default class PublicApi {
  readonly commissionReportGeneration: CommissionReportGenerationResource;
  readonly commissionReport: CommissionReportResource;
  readonly http: AxiosInstance;

  private showNetworkError = debounce(() => {
    this.notifications.createError(React.createElement(FormattedMessage, { id: "error.networkError" }));
  }, 250);

  constructor(
    baseURL: string,
    private notifications: NotificationsStore
  ) {
    this.http = axios.create({
      baseURL,
      transformResponse: buildTransformers<AxiosResponseTransformer>(axios.defaults.transformResponse, (data) =>
        data instanceof Blob ? data : humps.camelizeKeys(data)
      ),
    });
    this.http.interceptors.response.use(undefined, this.errorResponseHandler);
    this.commissionReportGeneration = new CommissionReportGenerationResource(this);
    this.commissionReport = new CommissionReportResource(this);
  }

  download(config: { url: string; params?: { [key: string]: any } }) {
    const params = humps.decamelizeKeys({ ...config.params });
    const url = `${this.http.defaults.baseURL || ""}${config.url}?${qs.stringify(params, { arrayFormat: "bracket" })}`;

    return new Promise<void>((resolve) => {
      window.location.href = url;
      resolve();
    });
  }

  private errorResponseHandler = (error: AxiosError) => {
    if (error.request && !error.response) {
      this.showNetworkError();
    }
    return Promise.reject(error);
  };
}
