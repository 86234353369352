import classNames from "classnames";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as ArrowDownIcon } from "nvent-web/assets/svg/arrowDown.svg";
import { ReactComponent as EditIcon } from "nvent-web/assets/svg/edit.svg";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import type { Level } from "nvent-web/types/Level";

import { Tooltip } from "../Tooltip";

import style from "./LevelItemCard.module.scss";

type LevelItemCardCommonProps = {
  level: Level;
  projectId: number;
  nestLevelIndex: number;
  isProjectFinished: boolean;
  onToggleNestedElements: () => void;
  isFetching: boolean;
  children?: ReactNode;
  nestedElementsList: ReactNode | undefined;
  areNestedElementsOpen: boolean;
};

type LevelItemCardProps =
  | (LevelItemCardCommonProps & {
      onEditLevelName: () => void;
      isReadOnly?: false;
    })
  | (LevelItemCardCommonProps & {
      isReadOnly: true;
    });

export const LevelItemCard = (props: LevelItemCardProps) => {
  const {
    level: { name, progress, isRootLevel, address, finishedAt },
    nestLevelIndex,
    isProjectFinished,
    onToggleNestedElements,
    areNestedElementsOpen,
    isFetching,
    isReadOnly,
    children,
    nestedElementsList,
  } = props;

  const isDark = nestLevelIndex % 2 !== 0;
  const isLevelCommissioned = Boolean(finishedAt);

  return (
    <li
      className={classNames(
        style.wrapper,
        isRootLevel ? style.wrapperShadow : style.wrapperBorder,
        isDark && style.dark
      )}
    >
      {!isProjectFinished && (
        <ProgressFull
          progress={progress}
          borderRadius="top"
          className={{ wrapper: style.progress }}
          isDisabled={isLevelCommissioned}
        />
      )}
      <div className={style.content}>
        <div className={style.header}>
          <div className={style.info}>
            <div className={style.titleWrapper}>
              <h4 className={classNames(style.title, isLevelCommissioned && style.disabled)}>{name}</h4>
              {!isReadOnly && (
                <Tooltip content={<FormattedMessage id="card.tooltip.edit" />}>
                  <button className={style.editButton}>
                    <EditIcon onClick={props.onEditLevelName} />
                  </button>
                </Tooltip>
              )}
            </div>
            <span className={style.detail}>{address?.streetAddress}</span>
          </div>
          <div className={style.contextMenus}>
            {children}
            <Tooltip
              content={
                <FormattedMessage id={areNestedElementsOpen ? "card.tooltip.collapse" : "card.tooltip.expand"} />
              }
            >
              <button
                className={classNames(style.toggleButton, areNestedElementsOpen && style.toggleButtonOpen)}
                onClick={onToggleNestedElements}
              >
                <ArrowDownIcon width={16} />
              </button>
            </Tooltip>
          </div>
        </div>
        {isFetching && (
          <div className={style.loadingWrapper}>
            <LoadingSpinner />
          </div>
        )}
        {!isFetching && areNestedElementsOpen && nestedElementsList}
      </div>
    </li>
  );
};
