import "./polyfills";

import "nvent-web/style/style.scss";
import "nvent-web/utils/validators";

import ReactDOM from "react-dom";

import App from "nvent-web/App";
import { initializeGoogleAnalytics } from "nvent-web/services/analytics";
import { ErrorBoundary } from "nvent-web/services/bugsnag";
import * as logger from "nvent-web/services/logger";
import * as munchkin from "nvent-web/services/munchkin";
import { addFeatureClasses } from "nvent-web/utils/features";

import * as serviceWorker from "./serviceWorker";

addFeatureClasses();
initializeGoogleAnalytics();

if (munchkin.shouldLoad()) {
  munchkin.load().catch((error) => {
    logger.error("Failed to load marketto", error);
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
