import { AxiosResponse } from "axios";
import { decamelizeKeys } from "humps";

import Resource from "nvent-web/services/resources/Resource";
import { AdminProductSku } from "nvent-web-admin/types/AdminProductSku";
import { Page } from "nvent-web-admin/types/Page";

interface SetAvailabilityOptions {
  productSkuId: number;
  countryCode: string;
  isAvailable: boolean;
}

export default class ProductsResource extends Resource {
  async getByPage(q = "", page?: number, pageSize?: number | "all", sortBy?: string, sortDir?: "asc" | "desc") {
    return this.http.get<Page<AdminProductSku>>("admin/product_skus", {
      params: decamelizeKeys({
        q,
        page,
        pageSize,
        sortBy,
        sortDir,
      }),
    });
  }

  readonly setAvailability = async (options: SetAvailabilityOptions) =>
    this.toggleProductAvailability(options, "countryAvailability");

  readonly setSelectionGuideAvailability = async (options: SetAvailabilityOptions) =>
    this.toggleProductAvailability(options, "selectionGuide");

  private async toggleProductAvailability(
    options: SetAvailabilityOptions,
    scope: string
  ): Promise<AxiosResponse<void>> {
    const { productSkuId, countryCode, isAvailable } = options;
    const url = `admin/product_skus/${productSkuId}/availability`;
    const data = decamelizeKeys({ [scope]: { countryCode } });

    return isAvailable ? this.http.post<void>(url, data) : this.http.delete(url, { data });
  }
}
