import { Form, Formik, FormikActions } from "formik";
import range from "lodash-es/range";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";
import * as yup from "yup";

import { AutocompleteField, AutocompleteOption } from "nvent-web/components/form/AutocompleteField";
import { DropdownField } from "nvent-web/components/form/DropdownField";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { buildMessage } from "nvent-web/i18n/yup";
import { ProductsStore } from "nvent-web/stores/Products";
import { UserStore } from "nvent-web/stores/User";
import { ProductSku } from "nvent-web/types/ProductSku";

import style from "./ByNumberForm.module.scss";

interface ByNumberFormProps {
  onSubmit: (productId: number, length?: number) => Promise<void>;
  onClose: () => void;
}

interface ByNumberFormInnerProps extends ByNumberFormProps, InjectedIntlProps {
  products: ProductsStore;
  user: UserStore;
}

interface ProductOption extends AutocompleteOption, Pick<ProductSku, "pcn" | "ean" | "seNumber" | "noNumber"> {
  searchValue: string;
}

interface FormValues {
  productNumber: string;
  length?: number;
}

class ByNumberFormInner extends Component<ByNumberFormInnerProps> {
  private readonly initialValues: FormValues = { productNumber: "" };

  @computed
  private get productOptions(): ProductOption[] {
    const { countryCode } = this.props.user.companyAddress;
    return this.props.products.availableProductSkus.map(({ description, ean, pcn, seNumber = "", noNumber = "" }) => {
      let searchValue = ean;
      if (countryCode === "SE" && seNumber) {
        searchValue = seNumber;
      } else if (countryCode === "NO" && noNumber) {
        searchValue = noNumber;
      }
      return {
        key: ean,
        title: description,
        searchValue: normalizeNumber(searchValue),
        pcn: normalizeNumber(pcn),
        ean: normalizeNumber(ean),
        seNumber: normalizeNumber(seNumber),
        noNumber: normalizeNumber(noNumber),
      };
    });
  }

  @observable
  private productNumber = "";

  @computed
  private get selectedProduct(): ProductSku | undefined {
    return this.props.products.availableProductSkus.find(
      ({ ean, pcn, seNumber = "", noNumber = "" }) =>
        normalizeNumber(ean) === this.productNumber ||
        normalizeNumber(pcn) === this.productNumber ||
        normalizeNumber(seNumber) === this.productNumber ||
        normalizeNumber(noNumber) === this.productNumber
    );
  }

  @computed
  private get isLengthRequired(): boolean {
    return Boolean(this.selectedProduct && this.selectedProduct.customLength);
  }

  private readonly productLengthOptions = range(1, 101).map((value) => ({
    title: `${value} m`,
    key: value.toString(),
  }));

  @computed
  private get schema() {
    const product = this.selectedProduct;
    const productNumber = yup.string().test("product-found", buildMessage("error.notFound"), () => !!product);

    let length = yup.string();
    if (this.isLengthRequired) {
      length = length.required();
    }

    return yup.object({ productNumber, length });
  }

  render() {
    const { onClose, intl } = this.props;
    return (
      <Formik initialValues={this.initialValues} validationSchema={this.schema} onSubmit={this.handleSubmit}>
        {({ isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate={true} autoComplete="off">
            <p className={style.notice}>
              <FormattedMessage id="product.add.byNumber.description" />
            </p>

            <AutocompleteField
              name="productNumber"
              label={<FormattedMessage id="product.add.byNumber" />}
              options={this.productOptions}
              filter={this.filterOption}
              onChange={(value) => {
                this.onProductNumberChange(value);
                setFieldValue("length", "");
                setFieldTouched("length", false);
              }}
              required
            />

            {!!this.productNumber && this.selectedProduct && <p>{this.selectedProduct.description}</p>}

            {this.isLengthRequired && (
              <DropdownField
                name="length"
                label={<FormattedMessage id="form.productLength.label" />}
                options={this.productLengthOptions}
                placeholder={intl.formatMessage({ id: "form.productLength.selectPlaceholder" })}
                required
              />
            )}

            <div className={style.actions}>
              <SubmitCancelButtons
                onCancel={onClose}
                submitLabel={<FormattedMessage id="actions.add" />}
                disabled={!isValid}
                loading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  private onProductNumberChange = (productNumber: string) => {
    this.productNumber = normalizeNumber(productNumber);
  };

  private filterOption = (productNumber: string, { ean, pcn, seNumber = "", noNumber = "" }: ProductOption) => {
    const normalizedNumber = normalizeNumber(productNumber);
    return (
      ean.includes(normalizedNumber) ||
      pcn.includes(normalizedNumber) ||
      seNumber.includes(normalizedNumber) ||
      noNumber.includes(normalizedNumber)
    );
  };

  private handleSubmit = async ({ length }: FormValues, { setSubmitting }: FormikActions<FormValues>) => {
    const { onSubmit } = this.props;

    try {
      if (this.selectedProduct) {
        await onSubmit(this.selectedProduct.id, this.isLengthRequired ? Number(length) : undefined);
      }
    } finally {
      setSubmitting(false);
    }
  };
}

const ByNumberForm = compose<ByNumberFormInnerProps, ByNumberFormProps>(
  injectIntl,
  inject("products", "user"),
  observer
)(ByNumberFormInner);

export default ByNumberForm;

function normalizeNumber(productNumber: string) {
  return productNumber ? productNumber.toLowerCase().trim() : "";
}
