export interface Auth0Address {
  /** 2-letter country code, [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) */
  country: string;
  /** State/Province */
  region: string;
  /** City */
  locality: string;
  street_address: string;
  postal_code: string;
}

export const auth0CompanyClaim = "https://nvent.com/company";
export const auth0GdprConsentClaim = "https://nvent.com/gdpr_consent";

export interface Auth0User {
  sub: string;
  updated_at: string;

  email: string;
  email_verified: boolean;
  phone_number: string;

  given_name: string;
  family_name: string;
  name: string;
  nickname: string;
  picture: string;

  offline_access: string;
  [auth0CompanyClaim]: string;
  address: Auth0Address;

  [auth0GdprConsentClaim]: "true" | "false" | boolean;
}

export interface Auth0UserUpdate
  extends Partial<Pick<Auth0User, "given_name" | "family_name" | "phone_number" | "address">> {
  company?: Auth0User[typeof auth0CompanyClaim];
  gpdr_consent?: Auth0User[typeof auth0GdprConsentClaim];
}

/** Scopes needed for Auth0 to include user properties */
const auth0UserScopesMap: Record<keyof Auth0User, string> = {
  sub: "openid",
  updated_at: "profile",

  email: "email",
  email_verified: "email",
  phone_number: "phone",

  family_name: "profile",
  given_name: "profile",
  name: "profile",
  nickname: "profile",
  picture: "profile",

  offline_access: "offline_access",

  [auth0CompanyClaim]: "openid",
  address: "address",

  [auth0GdprConsentClaim]: "openid",
};

export const auth0UserScope = Array.from(new Set(Object.values(auth0UserScopesMap))).join(" ");
