import classnames from "classnames";
import { FunctionComponent } from "react";
import Tooltip from "react-tooltip-lite";

import style from "./HelpTip.module.scss";

interface HelpTipProps {
  direction: string;
  className?: string;
  inline?: boolean;
}

export const HelpTip: FunctionComponent<HelpTipProps> = ({ direction, children, className = {}, inline = true }) => (
  <Tooltip
    content={children}
    direction={direction}
    className={classnames(style.helpTip, className, { [style.inline]: inline })}
    tipContentClassName={style.tipContent}
    arrowSize={5}
  >
    ?
  </Tooltip>
);
