import format from "date-fns/format";
import { Component } from "react";
import MediaQuery from "react-responsive";

import Card from "nvent-web/components/Card";
import { CardImage } from "nvent-web/components/Card/components/CardImage";
import { CardInitialsImage } from "nvent-web/components/Card/components/CardInitialsImage";
import DesktopCard from "nvent-web/components/Card/components/DesktopCard";
import { gridBreakpoints } from "nvent-web/lib/constants";
import { Project } from "nvent-web/types/Project";

import style from "./ProjectCard.module.scss";

interface ProjectCardProps {
  project: Project;
  actions: React.ReactNode[];
  toggleExpanded: () => void;
  isExpanded: boolean;
  hideInstaller?: boolean;
}

class ProjectCard extends Component<ProjectCardProps> {
  renderCardDetails() {
    const { clientName, clientPhoneNumber, createdAt } = this.props.project;

    return (
      <div className={style.details}>
        <div className={style.clientName}>{clientName}</div>
        <a className={style.phoneNumber} href={`tel:${clientPhoneNumber}`}>
          {clientPhoneNumber}
        </a>
        <div className={style.startDate}>{format(createdAt, "dd-MM-yyyy")}</div>
      </div>
    );
  }

  render() {
    const { actions, isExpanded, toggleExpanded } = this.props;
    const { id, name, progress, finished } = this.props.project;

    return (
      <>
        <MediaQuery minWidth={gridBreakpoints.lg}>
          {(isDesktop) => {
            if (isDesktop) {
              return (
                <DesktopCard
                  to={`/projects/${id}`}
                  actions={actions}
                  progress={progress}
                  title={name}
                  image={this.renderInstallerImage()}
                  isFinished={finished}
                >
                  {this.renderCardDetails()}
                </DesktopCard>
              );
            } else {
              return (
                <Card
                  to={`/projects/${id}`}
                  title={name}
                  image={this.renderInstallerImage()}
                  actions={actions}
                  progress={progress}
                  toggleExpanded={toggleExpanded}
                  isExpanded={isExpanded}
                  isFinished={finished}
                >
                  {this.renderCardDetails()}
                </Card>
              );
            }
          }}
        </MediaQuery>
      </>
    );
  }

  private renderInstallerImage() {
    const { installer } = this.props.project;

    if (!installer) {
      return null;
    }

    if (installer.avatar) {
      return <CardImage url={installer.avatar.urls.thumbnail} />;
    }

    return <CardInitialsImage firstName={installer.firstName} lastName={installer.lastName} />;
  }
}

export default ProjectCard;
