import range from "lodash-es/range";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import computerIcon from "nvent-web/assets/png/computer-icon.png";
import discountIcon from "nvent-web/assets/png/discount-icon.png";
import totalCareIcon from "nvent-web/assets/png/total-care-icon.png";
import termsIcon from "nvent-web/assets/svg/icon-terms.svg";
import AppStore from "nvent-web/stores/App";
import { PromotionsStore } from "nvent-web/stores/Promotions";
import { UserStore } from "nvent-web/stores/User";

import style from "./CertifiedProPage.module.scss";
import { Banner } from "./components/Banner";
import { CatalogBar } from "./components/CatalogBar";
import { getLogoByCountryCode } from "./components/getLogoByCountryCode";
import { Promotion } from "./components/Promotion";
import { getEmailByCountryCode } from "./getEmailByCountryCode";

interface CertifiedProPagePropsInner {
  user: UserStore;
  app: AppStore;
  promotions: PromotionsStore;
}

const bannerClasses = {
  image: style.bannerImage,
  text: style.bannerText,
};

class CertifiedProPageInner extends Component<CertifiedProPagePropsInner> {
  render() {
    const { user, app, promotions } = this.props;
    const contactEmail = getEmailByCountryCode(user.companyAddress.countryCode);
    const logoUrl = getLogoByCountryCode(app.locale);
    const promotionsToShow = promotions.promotionsForCurrentUser;
    return (
      <>
        <PageTitle />

        <section className={style.wrapper}>
          <h1 className={style.header}>
            <FormattedMessage id="certifiedPage.certified.header" />
          </h1>
          <Banner classes={bannerClasses}>
            <FormattedHTMLMessage id="certifiedPage.certified.bannerMessage" />
          </Banner>
        </section>
        <CatalogBar />
        <section className={style.wrapper}>
          <div className={style.headerRow}>
            <div className={style.headerIcon}>
              <img src={totalCareIcon} alt="" className={style.headerImg} />
            </div>
            <div className={style.headerText}>
              <FormattedHTMLMessage id="certifiedPage.certified.warranty" />
            </div>
          </div>

          <div className={style.row}>
            <div className={style.iconWrapper}>
              <a href={logoUrl} download="CertifiedPRO-logo" target="_blank" rel="noopener noreferrer">
                <img src={logoUrl} alt="Certified PRO Logo" className={style.iconImg} />
              </a>
            </div>
            <div className={style.columnRight}>
              <FormattedHTMLMessage id="certifiedPage.certified.certificate" />
            </div>
          </div>

          <div className={style.row}>
            <div className={style.iconWrapper}>
              <img src={computerIcon} alt="" className={style.iconImg} />
            </div>
            <div className={style.columnRight}>
              <FormattedHTMLMessage id="certifiedPage.certified.installerListed" values={{ mail: contactEmail }} />
            </div>
          </div>

          <div className={style.row} id="offers">
            <div className={style.iconWrapper}>
              <img src={discountIcon} alt="" className={style.iconImg} />
            </div>
            <div className={style.columnRight}>
              <h1 className={style.promotionsHeader}>
                <FormattedMessage id="certifiedPage.certified.activePromotions" />
              </h1>
              {!promotions.arePromotionsLoading && promotionsToShow.length === 0 && (
                <FormattedMessage id="certifiedPage.certified.activePromotions.empty" />
              )}
              {!promotions.arePromotionsLoading &&
                promotionsToShow.map((promotion) => <Promotion promotion={promotion} key={promotion.id} />)}
            </div>
          </div>

          <div className={style.row}>
            <div className={style.iconWrapper}>
              <img src={termsIcon} alt="" className={style.iconImg} />
            </div>
            <div className={style.columnRight}>
              <p>
                <FormattedHTMLMessage id="certifiedPage.certified.terms" />
              </p>
              <ul className={style.termsList}>
                {range(4).map((i) => (
                  <li key={i}>
                    <FormattedHTMLMessage id={`certifiedPage.certified.terms.${i}`} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export const CertifiedProPage = compose<CertifiedProPagePropsInner, Record<string, unknown>>(
  inject("user", "app", "promotions"),
  observer
)(CertifiedProPageInner);
