// Selectbox placeholder implementation with pure-CSS solution
// https://stackoverflow.com/a/29806043/7634578

import classNames from "classnames";
import { Field, FieldConfig, FieldProps } from "formik";
import { ChangeEvent, Component, FunctionComponent } from "react";

import { HelpTip } from "nvent-web/components/HelpTip";
import { DropdownOption } from "nvent-web/types/DropdownOption";

import style from "./DropdownField.module.scss";
import { FieldError } from "./FieldError";

type InputProps = Omit<React.InputHTMLAttributes<HTMLElement>, keyof FieldConfig | "className">;

export interface DropdownFieldProps extends InputProps, FieldConfig {
  label: React.ReactNode;
  className?: {
    wrapper?: string;
    label?: string;
    select?: string;
    inputContainer?: string;
  };
  options: DropdownOption[];
  prompt?: React.ReactNode;
  placeholder?: string;
  helpTip?: React.ReactNode;
}

export const DropdownField: FunctionComponent<DropdownFieldProps> = ({
  name,
  label,
  prompt,
  className = {},
  options = [],
  placeholder = "",
  required,
  helpTip,
  onChange,
  ...fieldProps
}) => (
  <>
    <div className={classNames(style.wrapper, className.wrapper)}>
      <label className={classNames(style.label, className.label)} htmlFor={name}>
        <span>
          {label}
          {required && "*"}
        </span>
        {helpTip && (
          <HelpTip direction="up" inline={false} className={style.helpTip}>
            {helpTip}
          </HelpTip>
        )}
      </label>
      <Field {...fieldProps} name={name}>
        {(props: FieldProps) => (
          <Dropdown
            options={options}
            placeholder={placeholder}
            required={required}
            className={className.select}
            onChange={onChange}
            {...props}
          />
        )}
      </Field>
      {prompt && <p className={style.prompt}>{prompt}</p>}
      <FieldError name={name} />
    </div>
  </>
);

interface DropdownProps extends FieldProps {
  options: DropdownOption[];
  placeholder?: string;
  required?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

class Dropdown extends Component<DropdownProps> {
  render() {
    const { placeholder, options, required, className } = this.props;
    const { name, value, onBlur } = this.props.field;

    return (
      <select
        id={name}
        name={name}
        value={value || ""}
        className={classNames(style.select, className, { [style.empty]: !value, [style.required]: required })}
        required={required}
        onChange={this.handleChange}
        onBlur={onBlur}
      >
        {placeholder && (
          <option value="" className={style.placeholder}>
            {placeholder}
          </option>
        )}
        {options.map(({ title, key }) => (
          <option key={key} value={key} className={style.option}>
            {title}
          </option>
        ))}
      </select>
    );
  }

  private handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;
    const { setFieldValue, setFieldTouched } = this.props.form;
    const { name } = this.props.field;
    const { value } = e.target;

    if (onChange) {
      onChange(e);
    }

    setFieldTouched(name);
    setFieldValue(name, value || null);
  };
}
