import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import NewProjectForm from "./components/EditProjectForm";
import style from "./EditProject.module.scss";

export const EditProject: FunctionComponent = () => (
  <div className={style.container}>
    <PageTitle>
      <FormattedMessage id="projects.edit.title" />
    </PageTitle>
    <NewProjectForm />
  </div>
);

export default EditProject;
