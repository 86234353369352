import classNames from "classnames";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { ProgressProps } from "..";

import style from "./FullProgress.module.scss";

interface ProgressFullProps extends ProgressProps {
  hideLabel?: boolean;
  borderRadius?: "top" | "none";
  isDisabled?: boolean;
  className?: {
    wrapper?: string;
    text?: string;
  };
}

const ProgressFull: FunctionComponent<ProgressFullProps> = ({
  progress,
  hideLabel,
  borderRadius,
  className = {},
  isDisabled = false,
}) => {
  const progressPercentage = `${Math.floor(progress * 100)}%`;

  return (
    <div
      className={classNames(style.progress, {
        [style.noRadius]: borderRadius === "none",
        [style.topRadius]: borderRadius === "top",
      })}
    >
      <div
        className={classNames(style.progressBar, {
          [style.noRadius]: borderRadius === "none",
          [style.topRadius]: borderRadius === "top",
          [style.disabled]: isDisabled,
        })}
        role="progressbar"
        style={{ width: progressPercentage }}
      >
        {!hideLabel && (
          <div className={classNames(style.text, className.text)}>
            {progressPercentage} <FormattedMessage id="projects.progress.completedTests" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressFull;
