import { inject, observer } from "mobx-react";
import { Component } from "react";
import MediaQuery from "react-responsive";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { compose } from "recompose";

import Hero from "nvent-web/components/Hero/Hero";
import { Notifications } from "nvent-web/components/Notifications/Notifications";
import { DESKTOP_WIDTH } from "nvent-web/lib/constants";
import Auth from "nvent-web/scenes/Auth";
import withTracker from "nvent-web/services/withTracker";
import { NotificationsStore } from "nvent-web/stores/Notifications";

import style from "./AnonymousApp.module.scss";
import { AnonymousHeader } from "./AnonymousHeader";

export interface AnonymousAppInnerProps extends RouteComponentProps {
  notifications: NotificationsStore;
}

export class AnonymousAppInner extends Component<AnonymousAppInnerProps> {
  render() {
    const { notifications } = this.props;

    return (
      <>
        <div className={style.wrapper}>
          <section className={style.main}>
            <AnonymousHeader />
            <div className={style.container}>
              <Notifications notifications={notifications} />
              <Switch>
                <Route path="/auth" exact={false} component={Auth} />
                <Redirect to="/auth" />
              </Switch>
            </div>
          </section>
          <MediaQuery minWidth={DESKTOP_WIDTH}>
            <Hero />
          </MediaQuery>
        </div>
      </>
    );
  }
}

export const AnonymousApp = compose<AnonymousAppInnerProps, Record<string, unknown>>(
  withRouter,
  withTracker,
  inject("notifications", "user"),
  observer
)(AnonymousAppInner);
