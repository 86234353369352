interface ContactDataForCountry {
  countryCode: string;
  mail: string;
  phoneNumber: string;
}

const contactDataForCountries: ContactDataForCountry[] = [
  { countryCode: "SE", mail: "salesSE@nVent.com", phoneNumber: "+46313355800" },
  { countryCode: "GB", mail: "uksales@nvent.com", phoneNumber: "+44800969013" },
  { countryCode: "AL", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "AD", mail: "ntm-sales-es@nVent.com", phoneNumber: "+34902125307" },
  { countryCode: "AT", mail: "salesAT@nVent.com", phoneNumber: "+43800297410" },
  { countryCode: "BY", mail: "salesLT@nvent.com", phoneNumber: "+375296315513" },
  { countryCode: "BE", mail: "salesBELUX@nVent.com", phoneNumber: "3216213502" },
  { countryCode: "BA", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "BG", mail: "CzechInfo@nVent.com", phoneNumber: "+420602250996" },
  { countryCode: "HR", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "CZ", mail: "CzechInfo@nVent.com", phoneNumber: "+420606069618" },
  { countryCode: "DK", mail: "salesDK@nvent.com", phoneNumber: "+4570110400" },
  { countryCode: "EE", mail: "salesLT@nvent.com", phoneNumber: "+37052136634" },
  { countryCode: "FI", mail: "salesfi@nvent.com", phoneNumber: "+358800116799" },
  { countryCode: "FR", mail: "salesfr@nvent.com", phoneNumber: "+33134407330" },
  { countryCode: "DE", mail: "salesDE@nVent.com", phoneNumber: "+498001818205" },
  { countryCode: "GR", mail: "CzechInfo@nVent.com", phoneNumber: "+420602250996" },
  { countryCode: "HU", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "IE", mail: "salesIE@nvent.com", phoneNumber: "1800654241" },
  { countryCode: "IL", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "IT", mail: "salesIT@nVent.com", phoneNumber: "+39025776151" },
  { countryCode: "KZ", mail: "salesKZ@nVent.com", phoneNumber: "+77122325554" },
  { countryCode: "LV", mail: "salesLT@nvent.com", phoneNumber: "+37052136634" },
  { countryCode: "LI", mail: "infoBaar@nVent.com", phoneNumber: "+41417663080" },
  { countryCode: "LT", mail: "salesLT@nvent.com", phoneNumber: "+37052136634" },
  { countryCode: "LU", mail: "salesBELUX@nVent.com", phoneNumber: "3216213502" },
  { countryCode: "MK", mail: "CzechInfo@nVent.com", phoneNumber: "+420602250996" },
  { countryCode: "MD", mail: "salesru@nvent.com", phoneNumber: "+74959261885" },
  { countryCode: "MC", mail: "salesfr@nvent.com", phoneNumber: "+33134407330" },
  { countryCode: "ME", mail: "salesEE@nVent.com", phoneNumber: "381230401770" },
  { countryCode: "NO", mail: "salesno@nvent.com", phoneNumber: "+4766817990" },
  { countryCode: "PL", mail: "salesPL@nvent.com", phoneNumber: "+48223313081" },
  { countryCode: "PT", mail: "ntm-sales-es@nVent.com", phoneNumber: "+34902125307" },
  { countryCode: "RO", mail: "SalesRO@nvent.com ", phoneNumber: "+40344802144" },
  { countryCode: "RU", mail: "salesru@nvent.com", phoneNumber: "+78002004344" },
  { countryCode: "RS", mail: "salesEE@nVent.com", phoneNumber: "381230401770" },
  { countryCode: "SK", mail: "CzechInfo@nVent.com", phoneNumber: "+420727904376" },
  { countryCode: "ES", mail: "ntm-sales-es@nVent.com", phoneNumber: "+34902125307" },
  { countryCode: "CH", mail: "infoBaar@nVent.com", phoneNumber: "+41417663080" },
  { countryCode: "NL", mail: "salesNL@nVent.com", phoneNumber: "+318000224978" },
  { countryCode: "TR", mail: "ntm-sales-tr@nVent.com", phoneNumber: "+902162507374" },
  { countryCode: "UA", mail: "salesLT@nvent.com", phoneNumber: "+380442245865" },
];

export default contactDataForCountries;
