import { FloorFinishOption } from "nvent-web/types/FloorFInishOption";
import { Option } from "nvent-web/types/Option";

import Resource from "./Resource";

export default class OptionsResource extends Resource {
  getFloorFinish() {
    return this.http.get<FloorFinishOption[]>("options/floor_finish");
  }

  getFloorConstruction() {
    return this.http.get<Option[]>("options/floor_construction");
  }
}
