import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";
import * as Yup from "yup";

import { TextField } from "nvent-web/components/form/TextField";
import { RequiredFieldLabel } from "nvent-web/components/RequiredFieldLabel";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";

import style from "./ProjectForm.module.scss";

interface ProjectFormProps {
  onCancel: () => void;
  initialValues: any;
  handleSubmit: (formValues: NewProjectFormValues) => void;
  isSubmitting: boolean;
  submitLabel?: React.ReactNode;
}

interface ProjectFormInnerProps extends ProjectFormProps, InjectedIntlProps {}

class ProjectFormInner extends Component<ProjectFormInnerProps> {
  schema = Yup.object({
    project: Yup.object({
      name: Yup.string().required(),
      clientName: Yup.string(),
      clientCompany: Yup.string(),
      clientEmail: Yup.string().email(),
      clientPhoneNumber: Yup.string(),

      addressAttributes: Yup.object({
        streetAddress: Yup.string().required(),
        postalCode: Yup.string(),
        locality: Yup.string().required(),
      }),
    }),
  });

  render() {
    const {
      onCancel,
      initialValues,
      handleSubmit,
      intl,
      isSubmitting,
      submitLabel = this.props.intl.formatMessage({ id: "actions.add" }),
    } = this.props;

    return (
      <>
        <Formik validationSchema={this.schema} initialValues={initialValues} onSubmit={handleSubmit}>
          {() => (
            <Form noValidate={true} className={style.form}>
              <h3 className={style.title}>{intl.formatMessage({ id: "projects.new.project.title" })}</h3>

              <div className={style.column}>
                <TextField
                  required
                  type="text"
                  name="project.name"
                  label={intl.formatMessage({ id: "form.projectName" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />
                <TextField
                  required
                  type="text"
                  name="project.addressAttributes.streetAddress"
                  label={intl.formatMessage({ id: "form.address" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />
              </div>
              <div className={style.column}>
                <div className={style.addressDetails}>
                  <TextField
                    type="text"
                    name="project.addressAttributes.postalCode"
                    label={intl.formatMessage({ id: "form.zipCode" })}
                    className={{ wrapper: style.inputLeft, label: style.label, inputContainer: style.inputContainer }}
                  />
                  <TextField
                    required
                    type="text"
                    name="project.addressAttributes.locality"
                    label={intl.formatMessage({ id: "form.locality" })}
                    className={{
                      wrapper: style.inputRight,
                      label: style.label,
                      inputContainer: style.inputContainer,
                    }}
                  />
                </div>
              </div>

              <hr className={style.separator} />

              <h3 className={style.title}>{intl.formatMessage({ id: "projects.new.client.title" })}</h3>

              <div className={style.column}>
                <TextField
                  type="text"
                  name="project.clientName"
                  label={intl.formatMessage({ id: "form.clientName" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />

                <TextField
                  type="text"
                  name="project.clientCompany"
                  label={intl.formatMessage({ id: "form.clientCompany" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />
              </div>

              <div className={style.column}>
                <TextField
                  type="email"
                  name="project.clientEmail"
                  label={intl.formatMessage({ id: "form.clientEmail" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />

                <TextField
                  type="tel"
                  name="project.clientPhoneNumber"
                  label={intl.formatMessage({ id: "form.clientPhone" })}
                  className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
                />
              </div>

              <div className={style.column}>
                <RequiredFieldLabel />
              </div>

              <div className={style.column}>
                <div className={style.actions}>
                  <SubmitCancelButtons
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onCancel={onCancel}
                    submitLabel={submitLabel}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const ProjectForm = compose<ProjectFormInnerProps, ProjectFormProps>(injectIntl, observer)(ProjectFormInner);

export default ProjectForm;
