import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { PrimaryBlueButton } from "nvent-web/components/Button";
import Modal from "nvent-web/components/Modal";

import style from "./PhotoExampleModal.module.scss";

type PhotoExampleModalContentOuterProps = {
  titleTextMessageId: string;
  descriptionTextMessageId: string;
  photoAltTextMessageId: string;
  photoSrc: string;
  onClose: () => void;
  isOpen: boolean;
};
type PhotoExampleModalContentProps = PhotoExampleModalContentOuterProps & InjectedIntlProps;

const PhotoExampleModalInner = ({
  onClose,
  isOpen,
  titleTextMessageId,
  descriptionTextMessageId,
  photoAltTextMessageId,
  photoSrc,
  intl,
}: PhotoExampleModalContentProps) => (
  <Modal center isOpen={isOpen} handleClose={onClose}>
    <div className={style.content}>
      <h2 className={style.title}>
        <FormattedMessage id={titleTextMessageId} />
      </h2>
      <img src={photoSrc} alt={intl.formatMessage({ id: photoAltTextMessageId })} className={style.examplePhoto} />
      <p className={style.message}>
        <FormattedMessage id={descriptionTextMessageId} />
      </p>
      <PrimaryBlueButton onClick={onClose} className={style.button}>
        <FormattedMessage id="actions.ok" />
      </PrimaryBlueButton>
    </div>
  </Modal>
);

export const PhotoExampleModal = compose<PhotoExampleModalContentProps, PhotoExampleModalContentOuterProps>(injectIntl)(
  PhotoExampleModalInner
);
