import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";

import { LoadingSpinner } from "../LoadingSpinner";

import style from "./common.module.scss";

export type GetQuickReportActionProps = {
  loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const GetQuickReportAction = ({
  className,
  disabled = false,
  loading = false,
  ...buttonProps
}: GetQuickReportActionProps) => (
  <button
    {...buttonProps}
    className={classNames(className, disabled && style.buttonDisabled)}
    disabled={disabled || loading}
  >
    {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.getQuickReport" />}
  </button>
);
