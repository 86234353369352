import { BlobResponse } from "nvent-web/services/BlobResponse";
import Resource from "nvent-web/services/resources/Resource";

export default class CommissionReportResource extends Resource {
  async download(projectId: string, projectToken: string, fallbackFilename = "commission_report.zip") {
    const axiosResponse = await this.http.get<BlobPart>(`projects/${projectId}/commission_reports`, {
      headers: { "Project-Authorization-Token": projectToken },
      responseType: "blob",
      baseURL: process.env.REACT_APP_PUBLIC_API_V3_URL,
    });

    new BlobResponse(axiosResponse).saveAs(fallbackFilename);
  }
}
