import { Form, Formik, FormikActions } from "formik";
import orderBy from "lodash-es/orderBy";
import range from "lodash-es/range";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import * as yup from "yup";

import LinkButton from "nvent-web/components/Button/LinkButton";
import { DropdownField } from "nvent-web/components/form/DropdownField";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { ProductsStore } from "nvent-web/stores/Products";
import { CategoryType } from "nvent-web/types/CategoryType";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { Product } from "nvent-web/types/Product";
import { ProductCategory } from "nvent-web/types/ProductCategory";
import { ProductSku } from "nvent-web/types/ProductSku";

import style from "./ByCategoryForm.module.scss";

interface FormValues {
  categoryId: string;
  productId: string;
  productSkuId: string;
  length?: string;
}

const initialValues: FormValues = {
  categoryId: "",
  productId: "",
  productSkuId: "",
  length: "",
};

interface ByCategoryFormProps {
  onSubmit: (productSkuId: number, length?: number) => Promise<void>;
  onClose: () => void;
}

interface ByCategoryFormInnerProps extends ByCategoryFormProps, InjectedIntlProps, RouteComponentProps {
  products: ProductsStore;
}

class ByCategoryFormInner extends Component<ByCategoryFormInnerProps> {
  @computed
  private get categoryOptions(): DropdownOption[] {
    const categories = this.props.products.availableProductCategoriesByType[CategoryType.product];

    return categories.map((category) => ({
      title: category.identifier,
      key: category.id.toString(),
    }));
  }

  @observable
  private selectedCategoryId?: number;

  @computed
  private get selectedCategory(): ProductCategory | undefined {
    const { products } = this.props;

    return products.availableProductCategories.find((category) => category.id === this.selectedCategoryId);
  }

  @computed
  private get products(): Product[] {
    const products = this.props.products.availableProducts.filter(
      ({ productCategoryId }) => productCategoryId === this.selectedCategoryId
    ) as Product[];

    return orderBy(products, [
      ({ cableLength }) => cableLength || Infinity,
      ({ totalOutput }) => totalOutput || Infinity,
    ]);
  }

  @computed
  private get productOptions(): DropdownOption[] {
    return this.products.map(({ id, description }) => ({
      title: description,
      key: id.toString(),
    }));
  }

  @observable
  private selectedProductId?: number;

  @computed
  private get selectedProduct(): Product | undefined {
    return this.products.find(({ id }) => id === this.selectedProductId);
  }

  @computed
  private get productSkus(): ProductSku[] {
    return this.props.products.availableProductSkus.filter(({ productId }) => productId === this.selectedProductId);
  }

  @computed
  private get productSkuOptions(): DropdownOption[] {
    return this.productSkus.map(({ id, description }) => ({
      title: description,
      key: id.toString(),
    }));
  }

  @computed
  private get isLengthRequired(): boolean {
    return Boolean(this.selectedProduct && this.selectedProduct.customLength);
  }

  private readonly productLengthOptions = range(1, 101).map((value) => ({
    title: `${value} m`,
    key: value.toString(),
  }));

  @computed
  private get schema() {
    let length = yup.string();
    if (this.isLengthRequired) {
      length = length.required();
    }

    let productSkuId = yup.string();
    if (this.productSkuOptions.length > 1) {
      productSkuId = productSkuId.required();
    }

    return yup.object({
      categoryId: yup.string().required(),
      productId: yup.string().required(),
      productSkuId,
      length,
    });
  }

  render() {
    const { intl, match } = this.props;
    return (
      <Formik initialValues={initialValues} validationSchema={this.schema} onSubmit={this.handleSubmit}>
        {({ isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form noValidate={true}>
            <p className={style.notice}>
              <FormattedMessage id="product.add.byCategory.description" />
            </p>

            <DropdownField
              name="categoryId"
              label={<FormattedMessage id="form.productCategory" />}
              options={this.categoryOptions}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                this.onCategoryChange(event);
                setFieldValue("productId", "");
                setFieldTouched("productId", false);
              }}
              placeholder={intl.formatMessage({ id: "form.productCategory.selectPlaceholder" })}
              required
            />

            {this.selectedCategory && (
              <>
                <DropdownField
                  name="productId"
                  label={intl.formatMessage({ id: "form.productName" })}
                  options={this.productOptions}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    this.onProductChange(event);
                    setFieldValue("productSkuId", "");
                    setFieldValue("length", "");
                    setFieldTouched("productSkuId", false);
                    setFieldTouched("length", false);
                  }}
                  placeholder={intl.formatMessage({ id: "form.productName.selectPlaceholder" })}
                  required
                />
              </>
            )}

            {this.selectedProduct && this.productSkuOptions.length > 1 && (
              <>
                <DropdownField
                  name="productSkuId"
                  label={intl.formatMessage({ id: "form.productSku" })}
                  options={this.productSkuOptions}
                  placeholder={intl.formatMessage({ id: "form.productSku.selectPlaceholder" })}
                  required
                />
              </>
            )}

            {this.isLengthRequired && (
              <DropdownField
                name="length"
                label={intl.formatMessage({ id: "form.productLength.label" })}
                options={this.productLengthOptions}
                placeholder={intl.formatMessage({ id: "form.productLength.selectPlaceholder" })}
                required
              />
            )}
            <div className={style.actions}>
              <SubmitCancelButtons
                onCancel={this.handleCancel}
                submitLabel={<FormattedMessage id="actions.add" />}
                disabled={!isValid}
                loading={isSubmitting}
              />
            </div>
            <LinkButton to={`${match.url}/selection-guide`} theme="primaryBlue" className={style.selectionGuideBtn}>
              <FormattedMessage id="form.productCategory.selectionGuide" />
            </LinkButton>
          </Form>
        )}
      </Formik>
    );
  }

  private onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.selectedCategoryId = Number(event.target.value);
  };

  private onProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.selectedProductId = Number(event.target.value);
  };

  private handleCancel = () => {
    this.props.onClose();
  };

  private handleSubmit = async (values: FormValues, { setSubmitting }: FormikActions<FormValues>) => {
    const length = this.isLengthRequired ? Number(values.length) : undefined;
    const productSkuId = values.productSkuId ? Number(values.productSkuId) : this.productSkus[0].id;

    try {
      await this.props.onSubmit(productSkuId, length);
    } finally {
      setSubmitting(false);
    }
  };
}

const ByCategoryForm = compose<ByCategoryFormInnerProps, ByCategoryFormProps>(
  injectIntl,
  inject("products"),
  withRouter,
  observer
)(ByCategoryFormInner);

export default ByCategoryForm;
