import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise, PENDING } from "mobx-utils";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { compose } from "recompose";

import { SecondaryBlueButton } from "nvent-web/components/Button";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import Api from "nvent-web/services/Api";
import { ProductsStore } from "nvent-web/stores/Products";
import { UserStore } from "nvent-web/stores/User";
import { CategoryRecommendation, CategoryRecommendationVariant } from "nvent-web/types/ProductRecommendation";
import contactDataForCountries from "nvent-web/utils/contactDataForCountries";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";
import { recommendationToProductSpecs } from "nvent-web/utils/recommendationToProductSpecs";

import ProductRecommendationCard from "../../../../components/ProductRecommendationCard/ProductRecommendationCard";
import StepHeading from "../StepHeading/StepHeading";

import style from "./ProductRecommendationsStep.module.scss";

interface ProjectParams {
  projectId: string;
  roomId: string;
}

interface ProductRecommendationsStepInnerProps extends RouteComponentProps<ProjectParams> {
  api: Api;
  user: UserStore;
  products: ProductsStore;
}

class ProductRecommendationsStep extends Component<ProductRecommendationsStepInnerProps> {
  @observable
  private recommendationsPromise = createInitialFromPromise<CategoryRecommendation[]>([]);

  @computed
  get isLoading(): boolean {
    return this.recommendationsPromise.state === PENDING;
  }

  @computed
  get recommendations(): CategoryRecommendation[] {
    return this.recommendationsPromise
      .case({
        pending: () => [],
        fulfilled: (value) => value,
        rejected: () => [],
      })
      .map((recommendation) => {
        const recommendationCategory = this.props.products.productCategories.find(
          ({ id }) => id === recommendation.productCategoryId
        );
        return {
          ...recommendation,
          featureDescription: recommendationCategory ? recommendationCategory.featureDescription : {},
        };
      });
  }

  @computed
  get customerSupportPhoneNumber() {
    const { companyAddress } = this.props.user;
    const matchedContactData = contactDataForCountries.find(
      ({ countryCode }) => countryCode === companyAddress.countryCode.toUpperCase()
    );
    return matchedContactData ? matchedContactData.phoneNumber : "";
  }

  @computed
  get projectId(): number {
    return parseInt(this.props.match.params.projectId, 10);
  }

  @computed
  get roomId(): number {
    return parseInt(this.props.match.params.roomId, 10);
  }

  componentDidMount() {
    this.recommendationsPromise = fromPromise(this.fetchRecommendations());
  }

  render() {
    if (this.isLoading) {
      return <LoadingSection />;
    }
    if (this.recommendations && this.recommendations.length === 0) {
      return (
        <>
          <div className={style.noProducts}>
            <FormattedMessage id="selectionGuide.noProducts" />
            <br />
            <FormattedMessage id="selectionguide.noProducts.customerService" />
            <a className={style.phoneNumber} href={`tel:${this.customerSupportPhoneNumber}`}>
              {this.customerSupportPhoneNumber}
            </a>
          </div>
          <div className={style.noProductsPhoneNumber} />
        </>
      );
    }
    return (
      <>
        <StepHeading step={3} />
        <div className={style.wrapper}>
          {this.recommendations &&
            this.recommendations.map(({ recommendations }) =>
              recommendations.map((recommendation) => (
                <ProductRecommendationCard
                  key={recommendation.productSkus[0].id}
                  recommendation={recommendation}
                  actions={
                    <SecondaryBlueButton className={style.addButton} onClick={() => this.onSelect(recommendation)}>
                      <FormattedMessage id="actions.add" />
                    </SecondaryBlueButton>
                  }
                />
              ))
            )}
        </div>
      </>
    );
  }
  private onSelect = async (selectedRecommendation: CategoryRecommendationVariant) => {
    const selectedProductSkus = recommendationToProductSpecs(selectedRecommendation);

    const { products, history } = this.props;
    await products.createProductSkus(this.projectId, this.roomId, selectedProductSkus);
    history.push(`/projects/${this.projectId}/rooms/${this.roomId}`);
  };
  private fetchRecommendations = async (): Promise<CategoryRecommendation[]> => {
    return (await this.props.api.products.getRecomendations(this.projectId, this.roomId)).data;
  };
}

export default compose<ProductRecommendationsStepInnerProps, Record<string, unknown>>(
  inject("api", "products", "user"),
  observer
)(ProductRecommendationsStep);
