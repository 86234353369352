import { FunctionComponent } from "react";

import style from "./CardImage.module.scss";

export interface CardImageProps {
  url: string;
}

export const CardImage: FunctionComponent<CardImageProps> = ({ url }) => (
  <div className={style.image} style={{ backgroundImage: `url(${url})` }} />
);
