import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import UserInitials from "nvent-web/components/UserInitials/UserInitials";
import { UserStore } from "nvent-web/stores/User";

import style from "./SidebarUser.module.scss";

interface SidebarUserInnerProps extends InjectedIntlProps {
  user: UserStore;
}

export class SidebarUserInner extends Component<SidebarUserInnerProps> {
  render() {
    const { user } = this.props;
    const accountType = user.certifiedProNumber ? "cpro" : "basic";

    return (
      <div className={style.userInfo}>
        <div className={style.userInfoInner}>
          <div className={style.userTextInfo}>
            <p className={style.userName}>{user.fullName}</p>
            <p className={style.userCompany}>{user.companyName}</p>
            <p className={style.userEmail}>{user.email}</p>
          </div>
          <div className={style.imageWrapper}>
            <div
              className={style.image}
              style={{ backgroundImage: user.avatar ? `url(${user.avatar.urls.thumbnail})` : "" }}
            >
              {!user.avatar && (
                <UserInitials className={style.initials} firstName={user.firstName} lastName={user.lastName} />
              )}
            </div>
            {user.logo && (
              <div className={style.companyImage} style={{ backgroundImage: `url(${user.logo.urls.thumbnail})` }} />
            )}
          </div>
        </div>
        <p className={style.userAccount}>
          <FormattedMessage
            id="side.menu.user.account"
            values={{
              type: (
                <span className={style.userAccountType}>
                  <FormattedMessage id={`side.menu.user.account.${accountType}`} />
                </span>
              ),
            }}
          />
        </p>
      </div>
    );
  }
}

export const SidebarUser = compose<SidebarUserInnerProps, Record<string, unknown>>(
  injectIntl,
  inject("user"),
  observer
)(SidebarUserInner);

export default SidebarUser;
