import { CommissionReportGeneration } from "nvent-web/types/CommissionReportGeneration";
import { Level, LevelWithNestedElements } from "nvent-web/types/Level";
import { NewLevelValues } from "nvent-web/types/NewLevelValues";

import Resource from "./Resource";

export default class LevelResource extends Resource {
  errors = {
    levelHasComissionedElements: 422,
    levelHasNoPhotos: 422,
  };

  create({ data, projectId }: { data: NewLevelValues; projectId: number }) {
    return this.http.post<Level>(`projects/${projectId}/levels`, data);
  }
  getOne({ projectId, levelId }: { projectId: number; levelId: number }) {
    return this.http.get<LevelWithNestedElements>(`projects/${projectId}/levels/${levelId}`);
  }
  edit({ projectId, levelId, data }: { projectId: number; levelId: number; data: Partial<NewLevelValues> }) {
    return this.http.put<Level>(`projects/${projectId}/levels/${levelId}`, data);
  }
  copy(levelId: number) {
    return this.http.post<Level>(`levels/${levelId}/duplicate`);
  }
  remove({ projectId, levelId }: { projectId: number; levelId: number }) {
    return this.http.delete(`projects/${projectId}/levels/${levelId}`);
  }
  commission(levelId: number) {
    return this.http.post(`/levels/${levelId}/commission`);
  }
  generateReport(levelId: number) {
    return this.http.post(`/levels/${levelId}/commission_reports_generation`);
  }
  fetchReportStatus(levelId: number) {
    return this.http.get<CommissionReportGeneration>(`/levels/${levelId}/commission_reports_generation`);
  }
  downloadLevelReport(url: string) {
    return this.http.get<BlobPart>(url, {
      responseType: "blob",
    });
  }
  getBOM(levelId: number) {
    return this.http.post<{ url: string }>(
      `/levels/${levelId}/bill_of_materials`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_URL,
      }
    );
  }

  moveFinishedRooms(levelId: number, newLevelId?: number) {
    return this.http.post<null>(`/levels/${levelId}/bulk_finished_rooms_parent_level_change`, {
      parentLevelId: newLevelId,
    });
  }
}
