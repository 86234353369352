import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import { EditLevelForm } from "./components/EditLevelForm";
import style from "./EditLevel.module.scss";

export const EditLevel: FunctionComponent = () => (
  <div className={style.container}>
    <PageTitle>
      <FormattedMessage id="level.address.edit.title" />
    </PageTitle>
    <EditLevelForm />
  </div>
);

export default EditLevel;
