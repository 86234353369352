import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { ReactComponent as CallSupportIcon } from "nvent-web/assets/svg/side-menu/call-support.svg";
import { ReactComponent as CertifiedIcon } from "nvent-web/assets/svg/side-menu/certified.svg";
import { ReactComponent as FinishedProjectsIcon } from "nvent-web/assets/svg/side-menu/finished-projects.svg";
import { ReactComponent as ProjectsIcon } from "nvent-web/assets/svg/side-menu/projects.svg";
import { ReactComponent as UserIcon } from "nvent-web/assets/svg/side-menu/user.svg";
import LinkButton from "nvent-web/components/Button/LinkButton";
import SidebarMenu from "nvent-web/components/SidebarMenu/SidebarMenu";
import SidebarMenuItem from "nvent-web/components/SidebarMenuItem/SidebarMenuItem";
import SidebarUser from "nvent-web/components/SidebarUser/SidebarUser";
import { certifiedPageFinishedForLocale } from "nvent-web/scenes/CertifiedPro/certifiedPageFinishedForLocale";
import AppStore from "nvent-web/stores/App";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { UserStore } from "nvent-web/stores/User";
import { Role } from "nvent-web/types/Role";
import { RequiredRole } from "nvent-web-admin/components/RequiredRole";

import style from "./SidebarItems.module.scss";

interface SidebarItemsInnerProps extends RouteComponentProps, InjectedIntlProps {
  app: AppStore;
  projects: ProjectsStore;
  user: UserStore;
}

export class SidebarItemsInner extends Component<SidebarItemsInnerProps> {
  render() {
    return (
      <div className={style.sideMenu}>
        <SidebarUser />
        {this.renderAddNewProject()}
        {this.renderMenu()}
        {this.renderFooter()}
      </div>
    );
  }

  private renderAddNewProject() {
    const { app } = this.props;

    return (
      <div className={style.addNewProject}>
        <LinkButton onClick={app.hideSideMenu} to="/projects/new" theme="secondaryBlue" fixedWidth>
          <FormattedMessage id="projects.new.title" />
        </LinkButton>
      </div>
    );
  }

  private renderMenu() {
    const { projects, user, intl, app } = this.props;

    const openProjectsCount = projects.projects.filter(
      ({ installerId, finished }) => installerId === user.id && !finished
    ).length;

    return (
      <SidebarMenu>
        <SidebarMenuItem path="/projects/open" icon={<ProjectsIcon />} count={openProjectsCount}>
          <FormattedMessage id="projects.open.title" />
        </SidebarMenuItem>
        <SidebarMenuItem path="/projects/finished" icon={<FinishedProjectsIcon />}>
          <FormattedMessage id="projects.finished.title" />
        </SidebarMenuItem>
        <SidebarMenuItem path="/settings" icon={<UserIcon className={style.userIcon} />}>
          <FormattedMessage id="projects.user.info" />
        </SidebarMenuItem>

        {certifiedPageFinishedForLocale(app.locale, Boolean(user.certifiedProNumber)) ? (
          <SidebarMenuItem path="/certifiedpro" icon={<CertifiedIcon />}>
            <FormattedMessage id="side.menu.certified" />
          </SidebarMenuItem>
        ) : (
          <SidebarMenuItem path={intl.formatMessage({ id: "certified.url" })} icon={<CertifiedIcon />} isExternal>
            <FormattedMessage id="side.menu.certified" />
          </SidebarMenuItem>
        )}
        <SidebarMenuItem path="/selection-guide" icon={<ProjectsIcon />}>
          <FormattedMessage id="selectionGuide.title" />
        </SidebarMenuItem>

        <RequiredRole userRole={Role.admin}>
          <SidebarMenuItem path="/admin" icon={<UserIcon className={style.userIcon} />}>
            <FormattedMessage id="side.menu.admin" />
          </SidebarMenuItem>
        </RequiredRole>
      </SidebarMenu>
    );
  }

  private renderFooter() {
    return (
      <div className={style.menuFooter}>
        <LinkButton
          size="medium"
          to="/customer-support"
          fixedWidth
          theme="primaryRed"
          className={style.callSupportButton}
          onClick={this.props.app.hideSideMenu}
        >
          <CallSupportIcon className={style.callSupportIcon} />
          <span className={style.callSupportText}>
            <FormattedMessage id="side.menu.button.call" />
          </span>
        </LinkButton>
        <div className={style.footerText}>
          <a href="https://www.nvent.com/terms-use" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="side.menu.terms" />
          </a>
          <br />
          <a href="https://www.nvent.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="side.menu.policy" />
          </a>
        </div>
      </div>
    );
  }
}

export const SidebarItems = compose<SidebarItemsInnerProps, Record<string, unknown>>(
  withRouter,
  injectIntl,
  inject("app", "user", "projects"),
  observer
)(SidebarItemsInner);

export default SidebarItems;
