import classNames from "classnames";
import { ErrorMessage, ErrorMessageProps } from "formik";
import { inject } from "mobx-react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { Message } from "nvent-web/i18n/yup";
import AppStore from "nvent-web/stores/App";

import style from "./FieldError.module.scss";

export interface FieldErrorProps extends Omit<ErrorMessageProps, "className" | "component"> {
  name: string;
  className?: {
    container?: string;
    message?: string;
  };
}

export const FieldError: FunctionComponent<FieldErrorProps> = ({ className = {}, name }) => (
  <div className={classNames(className.container, style.container)}>
    <p className={classNames(className.message, style.message)}>
      <ErrorMessage component={FormattedErrorMessage} name={name} />
    </p>
  </div>
);

export interface FormattedErrorMessageInnerProps {
  app: AppStore;
}

export const FormattedErrorMessageInner: FunctionComponent<FormattedErrorMessageInnerProps> = ({ children }) => {
  if (typeof children === "string") {
    return <>{children}</>;
  }

  return <FormattedMessage {...(children as Message)} />;
};

export const FormattedErrorMessage = compose<FormattedErrorMessageInnerProps, Record<string, unknown>>(
  inject(({ app }: { app: AppStore }) => app.locale)
)(FormattedErrorMessageInner);
