import classnames from "classnames";
import { Form, Formik } from "formik";
import noop from "lodash-es/noop";
import { observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import prettyBytes from "pretty-bytes";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";
import * as yup from "yup";

import { SecondaryButton } from "nvent-web/components/Button";
import FileButton, { defaultMaxSize, ValidatedFile } from "nvent-web/components/Button/FileButton";
import { CheckboxField } from "nvent-web/components/form/CheckboxField";
import CountriesField from "nvent-web/components/form/CountriesField";
import { TextField } from "nvent-web/components/form/TextField";
import Modal from "nvent-web/components/Modal";
import PhotoContainer from "nvent-web/components/PhotoContainer/PhotoContainer";
import { RequiredFieldLabel } from "nvent-web/components/RequiredFieldLabel";
import { SubmitButton } from "nvent-web/components/SubmitButton";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { UserStore } from "nvent-web/stores/User";
import { Address } from "nvent-web/types/Address";

import style from "./UserSettingsForm.module.scss";

export interface UserSettingsFormInnerProps extends InjectedIntlProps {
  user: UserStore;
  notifications: NotificationsStore;
}

interface Values {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  companyAddress: Address;

  gaveGdprConsent: boolean;
  shareProjects: boolean;
}

const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required().phoneNumber(),
  companyName: yup.string().required(),
  companyAddress: yup
    .object({
      streetAddress: yup.string().required(),
      postalCode: yup.string().required(),
      locality: yup.string().required(),
      countryCode: yup.string().required(),
      region: yup.string(),
    })
    .required(),

  gaveGdprConsent: yup.boolean(),
  shareProjects: yup.boolean(),
});

export class UserSettingsFormInner extends Component<UserSettingsFormInnerProps> {
  @observable
  submitPromise?: IPromiseBasedObservable<void>;

  @observable
  disabledFieldsModalOpen = false;

  private dispose: () => void = noop;

  componentDidMount() {
    const { user } = this.props;

    this.dispose = when(
      () => user.isReady,
      () => {
        if (user.shouldResumeProfileUpdate) {
          this.resumeSubmit();
        }
      }
    );
  }

  componentWillUnmount() {
    this.dispose();
  }

  render() {
    const { user, intl } = this.props;
    const {
      firstName,
      lastName,
      phoneNumber,
      companyName,
      companyAddress,
      certifiedProNumber,
      email,
      consents: { marketing: gaveGdprConsent, shareableProjects: shareProjects },
      isLogoCreating,
      logo,
      isAvatarCreating,
      avatar,
      isProfileComplete,
    } = user;

    const initialValues: Values = {
      firstName,
      lastName,
      phoneNumber,
      companyName,
      companyAddress,
      gaveGdprConsent,
      shareProjects,
    };

    const isSubmitting = user.isProfileUpdating;

    return (
      <div>
        <Modal
          isOpen={this.disabledFieldsModalOpen}
          handleClose={this.closeDisabledFieldsModal}
          bodyClass={style.modal}
          center
        >
          <div className={style.modalContent}>
            <div className={style.modalDescription}>
              <FormattedMessage id="user.settings.disabledFieldModalMessage" />
            </div>
            <div className={style.modalContact}>
              <a href={`mailto:${intl.formatMessage({ id: "support.email" })}`}>
                <FormattedMessage id="support.email" />
              </a>
              <br />
              <a href={`tel:${intl.formatMessage({ id: "support.phoneNumber" })}`}>
                <FormattedMessage id="support.phoneNumber" />
              </a>
            </div>
            <SecondaryButton className={style.cancelBtn} onClick={this.closeDisabledFieldsModal}>
              <FormattedMessage id="actions.cancel" />
            </SecondaryButton>
          </div>
        </Modal>

        {!isProfileComplete && (
          <div className={style.incompleteProfileAlert}>
            <FormattedMessage id="user.settings.incompleteProfile" />
          </div>
        )}

        <Formik initialValues={initialValues} onSubmit={this.submit} validationSchema={validationSchema}>
          {() => (
            <Form noValidate={true} className={style.form}>
              <div className={style.userInfoWrapper}>
                <h2 className={style.userInfoLabel}>
                  <FormattedMessage id="user.avatar" />
                </h2>

                <div className={style.photoWrapper}>
                  <div className={style.photo}>
                    <FileButton
                      disabled={!!avatar}
                      loading={isAvatarCreating}
                      onChange={this.handleAddAvatar}
                      className={classnames(style.fileButton, { [style.fileNoTheme]: !!avatar })}
                    >
                      {avatar ? (
                        <PhotoContainer photo={avatar} onRemove={this.handleRemoveAvatar} />
                      ) : (
                        <FormattedMessage id="user.avatar" />
                      )}
                    </FileButton>
                  </div>
                </div>

                <div className={style.column}>
                  {certifiedProNumber && (
                    <>
                      <div className={style.certifiedProNumberTitle}>
                        <FormattedMessage id="form.certifiedProNumber" />
                      </div>
                      <div className={style.certifiedProNumberValue}>{certifiedProNumber}</div>
                    </>
                  )}
                </div>

                <div className={style.column}>
                  <div className={style.emailTitle}>
                    <FormattedMessage id="form.clientEmail" />
                  </div>
                  <div className={style.emailValue} onClick={this.openDisabledFieldsModal}>
                    {email}
                  </div>
                </div>
              </div>
              <div className={style.column}>
                <TextField
                  name="firstName"
                  label={<FormattedMessage id="form.firstName" />}
                  required
                  disabled={isSubmitting}
                />
              </div>
              <div className={style.column}>
                <TextField
                  name="lastName"
                  label={<FormattedMessage id="form.lastName" />}
                  required
                  disabled={isSubmitting}
                />
              </div>
              <div className={style.column}>
                <TextField
                  type="tel"
                  name="phoneNumber"
                  label={<FormattedMessage id="form.phoneNumber" />}
                  required
                  disabled={isSubmitting}
                />
              </div>
              <hr className={style.separator} />
              <h2 className={style.userInfoLabel}>
                <FormattedMessage id="company.info" />
              </h2>
              <div className={style.photoWrapper}>
                <div className={style.photo}>
                  <FileButton
                    disabled={!!logo || isLogoCreating}
                    loading={isLogoCreating}
                    onChange={this.handleAddLogo}
                    className={classnames(style.fileButton, { [style.fileNoTheme]: !!logo })}
                  >
                    {logo ? (
                      <PhotoContainer photo={logo} onRemove={this.handleRemoveLogo} />
                    ) : (
                      <FormattedMessage id="company.logo" />
                    )}
                  </FileButton>
                </div>
              </div>
              <div className={style.fullWidth}>
                <div className={style.column}>
                  <CheckboxField
                    disabled={isSubmitting}
                    name="shareProjects"
                    label={<FormattedMessage id="form.shareableProjects" />}
                    className={{ label: style.checkbox, container: style.checkboxContainer }}
                  />
                </div>
              </div>
              {certifiedProNumber ? (
                <div className={style.column}>
                  <div className={style.companyNameTitle}>
                    <FormattedMessage id="form.clientCompany" />
                  </div>
                  <div className={style.companyName} onClick={this.openDisabledFieldsModal}>
                    {companyName}
                  </div>
                </div>
              ) : (
                <div className={style.column}>
                  <TextField
                    disabled={isSubmitting}
                    name="companyName"
                    label={<FormattedMessage id="form.companyName" />}
                    required
                  />
                </div>
              )}
              <div className={style.column}>
                <TextField
                  disabled={isSubmitting}
                  name="companyAddress.streetAddress"
                  label={<FormattedMessage id="form.address.streetAddress" />}
                  required
                />
              </div>
              <div className={style.column}>
                <div className={style.row}>
                  <div className={style.shortField}>
                    <TextField
                      disabled={isSubmitting}
                      name="companyAddress.postalCode"
                      label={<FormattedMessage id="form.address.postalCode" />}
                      required
                    />
                  </div>

                  <div className={style.shortField}>
                    <TextField
                      disabled={isSubmitting}
                      name="companyAddress.locality"
                      label={<FormattedMessage id="form.address.locality" />}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={style.column}>
                <div className={style.row}>
                  <div className={style.shortField}>
                    <TextField
                      disabled={isSubmitting}
                      name="companyAddress.region"
                      label={<FormattedMessage id="form.address.region" />}
                    />
                  </div>

                  <div className={style.shortField}>
                    <CountriesField
                      disabled={isSubmitting}
                      name="companyAddress.countryCode"
                      label={<FormattedMessage id="form.address.country" />}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={style.column}>
                <CheckboxField
                  disabled={isSubmitting}
                  name="gaveGdprConsent"
                  label={<FormattedMessage id="form.consents.newsletter" />}
                  className={{ label: style.checkbox, container: style.checkboxContainer }}
                />
              </div>
              <div className={style.fullWidth}>
                <div className={style.column}>
                  <RequiredFieldLabel />
                </div>
                <div className={style.column}>
                  <SubmitButton disabled={isSubmitting} loading={isSubmitting}>
                    <FormattedMessage id="actions.save" />
                  </SubmitButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  private openDisabledFieldsModal = () => {
    this.disabledFieldsModalOpen = true;
  };

  private closeDisabledFieldsModal = () => {
    this.disabledFieldsModalOpen = false;
  };

  private handleRemoveLogo = (logoId: number) => {
    this.props.user.deleteLogo(logoId);
  };

  private handleAddLogo = ([logo]: ValidatedFile[]) => {
    if (!logo) {
      return;
    }

    if (logo.isValid) {
      this.props.user.createLogo(logo.file);
    } else if (logo.errors.type) {
      this.props.notifications.createError(<FormattedMessage id="error.image.type" />);
    } else if (logo.errors.size) {
      this.props.notifications.createError(
        <FormattedMessage id="error.image.maxSize" values={{ maxSize: prettyBytes(defaultMaxSize) }} />
      );
    }
  };

  private handleRemoveAvatar = (avatarId: number) => {
    this.props.user.deleteAvatar(avatarId);
  };

  private handleAddAvatar = ([avatar]: ValidatedFile[]) => {
    if (!avatar) {
      return;
    }

    if (avatar.isValid) {
      this.props.user.createAvatar(avatar.file);
    } else if (avatar.errors.type) {
      this.props.notifications.createError(<FormattedMessage id="error.image.type" />);
    } else if (avatar.errors.size) {
      this.props.notifications.createError(
        <FormattedMessage id="error.image.maxSize" values={{ maxSize: prettyBytes(defaultMaxSize) }} />
      );
    }
  };

  private submit = (values: Values) => {
    this.submitPromise = fromPromise(this.save(values));
  };

  private resumeSubmit = () => {
    this.submitPromise = fromPromise(this.resumeSave());
  };

  private async save(values: Values) {
    const { user, notifications } = this.props;

    const { firstName, lastName, phoneNumber, companyName, companyAddress, gaveGdprConsent, shareProjects } = values;

    await user.updateProfile({
      firstName,
      lastName,
      phoneNumber,
      companyName,
      companyAddress,
      consents: {
        marketing: gaveGdprConsent,
        shareableProjects: shareProjects,
      },
    });

    if (!user.shouldResumeProfileUpdate) {
      await notifications.createSuccess(<FormattedMessage id="user.profileSaved" />);
    }
  }

  private async resumeSave() {
    const { user, notifications } = this.props;

    await user.resumeProfileUpdate();
    await notifications.createSuccess(<FormattedMessage id="user.profileSaved" />);
  }
}

export const UserSettingsForm = compose<UserSettingsFormInnerProps, Record<string, unknown>>(
  inject("user", "notifications"),
  injectIntl,
  observer
)(UserSettingsFormInner);

export default UserSettingsForm;
