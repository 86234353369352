import { History } from "history";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import ProjectForm from "nvent-web/components/ProjectForm";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";

interface EditProjectParams {
  projectId: string;
}

interface EditProjectFormInnerProps extends RouteComponentProps<EditProjectParams>, InjectedIntlProps {
  projects: ProjectsStore;
  history: History;
}

class EditProjectFormInner extends Component<EditProjectFormInnerProps> {
  initialValues(project: DetailedProject): NewProjectFormValues {
    return {
      project: {
        name: project.name,
        addressAttributes: {
          streetAddress: project.address.streetAddress,
          postalCode: project.address.postalCode,
          locality: project.address.locality,
        },
        clientName: project.clientName,
        clientCompany: project.clientCompany,
        clientEmail: project.clientEmail,
        clientPhoneNumber: project.clientPhoneNumber,
      },
    };
  }

  componentDidMount() {
    this.props.projects.getProject(parseInt(this.props.match.params.projectId, 10));
  }

  renderForm(values: DetailedProject) {
    const { projects, intl } = this.props;

    return (
      <ProjectForm
        initialValues={this.initialValues(values)}
        onCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
        isSubmitting={projects.isProjectLoading}
        submitLabel={intl.formatMessage({ id: "actions.update" })}
      />
    );
  }

  render() {
    const { projectDetails, areProjectDetailsLoading } = this.props.projects;

    if (areProjectDetailsLoading) {
      return <LoadingSection />;
    }

    return <>{projectDetails && this.renderForm(projectDetails)}</>;
  }

  private handleSubmit = async (formValues: NewProjectFormValues) => {
    const { projects } = this.props;
    if (projects.projectDetails) {
      await projects.updateProject(formValues, projects.projectDetails.id);
      this.props.history.push(`/projects/${projects.projectDetails.id}`);
    }
  };

  private handleCancel = () => {
    this.props.history.goBack();
  };
}

const EditProjectForm = compose<EditProjectFormInnerProps, Record<string, unknown>>(
  inject("projects"),
  injectIntl,
  withRouter,
  observer
)(EditProjectFormInner);

export default EditProjectForm;
