import { FunctionComponent } from "react";

import style from "./CardInitialsImage.module.scss";

export interface CardInitialsImageProps {
  firstName: string;
  lastName: string;
}

export const CardInitialsImage: FunctionComponent<CardInitialsImageProps> = ({ firstName, lastName }) => (
  <div className={style.image}>
    {firstName.trim().charAt(0)}
    {lastName.trim().charAt(0)}
  </div>
);
