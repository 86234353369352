import { inject } from "mobx-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { AddLevelOrRoomModal } from "nvent-web/components/AddLevelOrRoomModal/AddLevelOrRoomModal";
import { Button } from "nvent-web/components/Button";
import { CommissionedLabel } from "nvent-web/components/CommissionedLabel";
import { EditLevelNameModal } from "nvent-web/components/EditLevelNameModal/EditLevelNameModal";
import { LevelItemCard } from "nvent-web/components/LevelItemCard";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { useGenerateReport } from "nvent-web/hooks/useGenerateReport";
import { useGetBOM } from "nvent-web/hooks/useGetBOM";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import type { Level } from "nvent-web/types/Level";
import type { Room } from "nvent-web/types/Room";
import { STOP_POLLING_STATUSES } from "nvent-web/utils/polling";

import { ContextMenu, ContextMenuAction } from "../ContextMenu/ContextMenu";
import { RoomItem } from "../RoomItem";

import style from "./LevelItem.module.scss";
import { useManageNestedElements } from "./useManageNestedElements";

type LevelItemOuterProps = {
  level: Level;
  projectId: number;
  nestLevelIndex: number;
  isAddLevelSubmitting: boolean;
  isCopyLevelSubmitting: boolean;
  isEditNameSubmitting: boolean;
  isCommissionLevelSubmitting: boolean;
  isRemoveLevelSubmitting: boolean;
  isCopyRoomSubmitting: boolean;
  isCommissionRoomSubmitting: boolean;
  isProjectFinished: boolean;
  onAddLevel: (id?: number) => void;
  onCopyLevel: (id: number) => void;
  onEditLevelName: (id: number, name: string) => void;
  onCommissionLevel: (id: number) => void;
  onRemoveLevel: (id: number) => void | Promise<void>;
  onCopyRoom: (id: number) => void;
  onEditRoom: (id: number) => void;
  onCommissionRoom: (id: number) => void;
  onRemoveRoom: (id: number) => void;
};

type LevelItemInnerProps = { api: Api; notifications: NotificationsStore };

type LevelItemProps = LevelItemInnerProps & LevelItemOuterProps;

const LevelItemInner = (props: LevelItemProps) => {
  const {
    api,
    notifications,
    projectId,
    level,
    nestLevelIndex,
    isAddLevelSubmitting,
    isCopyLevelSubmitting,
    isEditNameSubmitting,
    isCommissionLevelSubmitting,
    isRemoveLevelSubmitting,
    isProjectFinished,
    onAddLevel,
    onCopyLevel,
    onEditLevelName,
    onCommissionLevel,
    onRemoveLevel,
  } = props;

  const { id, name, progress, isRootLevel, canHaveNestedLevels, finishedAt } = level;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isCommissionLevelModalOpen, setIsCommissionLevelModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { generateReport, isGenerateReportSubmitting } = useGenerateReport({
    id,
    notificationsStore: notifications,
    fetchReportStatusCallback: api.levels.fetchReportStatus.bind(api.levels),
    generateReportCallback: api.levels.generateReport.bind(api.levels),
    downloadReportCallback: api.levels.downloadLevelReport.bind(api.levels),
    isBlobResponse: true,
    stopPollingStatuses: STOP_POLLING_STATUSES,
    pollingInterval: 3000,
  });

  const { getBOM, isGetBOMSubmitting } = useGetBOM({
    id,
    getBOMCallback: api.levels.getBOM.bind(api.levels),
    notifications,
  });

  const { areNestedElementsOpen, isFetching, nestedLevels, nestedRooms, addLevelToExpanded, toggleNestedElements } =
    useManageNestedElements({ api: api.levels, id, notifications, projectId, key: "installerExpandedLevels" });

  const handleCommission = () => onCommissionLevel(id);
  const handleCopyLevel = () => onCopyLevel(id);

  const handleAddLevel = (parentLevelId?: number) => {
    if (parentLevelId === id) {
      addLevelToExpanded(id);
    }
    onAddLevel(parentLevelId);
  };

  const isLevelCommissioned = Boolean(finishedAt);

  const editAddressElement = isRootLevel
    ? [
        {
          type: "link" as const,
          to: `/projects/${projectId}/levels/${id}/edit`,
          labelId: "contextMenu.editAddress",
        },
      ]
    : [];

  const commissionElement = !isLevelCommissioned
    ? [
        {
          type: "button" as const,
          labelId: "actions.commissioning",
          isLoading: isCommissionLevelSubmitting,
          isDisabled: progress < 1 || isCommissionLevelSubmitting,
          onClick: () => setIsCommissionLevelModalOpen(true),
        },
      ]
    : [];

  const removeElement = !isLevelCommissioned
    ? [
        {
          type: "button" as const,
          labelId: "actions.remove",
          onClick: () => setIsRemoveModalOpen(true),
          className: style.removeAction,
        },
      ]
    : [];

  const levelActions: ContextMenuAction[] = [
    ...editAddressElement,
    {
      type: "button",
      labelId: "actions.copy",
      isLoading: isCopyLevelSubmitting,
      onClick: handleCopyLevel,
    },
    {
      type: "button",
      labelId: "actions.getBOM",
      isLoading: isGetBOMSubmitting,
      onClick: async (closeMenu) => {
        await getBOM();
        closeMenu && closeMenu();
      },
    },
    ...commissionElement,
    {
      type: "button",
      labelId: "actions.getPDF",
      isDisabled: !isLevelCommissioned || isGenerateReportSubmitting,
      isLoading: isGenerateReportSubmitting,
      onClick: async (closeMenu) => {
        await generateReport();
        closeMenu && closeMenu();
      },
    },
    ...removeElement,
  ];

  return (
    <>
      <LevelItemCard
        level={level}
        projectId={projectId}
        nestLevelIndex={nestLevelIndex}
        isProjectFinished={isProjectFinished}
        onEditLevelName={() => setIsEditMode(true)}
        areNestedElementsOpen={areNestedElementsOpen}
        onToggleNestedElements={toggleNestedElements}
        isFetching={isFetching}
        nestedElementsList={
          areNestedElementsOpen ? (
            <NestedElementsList nestedLevels={nestedLevels} nestedRooms={nestedRooms} {...props} />
          ) : undefined
        }
      >
        {!isProjectFinished && (
          <>
            {isLevelCommissioned && <CommissionedLabel />}
            <ContextMenu actions={levelActions} />
            {!isLevelCommissioned && (
              <Button theme="secondaryBlue" className={style.addButton} onClick={() => setIsAddModalOpen(true)}>
                <FormattedMessage id="actions.add" />
              </Button>
            )}
          </>
        )}
      </LevelItemCard>

      <AddLevelOrRoomModal
        isOpen={isAddModalOpen}
        projectId={projectId}
        parentLevelId={id}
        isAddLevelLoading={isAddLevelSubmitting}
        disableLevels={!canHaveNestedLevels}
        onAddLevel={handleAddLevel}
        onClose={() => setIsAddModalOpen(false)}
      />
      <EditLevelNameModal
        isOpen={isEditMode}
        currentName={name}
        center
        loading={isEditNameSubmitting}
        onConfirm={(levelName) => onEditLevelName(id, levelName)}
        handleClose={() => setIsEditMode(false)}
      />
      <ConfirmationModal
        isOpen={isRemoveModalOpen}
        title={<FormattedMessage id="level.removeModal.title" />}
        description={<FormattedMessage id="level.removeModal.description" />}
        center
        loading={isRemoveLevelSubmitting}
        onConfirm={async () => {
          await onRemoveLevel(id);
          setIsRemoveModalOpen(false);
        }}
        onCancel={() => setIsRemoveModalOpen(false)}
        handleClose={() => setIsRemoveModalOpen(false)}
      />
      <ConfirmationModal
        center
        isOpen={isCommissionLevelModalOpen}
        handleClose={() => setIsCommissionLevelModalOpen(false)}
        title={<FormattedMessage id="level.commissionConfirmationModal.title" />}
        description={<FormattedMessage id="level.commissionConfirmationModal.description" />}
        onCancel={() => setIsCommissionLevelModalOpen(false)}
        loading={isCommissionLevelSubmitting}
        onConfirm={handleCommission}
      />
    </>
  );
};

type NestedElementsListProps = {
  nestedLevels: Level[];
  nestedRooms: Room[];
} & LevelItemProps;

const NestedElementsList = ({
  projectId,
  level,
  nestedLevels,
  nestLevelIndex,
  nestedRooms,
  isAddLevelSubmitting,
  isCopyLevelSubmitting,
  isEditNameSubmitting,
  isCommissionLevelSubmitting,
  isRemoveLevelSubmitting,
  isCopyRoomSubmitting,
  isCommissionRoomSubmitting,
  isProjectFinished,
  onAddLevel,
  onCopyLevel,
  onEditLevelName,
  onCommissionLevel,
  onRemoveLevel,
  onCopyRoom,
  onEditRoom,
  onCommissionRoom,
  onRemoveRoom,
}: NestedElementsListProps) => {
  const hasAnyNestedElements = nestedLevels.length || nestedRooms.length;

  if (!hasAnyNestedElements) {
    return (
      <p className={style.empty}>
        <FormattedMessage id="level.messages.noContent" />
      </p>
    );
  }

  return (
    <ul className={style.nestedElements}>
      {nestedLevels.map((level) => (
        <LevelItem
          key={level.id}
          level={level}
          nestLevelIndex={nestLevelIndex + 1}
          projectId={projectId}
          isProjectFinished={isProjectFinished}
          isEditNameSubmitting={isEditNameSubmitting}
          isAddLevelSubmitting={isAddLevelSubmitting}
          isRemoveLevelSubmitting={isRemoveLevelSubmitting}
          isCopyLevelSubmitting={isCopyLevelSubmitting}
          isCopyRoomSubmitting={isCopyRoomSubmitting}
          isCommissionRoomSubmitting={isCommissionRoomSubmitting}
          onCopyLevel={onCopyLevel}
          onRemoveLevel={onRemoveLevel}
          onAddLevel={onAddLevel}
          onRemoveRoom={onRemoveRoom}
          onEditRoom={onEditRoom}
          onEditLevelName={onEditLevelName}
          onCopyRoom={onCopyRoom}
          onCommissionRoom={onCommissionRoom}
          onCommissionLevel={onCommissionLevel}
          isCommissionLevelSubmitting={isCommissionLevelSubmitting}
        />
      ))}
      {nestedRooms.length > 0 && (
        <ul className={style.roomsList}>
          {nestedRooms.map((room, index) => (
            <RoomItem
              key={room.id}
              projectId={projectId}
              data={room}
              levelId={level.id}
              index={index}
              nestLevelIndex={nestLevelIndex + 1}
              isCopyRoomSubmitting={isCopyRoomSubmitting}
              onRemove={onRemoveRoom}
              onEdit={onEditRoom}
              onCopy={onCopyRoom}
              onCommission={onCommissionRoom}
              isCommissionSubmitting={isCommissionRoomSubmitting}
              isProjectFinished={isProjectFinished}
            />
          ))}
        </ul>
      )}
    </ul>
  );
};

export const LevelItem = compose<LevelItemProps, LevelItemOuterProps>(
  inject("api", "notifications"),
  withRouter
)(LevelItemInner);
