import { FunctionComponent } from "react";
import { FormattedNumber } from "react-intl";

interface FormattedMeasurementProps {
  value: number;
  fractionDigits: number;
  unit: React.ReactNode;
  className?: {
    container?: string;
    unit?: string;
  };
}

const FormattedMeasurement: FunctionComponent<FormattedMeasurementProps> = ({
  value,
  fractionDigits,
  unit,
  className = {},
}) => (
  <span className={className.container}>
    <FormattedNumber value={value} minimumFractionDigits={fractionDigits} maximumFractionDigits={fractionDigits} />
    <span className={className.unit}>{unit}</span>
  </span>
);

export default FormattedMeasurement;
