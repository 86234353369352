import { Photo } from "nvent-web/types/Photo";

import Resource from "./Resource";

export default class StickerPhotoResource extends Resource {
  create(projectId: number, roomId: number, photos: File[]) {
    const formPayload = new FormData();

    photos.forEach((photo) => formPayload.append("images[]", photo));

    return this.http.post<Photo[]>(`projects/${projectId}/rooms/${roomId}/sticker_photos`, formPayload);
  }

  delete(projectId: number, roomId: number, photoId: number) {
    return this.http.delete<Photo[]>(`projects/${projectId}/rooms/${roomId}/sticker_photos/${photoId}`);
  }
}
