import { FunctionComponent } from "react";

import LocalePicker from "nvent-web/scenes/Auth/components/LocalePicker";

import style from "./AnonymousHeader.module.scss";

export const AnonymousHeader: FunctionComponent = () => (
  <>
    <div className={style.placeholder} />
    <div className={style.root}>
      <div className={style.container}>
        <a href="https://www.nvent.com/raychem" target="_blank" rel="noopener noreferrer">
          <div className={style.logo} />
        </a>
        <div className={style.spark} />
        <div className={style.picker}>
          <LocalePicker dark />
        </div>
      </div>
    </div>
  </>
);
