import { inject, observer } from "mobx-react";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { Project } from "nvent-web/types/Project";

import style from "./ProjectList.module.scss";
import { ProjectListItem } from "./ProjectListItem";

export type ProjectListPropsOuter = {
  projectsList: Project[];
  isLoading?: boolean;
  emptyMessage?: ReactNode;
};

export type ProjectListProps = ProjectListPropsOuter & {
  projects: ProjectsStore;
};

const ProjectListInner = ({
  projects,
  projectsList,
  isLoading: loading,
  emptyMessage = <FormattedMessage id="projects.noContent" />,
}: ProjectListProps) => (
  <div className={style.root}>
    {loading && <LoadingSection />}
    {!loading && projectsList.length === 0 && <h2 className={style.noContent}>{emptyMessage}</h2>}

    {projectsList.map((project) => {
      const { isExpanded, toggleExpanded } = projects.getLocalState(project.id);
      return (
        <ProjectListItem key={project.id} project={project} isExpanded={isExpanded} onToggleExpanded={toggleExpanded} />
      );
    })}
  </div>
);

export const ProjectList = compose<ProjectListProps, ProjectListPropsOuter>(
  inject("projects"),
  observer
)(ProjectListInner);
