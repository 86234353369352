import debounce from "lodash-es/debounce";
import { action, autorun, computed, Lambda, observable, onBecomeObserved, onBecomeUnobserved, reaction } from "mobx";
import { fromPromise } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { ProjectFilters } from "nvent-web/services/resources/ProjectResource";
import { Project } from "nvent-web/types/Project";
import { SearchState } from "nvent-web/types/SearchState";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";

export type Owner = "my" | "team";

export interface ProjectSearchFilters {
  query: string;
  status: SearchState;
  owner: Owner;
}

export type ProjectSearchInitialFilters = Partial<ProjectSearchFilters>;

export class ProjectSearch implements ProjectSearchFilters {
  @observable query = "";
  @observable status: SearchState = "open";
  @observable owner: Owner = "my";

  @observable resultsPromise = createInitialFromPromise<Project[]>([]);
  @observable results: Project[] = [];

  @computed
  get areResultsLoading() {
    return this.resultsPromise.state === "pending";
  }

  private get filters(): ProjectFilters {
    return {
      search: this.query,
      state: this.status,
    };
  }

  constructor(
    private api: Api,
    initialFilters?: ProjectSearchInitialFilters
  ) {
    if (initialFilters) {
      Object.assign(this, initialFilters);
    }

    this.searchChanged = debounce(this.searchChanged, 200);

    autorun(() => {
      if (this.resultsPromise.state === "fulfilled") {
        this.results = this.resultsPromise.value;
      }
    });

    this.autoLoadResults();
  }

  @action
  loadResults = async () => {
    return (this.resultsPromise = fromPromise(this.search()));
  };

  @action
  reloadResults = async () => {
    this.results = [];
    return this.loadResults();
  };

  @action
  searchChanged = (query: string) => {
    this.query = query.length >= 3 ? query : "";
  };

  @action
  ownerChanged = (owner: Owner) => {
    this.owner = owner;
  };

  autoLoadResults() {
    // when someone starts observing results, load them and keep reloading them
    // whenever filters change
    onBecomeObserved(this, "results", () => {
      this.loadResults();

      const disposers: Lambda[] = [
        reaction(() => this.query, this.loadResults),
        reaction(() => [this.status, this.owner], this.reloadResults),
        onBecomeUnobserved(this, "results", () => {
          disposers.forEach((disposer) => disposer());
        }),
      ];
    });
  }

  private async search(): Promise<Project[]> {
    const resource = this.owner === "my" ? this.api.projects : this.api.team.projects;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return resource.search(this.filters).then(({ data }) => data);
  }
}
