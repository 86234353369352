import { auth0UserScope } from "./Auth0User";

export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";

export const auth0LoginCallbackPath = "/auth/login/callback";
export const auth0LogoutCallbackPath = "/auth/logout/callback";

// install pro 360 backend api
export const apiAudienceId = "api";
export const apiAudience = process.env.REACT_APP_API_AUDIENCE || "";
export const apiScope = auth0UserScope; // we don't use additional scopes in our backend

export type Auth0AudienceId = typeof apiAudienceId;
