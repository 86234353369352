import { action, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { Photo } from "nvent-web/types/Photo";

import { RoomsStore } from "./Rooms";

export class PhotosStore {
  @observable newInstallationPhotosPromise?: IPromiseBasedObservable<Photo[]>;
  @observable deleteInstallationPhotoPromise?: IPromiseBasedObservable<Photo[]>;
  @observable newStickerPhotosPromise?: IPromiseBasedObservable<Photo[]>;
  @observable deleteStickerPhotoPromise?: IPromiseBasedObservable<Photo[]>;

  constructor(
    private rooms: RoomsStore,
    private api: Api
  ) {}

  @computed
  get areInstallationPhotosCreating(): boolean {
    return Boolean(this.newInstallationPhotosPromise && this.newInstallationPhotosPromise.state === PENDING);
  }

  @computed
  get areStickerPhotosCreating(): boolean {
    return Boolean(this.newStickerPhotosPromise && this.newStickerPhotosPromise.state === PENDING);
  }

  @action.bound
  async createInstallationPhotos(projectId: number, roomId: number, images: File[]) {
    this.newInstallationPhotosPromise = fromPromise(
      this.api.installationPhotos.create(projectId, roomId, images).then(({ data }) => data)
    );

    await this.newInstallationPhotosPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.newInstallationPhotosPromise;
  }

  @action.bound
  async deleteInstallationPhoto(projectId: number, roomId: number, imageId: number) {
    this.deleteInstallationPhotoPromise = fromPromise(
      this.api.installationPhotos.delete(projectId, roomId, imageId).then(({ data }) => data)
    );

    await this.deleteInstallationPhotoPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.deleteInstallationPhotoPromise;
  }

  @action.bound
  async createStickerPhotos(projectId: number, roomId: number, images: File[]) {
    this.newStickerPhotosPromise = fromPromise(
      this.api.stickerPhotos.create(projectId, roomId, images).then(({ data }) => data)
    );

    await this.newStickerPhotosPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.newStickerPhotosPromise;
  }

  @action.bound
  async deleteStickerPhoto(projectId: number, roomId: number, imageId: number) {
    this.deleteStickerPhotoPromise = fromPromise(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.api.stickerPhotos.delete(projectId, roomId, imageId).then(({ data }) => data)
    );

    await this.deleteStickerPhotoPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.deleteStickerPhotoPromise;
  }
}
