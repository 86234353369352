import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import style from "./ArchiveAction.module.scss";

export interface ArchiveActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

export const ArchiveAction: FunctionComponent<ArchiveActionProps> = ({
  className,
  disabled = false,
  ...buttonProps
}) => (
  <button {...buttonProps} type="button" className={classNames(style.archiveButton, className)} disabled={disabled}>
    <FormattedMessage id={disabled ? "actions.archived" : "actions.archive"} />
  </button>
);
