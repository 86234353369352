import classnames from "classnames";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import style from "./RequiredFieldLabel.module.scss";

export interface RequiredFieldLabelProps {
  className?: string;
}

export const RequiredFieldLabel: FunctionComponent<RequiredFieldLabelProps> = ({ className }) => (
  <p className={classnames(style.requiredFieldsLabel, className)}>
    * <FormattedMessage id="form.requiredFields" />
  </p>
);
