import { Form, Formik, FormikActions } from "formik";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import * as Yup from "yup";

import { TextField } from "nvent-web/components/form/TextField";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { buildMessage } from "nvent-web/i18n/yup";
import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { UserStore } from "nvent-web/stores/User";
import { AddProductParams } from "nvent-web/types/AddProductParams";
import { CommissioningFormValues } from "nvent-web/types/CommissioningFormValues";

import style from "./CommissioningForm.module.scss";

interface CommissioningFormProps {
  onClose: () => void;
  onSubmit: (values: CommissioningFormValues) => void;
}

interface CommissioningFormInnerProps
  extends CommissioningFormProps,
    RouteComponentProps<AddProductParams>,
    InjectedIntlProps {
  api: Api;
  notifications: NotificationsStore;
  projects: ProjectsStore;
  user: UserStore;
}

const emailSchema = Yup.string().email();

class CommissioningFormInner extends Component<CommissioningFormInnerProps> {
  schema = Yup.object({
    installerEmail: emailSchema.required(),
    clientEmail: emailSchema,
    additionalEmails: Yup.string().test({
      name: "emails-list",
      message: buildMessage("error.string.emailsList"),
      test: (value?: string): value is string =>
        typeof value === "undefined" || value.split(/\s*,\s*/g).every((part) => emailSchema.isValidSync(part)),
    }),
  });

  private get initialValues(): CommissioningFormValues {
    const {
      user,
      projects: { projectDetails },
    } = this.props;

    return {
      installerEmail: user.email,
      clientEmail: projectDetails ? projectDetails.clientEmail : "",
      additionalEmails: "",
    };
  }

  render() {
    const { projectDetails } = this.props.projects;
    const { intl } = this.props;
    const isFinished = projectDetails && projectDetails.finished;

    return (
      <Formik
        validationSchema={this.schema}
        isInitialValid={true}
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form noValidate={true}>
            <h3 className={style.title}>
              <FormattedMessage id="commissioning.title" />
            </h3>

            {!isFinished && (
              <p className={style.notice}>
                <FormattedMessage id="commissioning.notice" />
              </p>
            )}

            <TextField
              type="email"
              name="installerEmail"
              label={intl.formatMessage({ id: "form.commissioning.installerEmail" })}
              placeholder={intl.formatMessage({ id: "form.commissioning.installerEmail" })}
              className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
            />

            <TextField
              type="email"
              name="clientEmail"
              label={intl.formatMessage({ id: "form.commissioning.clientEmail" })}
              placeholder={intl.formatMessage({ id: "form.commissioning.clientEmail" })}
              className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
            />

            <TextField
              component="textarea"
              name="additionalEmails"
              label={intl.formatMessage({ id: "form.commissioning.additionalEmails" })}
              placeholder={intl.formatMessage({ id: "form.commissioning.additionalEmails.placeholder" })}
              className={{ wrapper: style.row, label: style.label, inputContainer: style.inputContainer }}
            />

            <div className={style.actions}>
              <SubmitCancelButtons
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                submitLabel={<FormattedMessage id="actions.send" />}
                onCancel={this.handleCancel}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  private handleCancel = () => {
    this.props.onClose();
  };

  private handleSubmit = async (values: CommissioningFormValues, actions: FormikActions<CommissioningFormValues>) => {
    const {
      onSubmit,
      projects: { projectDetails },
      api,
      notifications,
    } = this.props;

    try {
      if (projectDetails?.id) {
        await api.projects.requestReportOnEmail(projectDetails.id, values);
        onSubmit(values);
      }
    } catch (err) {
      logger.error(err);
      notifications.createError(<FormattedMessage id="error.reportSendingFailed" />);
    } finally {
      actions.setSubmitting(false);
    }
  };
}

const CommissioningForm = compose<CommissioningFormInnerProps, CommissioningFormProps>(
  injectIntl,
  inject("projects", "user", "api", "notifications"),
  withRouter,
  observer
)(CommissioningFormInner);

export default CommissioningForm;
