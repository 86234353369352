import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { ReactComponent as NewBuildingIcon } from "nvent-web/assets/svg/building-new.svg";
import { ReactComponent as OldBuildingIcon } from "nvent-web/assets/svg/building-old.svg";
import { ReactComponent as RenovatedBuildingIcon } from "nvent-web/assets/svg/building-renovated.svg";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { BuildingType } from "nvent-web/types/BuildingType";
import { ProjectInsulationItem } from "nvent-web/types/ProjectInsulationItem";

import StepHeading from "../StepHeading/StepHeading";

import ProjectInsulationCard from "./components/ProjectInsulationCard/ProjectInsulationCard";

interface EditProjectParams {
  projectId: string;
  roomId: string;
}

interface ProjectInsulationStepInnerProps extends RouteComponentProps<EditProjectParams> {
  projects: ProjectsStore;
}

export const PROJECT_INSULATION_ITEMS: ProjectInsulationItem[] = [
  {
    buildingType: "passive_building",
    icon: <NewBuildingIcon />,
    title: <FormattedMessage id="selectionGuide.newBuilding" />,
    description: <FormattedMessage id="selectionGuide.newBuilding.insulation" />,
  },
  {
    buildingType: "new_building",
    icon: <RenovatedBuildingIcon />,
    title: <FormattedMessage id="selectionGuide.renovatedBuilding" />,
    description: <FormattedMessage id="selectionGuide.renovatedBuilding.insulation" />,
  },
  {
    buildingType: "old_building",
    icon: <OldBuildingIcon />,
    title: <FormattedMessage id="selectionGuide.oldBuilding" />,
    description: <FormattedMessage id="selectionGuide.oldBuilding.insulation" />,
  },
];

class ProjectInsulationStepInner extends Component<ProjectInsulationStepInnerProps> {
  @computed
  get projectId(): number {
    return parseInt(this.props.match.params.projectId, 10);
  }

  @computed
  get roomId(): number {
    return parseInt(this.props.match.params.roomId, 10);
  }

  componentDidMount() {
    this.props.projects.getProject(this.projectId);
  }

  render() {
    const { projectDetails } = this.props.projects;
    return (
      <>
        <StepHeading step={1} />
        {PROJECT_INSULATION_ITEMS.map(({ buildingType, icon, title, description }) => (
          <ProjectInsulationCard
            buildingType={buildingType}
            key={buildingType}
            icon={icon}
            title={title}
            description={description}
            selected={Boolean(projectDetails && projectDetails.buildingType === buildingType)}
            onSelect={this.selectBuildingType}
          />
        ))}
      </>
    );
  }
  private selectBuildingType = async (buildingType: BuildingType) => {
    const { projectDetails, updateProject } = this.props.projects;
    if (projectDetails) {
      await updateProject(
        {
          project: {
            ...projectDetails,
            addressAttributes: projectDetails.address,
            buildingType,
          },
        },
        this.projectId
      );
      this.props.history.push(`/projects/${this.projectId}/rooms/${this.roomId}/selection-guide/room`);
    }
  };
}

const ProjectInsulationStep = compose<ProjectInsulationStepInnerProps, Record<string, unknown>>(
  inject("projects"),
  withRouter,
  observer
)(ProjectInsulationStepInner);

export default ProjectInsulationStep;
