import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { LoadingSpinner } from "../LoadingSpinner";

import style from "./common.module.scss";

export type GetPDFActionProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

export const GetPDFAction: FunctionComponent<GetPDFActionProps> = ({
  className,
  disabled = false,
  loading = false,
  ...buttonProps
}) => (
  <button
    {...buttonProps}
    type="button"
    className={classNames(className, disabled && style.buttonDisabled)}
    disabled={disabled || loading}
  >
    {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.getPDF" />}
  </button>
);
