import { FormattedMessage } from "react-intl";

import style from "./CommissionedLabel.module.scss";

export const CommissionedLabel = () => {
  return (
    <div className={style.label}>
      <FormattedMessage id="label.commissioned" />
    </div>
  );
};
