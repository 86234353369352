import { AxiosInstance } from "axios";

export interface Client {
  readonly http: AxiosInstance;

  download(config: { url: string; params?: { [key: string]: any } }): Promise<void>;
}

export default class Resource {
  protected get http() {
    return this.client.http;
  }

  constructor(protected client: Client) {}
}
