import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { LoadingSpinner } from "../LoadingSpinner";

import style from "./common.module.scss";

export type GetBOMActionProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  loading?: boolean;
};

export const GetBOMAction: FunctionComponent<GetBOMActionProps> = ({
  className,
  disabled,
  loading,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      type="button"
      className={classNames(className, style.buttonDisabled)}
      disabled={disabled || loading}
    >
      {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.getBOM" />}
    </button>
  );
};
