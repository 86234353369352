import { inject, observer } from "mobx-react";
import { Component, ComponentType, createRef } from "react";
import { compose } from "recompose";

import { ReactComponent as HamburgerIcon } from "nvent-web/assets/svg/hamburger.svg";
import AppStore from "nvent-web/stores/App";

import style from "./Header.module.scss";

interface HeaderInnerProps {
  app: AppStore;
}

class HeaderInner extends Component<HeaderInnerProps> {
  static getTitleContainer() {
    return HeaderInner.titleContainer;
  }
  private static titleContainer?: HTMLElement;
  private titleContainerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    HeaderInner.titleContainer = this.titleContainerRef.current || undefined;
  }

  componentWillUnmount() {
    HeaderInner.titleContainer = undefined;
  }

  render() {
    const { app } = this.props;

    return (
      <>
        <div className={style.placeholder} />
        <header className={style.root}>
          <div className={style.container}>
            <div ref={this.titleContainerRef} className={style.titleContainer} />
            <div className={style.hamburger} onClick={app.showSideMenu} role="button" tabIndex={0}>
              <HamburgerIcon />
            </div>
          </div>
        </header>
      </>
    );
  }
}

type HeaderType = ComponentType<Record<string, unknown>> & Pick<typeof HeaderInner, "getTitleContainer">;
const Header = compose<HeaderInnerProps, Record<string, unknown>>(inject("app"), observer)(HeaderInner) as HeaderType;
Header.displayName = "Header";

export default Header;
