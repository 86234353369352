import { FunctionComponent } from "react";

import { AbstractField, AbstractFieldProps } from "./AbstractField";

export interface TextFieldProps extends Omit<AbstractFieldProps, "type" | "render" | "children"> {
  type?: "text" | "email" | "tel";
}

export const TextField: FunctionComponent<TextFieldProps> = ({ type = "text", ...fieldProps }) => (
  <AbstractField type={type} {...fieldProps} />
);
