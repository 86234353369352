import { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { compose } from "recompose";

import Login from "./Login";

export type AuthInnerProps = RouteComponentProps;

export class AuthInner extends Component<AuthInnerProps> {
  render() {
    const { path, url } = this.props.match;

    return (
      <Switch>
        <Route path={`${path}/login`} exact={false} component={Login} />
        <Redirect to={`${url}/login`} />
      </Switch>
    );
  }
}

export const Auth = compose<AuthInnerProps, Record<string, unknown>>(withRouter)(AuthInner);
Auth.displayName = "Auth";

export default Auth;
