import Bugsnag, { Event } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React, { ComponentType, FC } from "react";

export const client = process.env.REACT_APP_BUGSNAG_API_KEY
  ? Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      appVersion: process.env.REACT_APP_VERSION,
      releaseStage: process.env.REACT_APP_ENVIRONMENT,
      redactedKeys: [/token/i, /password/i, /passphrase/i],
      collectUserIp: false,
      plugins: [new BugsnagPluginReact(React)],
      onError: (event: Event) => {
        // Remove the call to the logger from the stack trace for better error grouping
        event.errors[0].stacktrace = event.errors[0].stacktrace.slice(1);
      },
    })
  : undefined;

export default client;

const NoBoundary: FC = ({ children }) => <>{children}</>;

export const ErrorBoundary: ComponentType = client
  ? (Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? NoBoundary)
  : NoBoundary;
