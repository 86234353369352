import { useState } from "react";
import { FormattedMessage } from "react-intl";

import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";

type UseGetBOMProps = {
  id: number;
  getBOMCallback: (id: number) => Promise<{ data: { url: string } }>;
  notifications: NotificationsStore;
};
export function useGetBOM({ id, notifications, getBOMCallback }: UseGetBOMProps) {
  const [isGetBOMSubmitting, setIsGetBOMSubmitting] = useState(false);

  const getBOM = async () => {
    try {
      setIsGetBOMSubmitting(true);
      const {
        data: { url },
      } = await getBOMCallback(id);
      window.open(url, "_blank");
    } catch (err) {
      logger.error(err);
      notifications.createError(<FormattedMessage id="error.getBOMFailed" />);
    } finally {
      setIsGetBOMSubmitting(false);
    }
  };

  return { getBOM, isGetBOMSubmitting };
}
