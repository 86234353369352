import saveAs from "file-saver";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { CommissionReportsGenerationStatus } from "nvent-web/types/CommissionReportsGenerationStatus";
import { withResolvers } from "nvent-web/utils/withResolvers";

import { usePolling } from "./usePolling";

type ReportGenerationBase = {
  status: string;
  url: string;
};

type UseGenerateReportCommonTypes = {
  id: number;
  notificationsStore: NotificationsStore;
  fetchReportStatusCallback: (id: number) => Promise<{ data: ReportGenerationBase }>;
  generateReportCallback: (id: number) => Promise<{ data: void }>;
  stopPollingStatuses: string[];
  pollingInterval: number;
};

type UseGenerateReportProps =
  | (UseGenerateReportCommonTypes & {
      isBlobResponse: true;
      downloadReportCallback: (url: string) => Promise<{ data: BlobPart }>;
    })
  | (UseGenerateReportCommonTypes & {
      isBlobResponse: false;
    });

export function useGenerateReport(props: UseGenerateReportProps) {
  const {
    id,
    notificationsStore,
    fetchReportStatusCallback,
    generateReportCallback,
    isBlobResponse,
    stopPollingStatuses,
    pollingInterval,
  } = props;

  const [isReportGenerationActive, setIsReportGenerationActive] = useState(false);
  const [isGenerateReportSubmitting, setIsGenerateReportSubmitting] = useState(false);
  const {
    current: { promise, resolve, reject },
  } = useRef(withResolvers());

  const handleError = (err: unknown, translationId: string) => {
    logger.error(err);
    notificationsStore.createError(<FormattedMessage id={translationId} />);
  };

  const fetchGenerationStatus = async () => {
    try {
      const { data: reportGeneration } = await fetchReportStatusCallback(id);

      if (stopPollingStatuses.includes(reportGeneration.status)) {
        setIsReportGenerationActive(false);
        await getReport(reportGeneration);
        setIsGenerateReportSubmitting(false);
      }
    } catch (err) {
      handleError(err, "error.reportDownloadFailed");
      setIsGenerateReportSubmitting(false);
      setIsReportGenerationActive(false);
    }
  };

  usePolling(fetchGenerationStatus, {
    fetchOnMount: false,
    isActive: isReportGenerationActive,
    interval: pollingInterval,
  });

  const getReport = async (reportGeneration: ReportGenerationBase) => {
    if (reportGeneration) {
      if (reportGeneration.status === CommissionReportsGenerationStatus.generated) {
        if (isBlobResponse) {
          try {
            const { data } = await props.downloadReportCallback(reportGeneration.url);
            const dataBlob = new Blob([data], { type: "application/zip" });
            saveAs(dataBlob, "commission_report.zip");
            // we need to wait until polling status is as expected (stopPollingStatuses)
            resolve("Resolve");
          } catch (err) {
            handleError(err, "error.reportDownloadFailed");
            reject("Reject");
          }
        } else {
          window.open(reportGeneration.url, "_blank");
        }
      }
    }
  };

  const generateReport = async () => {
    try {
      setIsGenerateReportSubmitting(true);
      await generateReportCallback(id);
      setIsReportGenerationActive(true);
      await promise;
    } catch (err) {
      handleError(err, "error.reportDownloadFailed");
      setIsGenerateReportSubmitting(false);
    }
  };

  return { generateReport, isGenerateReportSubmitting };
}
