/* eslint-disable @typescript-eslint/no-unsafe-return */
import { KnownLocale } from "nvent-web/i18n";

export const getLogoByCountryCode = (locale: KnownLocale): string => {
  try {
    return require(`nvent-web/assets/png/certified-${locale}.png`);
  } catch (error) {
    return require("nvent-web/assets/png/certified.png");
  }
};
