import classNames from "classnames";
import { Children, cloneElement, Component, ReactElement } from "react";

import Progress from "nvent-web/components/Progress";

import ProgressFull from "../Progress/FullProgress";

import style from "./SimpleCard.module.scss";

interface SimpleCardProps {
  title: React.ReactNode;
  progress?: number;
  actions: React.ReactNode[];
  isFinished?: boolean;
}

class SimpleCard extends Component<SimpleCardProps> {
  render() {
    const { title, progress, actions, isFinished } = this.props;
    const actionButtons = Children.map(actions, (item) => {
      const props = { className: style.button };
      return cloneElement(item as ReactElement, props);
    });

    return (
      <div className={classNames(style.wrapper)}>
        <div className={style.header}>
          <span>{title}</span>
          {!isFinished && progress !== undefined && (
            <div className={style.progressFullWrapper}>
              <ProgressFull progress={progress} className={{ text: style.progressFullText }} />
            </div>
          )}
          <div className={style.actionsWrapper}>{actionButtons}</div>
        </div>

        {!isFinished && progress !== undefined && (
          <div className={style.progressWrapper}>
            <Progress progress={progress} />
          </div>
        )}
      </div>
    );
  }
}

export default SimpleCard;
