import { History } from "history";
import { action, autorun, computed, observable, onBecomeObserved, onBecomeUnobserved, reaction } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";
import { Project } from "nvent-web/types/Project";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";

import { ProjectSearch, ProjectSearchInitialFilters } from "./ProjectsSearch";
import { UserStore } from "./User";

export class ProjectLocalState {
  @observable
  isExpanded = false;

  @observable
  areDetailsExpanded = true;

  constructor(projectId: number) {
    const key = `projects.${projectId}.state`;
    const stored = localStorage.getItem(key);

    if (stored) {
      Object.assign(this, JSON.parse(stored));
    }

    autorun(() => {
      localStorage.setItem(
        key,
        JSON.stringify({
          isExpanded: this.isExpanded,
          areDetailsExpanded: this.areDetailsExpanded,
        })
      );
    });
  }

  @action.bound
  toggleExpanded(isExpanded = !this.isExpanded) {
    this.isExpanded = isExpanded;
  }

  @action.bound
  toggleDetailsExpanded(areExpanded = !this.areDetailsExpanded) {
    this.areDetailsExpanded = areExpanded;
  }
}

export class ProjectsStore {
  @observable projectsPromise = createInitialFromPromise<Project[]>([]);
  @observable newProjectPromise?: IPromiseBasedObservable<Project>;
  @observable projectDetailsPromise?: IPromiseBasedObservable<DetailedProject>;

  private searches: ProjectSearch[] = [];
  private localStateMap = observable.map<number, ProjectLocalState>();

  constructor(
    user: UserStore,
    private api: Api,
    private history: History
  ) {
    reaction(
      () => user.isReady && user.id,
      (id) => {
        if (id) {
          this.loadProjects();
        }
      },
      { fireImmediately: true }
    );
  }

  @computed
  get areProjectsLoading(): boolean {
    return this.projectsPromise.state === PENDING;
  }

  @computed
  get projects(): Project[] {
    return (
      this.projectsPromise.case({
        fulfilled: (projects) => projects,
      }) || []
    );
  }

  @action.bound
  loadProjects() {
    this.projectsPromise = fromPromise(this.api.team.projects.getAll().then(({ data }) => data));
    this.searches.forEach((search) => search.loadResults());
  }

  @computed
  get isProjectLoading(): boolean {
    return Boolean(this.newProjectPromise && this.newProjectPromise.state === PENDING);
  }

  @action.bound
  async createProject(values: NewProjectFormValues) {
    this.newProjectPromise = fromPromise(this.api.projects.create(values).then(({ data }) => data));

    const newProject = await this.newProjectPromise;

    this.projectsPromise = fromPromise(Promise.resolve([newProject, ...this.projects]));
    this.history.push(`/projects/${newProject.id}`);
  }

  @action.bound
  getLocalState(id: number): ProjectLocalState {
    let state = this.localStateMap.get(id);

    if (!state) {
      state = new ProjectLocalState(id);
      this.localStateMap.set(id, state);
    }

    return state;
  }

  @action.bound
  async updateProject(values: NewProjectFormValues, id: number) {
    this.newProjectPromise = fromPromise(this.api.projects.updateOne(values, id).then(({ data }) => data));

    await this.newProjectPromise;
    await this.loadProjects();
  }

  @computed
  get areProjectDetailsLoading(): boolean {
    return Boolean(this.projectDetailsPromise && this.projectDetailsPromise.state === PENDING);
  }

  @computed
  get projectDetails(): DetailedProject | null {
    return this.projectDetailsPromise
      ? this.projectDetailsPromise.case({
          fulfilled: (project) => project,
        })
      : null;
  }

  @action.bound
  getProject(id: number) {
    this.projectDetailsPromise = fromPromise(this.api.projects.getOne(id).then(({ data }) => data));
  }

  @action.bound
  async removeProject(id: number) {
    await this.api.projects.remove(id);
    await this.loadProjects();
  }

  @action.bound
  async copyProject(id: number) {
    await this.api.projects.copy(id);
    await this.loadProjects();
  }

  @action.bound
  async archiveProject(id: number) {
    await this.api.projects.archive(id);
    await this.loadProjects();
  }

  downloadReport(id: number) {
    return this.api.projects.downloadCommissionReportV2(id);
  }

  downloadBillOfMaterials(id: number) {
    return this.api.projects.downloadBillOfMaterialsV2(id);
  }

  buildSearch(initialFilters: ProjectSearchInitialFilters): ProjectSearch {
    const search = new ProjectSearch(this.api, initialFilters);

    onBecomeObserved(search, "results", () => {
      this.searches.push(search);
    });
    onBecomeUnobserved(search, "results", () => {
      this.searches.splice(this.searches.indexOf(search), 1);
    });

    return search;
  }
}
