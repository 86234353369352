import { observer } from "mobx-react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { AppLogo } from "nvent-web/components/AppLogo/AppLogo";
import { PrimaryRedButton } from "nvent-web/components/Button";
import { withAppAuth0 } from "nvent-web/services/auth0/AppAuth0Context";
import { AppAuth0 } from "nvent-web/services/auth0/useCreateAppAuth0";

import style from "./LoginPage.module.scss";
import SelectionGuideSection from "./SelectionGuideSection";

interface LoginPageInnerProps {
  auth0: AppAuth0;
}

const LoginPageInner: FunctionComponent<LoginPageInnerProps> = ({ auth0 }) => {
  const logIn = () => {
    auth0.loginWithRedirect();
  };

  return (
    <div className={style.root}>
      <AppLogo />
      <SelectionGuideSection />

      <div className={style.logIn}>
        <PrimaryRedButton className={style.logInButton} size="large" fixedWidth onClick={logIn}>
          <FormattedMessage id="login.callToAction" />
        </PrimaryRedButton>
      </div>
    </div>
  );
};

export const LoginPage = compose<LoginPageInnerProps, Record<string, unknown>>(withAppAuth0, observer)(LoginPageInner);
export default LoginPage;
