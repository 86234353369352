import { Component } from "react";
import { FormattedMessage } from "react-intl";

import DeleteIcon from "nvent-web/components/DeleteIcon/DeleteIcon";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { Photo } from "nvent-web/types/Photo";

import style from "./PhotoContainer.module.scss";

interface PhotoContainerProps {
  onRemove?: (imageId: number) => void;
  photo: Photo;
}

interface PhotoContainerState {
  isRemoveConfirmationOpen: boolean;
}

class PhotoContainer extends Component<PhotoContainerProps, PhotoContainerState> {
  state = {
    isRemoveConfirmationOpen: false,
  };

  render() {
    const { photo } = this.props;

    return (
      <>
        <ConfirmationModal
          isOpen={this.state.isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"rooms.removeImage.title"} />}
          description={<FormattedMessage id={"rooms.removeImage.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.handleRemove}
        />
        <div className={style.imageWrapper}>
          <div className={style.imageContainer}>
            <div className={style.image} style={{ backgroundImage: `url(${photo.urls.thumbnail})` }}>
              <DeleteIcon onDelete={this.openRemoveConfirmation} />
            </div>
          </div>
        </div>
      </>
    );
  }

  private openRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: !this.state.isRemoveConfirmationOpen,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({ isRemoveConfirmationOpen: !this.state.isRemoveConfirmationOpen });
  };

  private handleRemove = () => {
    const { onRemove, photo } = this.props;

    if (onRemove) {
      onRemove(photo.id);
    }

    this.closeRemoveConfirmation();
  };
}

export default PhotoContainer;
