import axios from "axios";
import { action, autorun, computed, observable, reaction } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import { isKnownLocale, KnownLocale } from "nvent-web/i18n";
import Api from "nvent-web/services/Api";
import { AppAuth0User } from "nvent-web/services/auth0/AppAuth0User";
import { AppAuth0 } from "nvent-web/services/auth0/useCreateAppAuth0";
import bugsnag from "nvent-web/services/bugsnag";
import { ProfileUpdate } from "nvent-web/services/resources/ProfileResource";
import { Address } from "nvent-web/types/Address";
import { Photo } from "nvent-web/types/Photo";
import { Consents, Profile } from "nvent-web/types/Profile";
import { Role } from "nvent-web/types/Role";
import { never } from "nvent-web/utils/promise";

const emptyCompanyAddress: Address = {
  streetAddress: "",
  postalCode: "",
  locality: "",
  countryCode: "SE",
  region: "",
};

const initialConsents: Consents = {
  marketing: false,
  shareableProjects: true,
};

const pausedProfileUpdateKey = "installPro360.profileUpdate";

export class UserStore implements Profile {
  @observable profilePromise = fromPromise(never());

  @observable auth0: AppAuth0 | null = null;
  @observable isAuthenticated = false;
  @observable isAuthenticating = true;

  @computed
  get isLoading() {
    return this.isAuthenticating || this.isProfileLoading;
  }

  @computed
  get isProfileLoading() {
    return this.profilePromise.state === "pending";
  }

  @computed
  get isReady() {
    return !this.isLoading && this.isAuthenticated;
  }

  @computed
  get isLogoCreating(): boolean {
    return Boolean(this.newLogoPromise && this.newLogoPromise.state === PENDING);
  }

  @computed
  get isAvatarCreating(): boolean {
    return Boolean(this.newAvatarPromise && this.newAvatarPromise.state === PENDING);
  }

  @observable updatingPromise?: IPromiseBasedObservable<void>;

  @observable auth0User: AppAuth0User | null = null;

  @computed
  get isProfileComplete() {
    return Boolean(
      this.firstName &&
        this.lastName &&
        this.phoneNumber &&
        this.companyName &&
        this.companyAddress.streetAddress &&
        this.companyAddress.locality &&
        this.companyAddress.postalCode &&
        this.companyAddress.countryCode
    );
  }

  @computed
  get email() {
    return this.auth0User?.email || "";
  }

  @computed
  get auth0Id() {
    return this.auth0User?.auth0Id || "";
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @observable id = "";
  @observable firstName = "";
  @observable lastName = "";
  @observable phoneNumber = "";
  @observable companyName = "";
  @observable.struct companyAddress: Address = emptyCompanyAddress;

  @observable certifiedProNumber = "";
  @observable locale: KnownLocale;
  @observable.struct consents: Consents = { ...initialConsents };
  @observable role = Role.installer;

  @computed
  get isAdmin() {
    return this.role === Role.admin || this.role === Role.superAdmin;
  }

  @computed
  get isSuperAdmin() {
    return this.role === Role.superAdmin;
  }

  @observable logo: Photo | null = null;
  @observable newLogoPromise?: IPromiseBasedObservable<Photo>;
  @observable deleteLogoPromise?: IPromiseBasedObservable<Photo>;

  @observable avatar: Photo | null = null;
  @observable newAvatarPromise?: IPromiseBasedObservable<Photo>;
  @observable deleteAvatarPromise?: IPromiseBasedObservable<Photo>;

  constructor(private api: Api) {
    const storedLocale = localStorage.getItem("app.locale");
    this.locale = storedLocale && isKnownLocale(storedLocale) ? storedLocale : "en";

    reaction(
      () => this.auth0Id,
      () => {
        this.profilePromise = fromPromise(this.loadProfile());
      },
      { fireImmediately: true }
    );

    autorun(() => {
      if (bugsnag) {
        bugsnag.setUser(this.auth0Id ?? undefined);
      }
    });

    autorun(() => localStorage.setItem("app.locale", this.locale));
  }

  @computed
  get isProfileUpdating(): boolean {
    return Boolean(this.updatingPromise && this.updatingPromise.state === PENDING);
  }

  updateProfile(changes: ProfileUpdate) {
    this.updatingPromise = fromPromise(update.call(this));
    return this.updatingPromise;

    async function update(this: UserStore) {
      if (!this.auth0) {
        throw new Error("Missing UserStore.auth0");
      }

      const idToken = await this.auth0.getIdToken();

      if (!idToken) {
        throw new Error("Missing idToken");
      }

      this.setProfile((await this.api.profile.update(changes, idToken)).data);
    }
  }

  get shouldResumeProfileUpdate() {
    return sessionStorage.getItem(pausedProfileUpdateKey) !== null;
  }

  resumeProfileUpdate() {
    const changes = this.popPausedProfileUpdate() || {};

    return this.updateProfile(changes);
  }

  @action.bound
  async setLocale(locale: KnownLocale) {
    this.locale = locale;
    if (this.id) {
      await this.api.profile.setLocale(this.locale);
    }
  }

  @action.bound
  async createLogo(logo: File) {
    this.newLogoPromise = fromPromise(this.api.profile.createLogo(logo).then(({ data }) => data));

    await this.newLogoPromise;
    await this.loadProfile();

    return this.newLogoPromise;
  }

  @action.bound
  async deleteLogo(logoId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.deleteLogoPromise = fromPromise(this.api.profile.deleteLogo(logoId).then(({ data }) => data));

    await this.deleteLogoPromise;
    await this.loadProfile();

    return this.deleteLogoPromise;
  }

  @action.bound
  async createAvatar(avatar: File) {
    this.newAvatarPromise = fromPromise(this.api.profile.createAvatar(avatar).then(({ data }) => data));

    await this.newAvatarPromise;
    await this.loadProfile();

    return this.newAvatarPromise;
  }

  @action.bound
  async deleteAvatar(avatarId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.deleteAvatarPromise = fromPromise(this.api.profile.deleteAvatar(avatarId).then(({ data }) => data));

    await this.deleteAvatarPromise;
    await this.loadProfile();

    return this.deleteAvatarPromise;
  }

  private async loadProfile() {
    return this.setProfile(this.isAuthenticated ? await this.ensureProfile() : undefined);
  }

  private async ensureProfile(): Promise<Profile | undefined> {
    try {
      return (await this.api.profile.get()).data;
    } catch (err) {
      const isAccessDeniedError = axios.isAxiosError(err) && err.response?.status === 403;

      if (!isAccessDeniedError || !this.auth0) {
        throw err;
      }

      const idToken = await this.auth0.getIdToken();

      if (!idToken) {
        throw new Error("Missing idToken");
      }

      return (await this.api.profile.update({ locale: this.locale }, idToken)).data;
    }
  }

  @action
  private setProfile(profile: Profile | undefined) {
    this.id = profile?.id || "";

    this.firstName = profile?.firstName || "";
    this.lastName = profile?.lastName || "";
    this.phoneNumber = profile?.phoneNumber || "";
    this.companyName = profile?.companyName || "";
    this.companyAddress = {
      countryCode: profile?.companyAddress.countryCode || emptyCompanyAddress.countryCode,
      locality: profile?.companyAddress.locality || "",
      postalCode: profile?.companyAddress.postalCode || "",
      region: profile?.companyAddress.region || "",
      streetAddress: profile?.companyAddress.streetAddress || "",
    };

    this.certifiedProNumber = profile?.certifiedProNumber || "";
    this.role = profile?.role || Role.installer;
    this.locale = profile?.locale || this.locale;
    this.consents = {
      marketing: profile?.consents.marketing ?? initialConsents.marketing,
      shareableProjects: profile?.consents.shareableProjects ?? initialConsents.shareableProjects,
    };

    this.logo = profile?.logo || null;
    this.avatar = profile?.avatar || null;
  }

  private getPausedProfileUpdate(): ProfileUpdate | null {
    const changesJson = sessionStorage.getItem(pausedProfileUpdateKey);

    if (!changesJson) {
      return null;
    }

    try {
      return JSON.parse(changesJson) as ProfileUpdate;
    } catch {
      return null;
    }
  }

  private clearPausedProfileUpdate() {
    sessionStorage.removeItem(pausedProfileUpdateKey);
  }

  private popPausedProfileUpdate(): ProfileUpdate | null {
    const changes = this.getPausedProfileUpdate();
    this.clearPausedProfileUpdate();
    return changes;
  }
}
