import { Field, FieldProps, Form, Formik } from "formik";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";
import * as Yup from "yup";

import { AreaTypeCheckboxField } from "nvent-web/components/AreaTypeCheckboxField/AreaTypeCheckboxField";
import { Checkbox } from "nvent-web/components/Checkbox/Checkbox";
import FloorConstructionField from "nvent-web/components/FloorConstructionField/FloorConstructionField";
import FloorFinishField from "nvent-web/components/FloorFinishField/FloorFinishField";
import { RequiredFieldLabel } from "nvent-web/components/RequiredFieldLabel";
import RoomAreas from "nvent-web/components/RoomAreas/RoomAreas";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { buildMessage } from "nvent-web/i18n/yup";
import AppStore from "nvent-web/stores/App";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { UserStore } from "nvent-web/stores/User";
import { BuildingType } from "nvent-web/types/BuildingType";
import { RawSelectionGuideFormValues, SelectionGuideFormValues } from "nvent-web/types/SelectionGuideFormValues";

import style from "./RoomDetailsStepForm.module.scss";

export interface RoomDetailsStepFormProps {
  onCancel: () => void;
  initialValues: SelectionGuideFormValues;
  handleSubmit: (formValues: SelectionGuideFormValues) => void;
  isSubmitting: boolean;
  buildingType: BuildingType | null;
  countryCode?: string;
  cancelButtonText?: ReactNode;
}
interface RoomDetailsStepFormInnerProps extends RoomDetailsStepFormProps, InjectedIntlProps {
  app: AppStore;
  rooms: RoomsStore;
  user: UserStore;
}

const quickHeatingFloorFinishes = new Set(["tile", "natural_stone"]);
const quickHeatingCountriesBlacklist = new Set(["BY", "KZ", "RU", "UA"]);

const areaSchema = Yup.number()
  .required()
  .transform((_, value: string | undefined) =>
    value === undefined || value === "" ? null : Number(value.replace(",", "."))
  )
  .typeError(buildMessage("error.number.positive"))
  .positive();

export const roomDetailsValidationSchema = Yup.object<SelectionGuideFormValues>({
  area: areaSchema,
  installableArea: areaSchema.when("area", (area: number, schema: Yup.NumberSchema) =>
    schema.max(area, buildMessage("room.error.installableArea.max"))
  ),
  floorConstruction: Yup.string().nullable().required(),
  floorFinish: Yup.string().nullable().required(),
  quickHeating: Yup.boolean(),
  areaType: Yup.string().nullable().required(),
}).required();
class RoomDetailsStepFormInner extends Component<RoomDetailsStepFormInnerProps> {
  @observable
  private floorFinish: string = this.props.initialValues.floorFinish || "";

  @computed
  private get quickHeatingAvailable() {
    const countryCode = this.props.countryCode || this.props.user.companyAddress.countryCode;

    return (
      !quickHeatingCountriesBlacklist.has(countryCode) &&
      this.props.buildingType === "new_building" &&
      quickHeatingFloorFinishes.has(this.floorFinish)
    );
  }

  render() {
    const { onCancel, initialValues, isSubmitting, cancelButtonText } = this.props;

    const rawInitialValues: RawSelectionGuideFormValues = {
      area: initialValues.area ? initialValues.area.toString() : "",
      installableArea: initialValues.installableArea ? initialValues.installableArea.toString() : "",
      floorConstruction: initialValues.floorConstruction || "",
      floorFinish: initialValues.floorFinish || "",
      quickHeating: initialValues.quickHeating || false,
      areaType: initialValues.areaType,
    };

    return (
      <>
        <Formik
          validationSchema={roomDetailsValidationSchema}
          initialValues={rawInitialValues}
          onSubmit={this.handleSubmit}
        >
          {({ initialValues: { floorFinish } }: { initialValues: RawSelectionGuideFormValues }) => (
            <Form noValidate={true} className={style.wrapper}>
              <div className={style.column}>
                <RoomAreas required />
              </div>

              <div className={style.column}>
                <FloorConstructionField required />

                <FloorFinishField required onChange={this.handleFloorFinishChange} initialValue={floorFinish} />
              </div>

              <div className={style.column}>
                <AreaTypeCheckboxField />
              </div>

              {this.quickHeatingAvailable && (
                <div className={style.column}>
                  <Field
                    name="quickHeating"
                    render={(fieldProps: FieldProps) => (
                      <Checkbox
                        name={fieldProps.field.name}
                        value={fieldProps.field.value}
                        type="checkbox"
                        className={style.checkbox}
                        onChange={(value) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, value);
                        }}
                        label={<FormattedMessage id="selectionGuide.quickHeating" />}
                      />
                    )}
                  />
                </div>
              )}
              <div className={style.columnFullWidth}>
                <RequiredFieldLabel className={style.requiredFieldLabel} />
              </div>
              <div className={style.buttons}>
                <div className={style.buttonsColumn}>
                  <div className={style.actions}>
                    <SubmitCancelButtons
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onCancel={onCancel}
                      submitLabel={<FormattedMessage id="actions.next" />}
                      cancelLabel={cancelButtonText}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  private handleFloorFinishChange = (floorFinish: string) => {
    this.floorFinish = floorFinish;
  };

  private handleSubmit = (rawValues: RawSelectionGuideFormValues) => {
    const value = roomDetailsValidationSchema.cast({
      ...rawValues,
      quickHeating: this.quickHeatingAvailable ? rawValues.quickHeating : undefined,
    });

    this.props.handleSubmit(value);
  };
}

const RoomDetailsStepForm = compose<RoomDetailsStepFormInnerProps, RoomDetailsStepFormProps>(
  injectIntl,
  inject("app", "rooms", "user"),
  observer
)(RoomDetailsStepFormInner);

export default RoomDetailsStepForm;
