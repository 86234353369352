import fileSaver from "file-saver";
import { History } from "history";
import pickBy from "lodash-es/pickBy";
import sortBy from "lodash-es/sortBy";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import qs from "query-string";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as yup from "yup";

import { SecondaryButton } from "nvent-web/components/Button";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { BOMProductSku, GetIndependentRecommendationsPayload } from "nvent-web/services/resources/ProductResource";
import AppStore from "nvent-web/stores/App";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProductsStore } from "nvent-web/stores/Products";
import { UserStore } from "nvent-web/stores/User";
import { BuildingType } from "nvent-web/types/BuildingType";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { CategoryRecommendation, CategoryRecommendationVariant } from "nvent-web/types/ProductRecommendation";
import {
  IndependentSelectionGuideRoomDetails,
  selectionGuideRoomDetailsSchema,
  setAwaitingProject,
} from "nvent-web/utils/awaitingProject";
import contactDataForCountries from "nvent-web/utils/contactDataForCountries";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";
import { recommendationToProductSpecs } from "nvent-web/utils/recommendationToProductSpecs";

import StepHeading from "../SelectionGuide/components/StepHeading/StepHeading";

import { RecommendationCard } from "./RecommendationCard";
import style from "./RecommendationsStep.module.scss";

interface RecommendationsStepProps {
  createProjectDisabled?: boolean;
}

interface RecommendationsStepInnerProps extends RecommendationsStepProps {
  history: History;
  api: Api;
  products: ProductsStore;
  notifications: NotificationsStore;
  user: UserStore;
  app: AppStore;
}

interface IndependentSelectionGuideValues extends IndependentSelectionGuideRoomDetails {
  countryCode: string;
  buildingType: BuildingType;
  quickHeating: boolean;
  projectName?: string;
}

const selectionGuideValuesSchema = yup
  .object({
    countryCode: yup.string(),
    buildingType: yup.string().oneOf(["new_building", "passive_building", "old_building"]).required(),
    projectName: yup.string(),
    quickHeating: yup.boolean().notRequired(),
  })
  .concat(selectionGuideRoomDetailsSchema) as yup.ObjectSchema<IndependentSelectionGuideValues>;

const sortThermostats = (thermostatOptions: DropdownOption[]) => {
  const thermostatsNamesOrder = ["R-SENZ-WIFI", "R-GREEN-LEAF", "R-NRG-DM", "R-TE"];

  return sortBy(thermostatOptions, (thermostat) => {
    const titleIndex = thermostatsNamesOrder.indexOf(thermostat.title);
    return titleIndex >= 0 ? titleIndex : thermostatsNamesOrder.length;
  });
};

class RecommendationsStepInner extends Component<RecommendationsStepInnerProps> {
  @observable
  private recommendationsPromise = createInitialFromPromise<CategoryRecommendation[]>([]);

  @observable
  private roomDetails: IndependentSelectionGuideValues | null = null;

  @computed
  get recommendations(): CategoryRecommendation[] {
    return this.recommendationsPromise.case({
      pending: () => [],
      fulfilled: (value) => value,
      rejected: () => [],
    });
  }

  @computed
  get countryCode() {
    const { user } = this.props;

    if (user.isReady) {
      return user.companyAddress.countryCode;
    }

    if (this.roomDetails && this.roomDetails.countryCode) {
      return this.roomDetails.countryCode;
    }

    return "";
  }

  @computed
  get customerSupportPhoneNumber() {
    if (!this.roomDetails) {
      return "";
    }

    const matchedContactData = contactDataForCountries.find(
      ({ countryCode }) => countryCode === this.countryCode.toUpperCase()
    );

    return matchedContactData ? matchedContactData.phoneNumber : "";
  }

  @computed
  get thermostatSkuOptions() {
    const thermostatSkuOptions = this.props.products.availableThermostatSkus.map(({ id, description }) => ({
      key: id.toString(),
      title: description,
    }));

    return sortThermostats(thermostatSkuOptions);
  }

  componentDidMount() {
    this.roomDetails = this.parseSelectionGuideValuesFromUrl();

    const { products } = this.props;

    if (!this.roomDetails) {
      this.props.history.replace("/selection-guide/insulation");
      return;
    }

    this.recommendationsPromise = fromPromise(
      this.fetchRecommendations({
        ...this.roomDetails,
        countryCode: this.countryCode,
      })
    );

    if (this.roomDetails.countryCode) {
      products.loadProductsCatalogForCountry(this.roomDetails.countryCode);
    } else {
      products.loadProductsCatalog();
    }
  }

  render() {
    return (
      <>
        <StepHeading step={3} />
        {this.renderContent()}
      </>
    );
  }

  private renderContent = () => {
    if (this.recommendationsPromise.state === "pending") {
      return <LoadingSection />;
    }

    if (this.recommendations.length === 0) {
      return (
        <>
          <div className={style.noProducts}>
            <FormattedMessage id="selectionGuide.noProducts" />
            <br />
            {this.customerSupportPhoneNumber && (
              <>
                <FormattedMessage id="selectionguide.noProducts.customerService" />
                <a className={style.phoneNumber} href={`tel:${this.customerSupportPhoneNumber}`}>
                  {this.customerSupportPhoneNumber}
                </a>
              </>
            )}
          </div>
          <div className={style.noProductsPhoneNumber} />
          <SecondaryButton onClick={this.goBack} className={style.cancelButton}>
            <FormattedMessage id="actions.cancel" />
          </SecondaryButton>
        </>
      );
    }

    return (
      <>
        {this.recommendations.map(({ recommendations }) =>
          recommendations.map((recommendation) => (
            <RecommendationCard
              key={recommendation.productSkus[0].id}
              onCreateProject={!this.props.createProjectDisabled ? this.createProject : undefined}
              onDownloadBom={this.downloadBillOfMaterials}
              thermostatSkuOptions={this.thermostatSkuOptions}
              recommendation={recommendation}
              countryCode={this.roomDetails?.countryCode}
            />
          ))
        )}
        <div>
          <SecondaryButton onClick={this.goBack} className={style.cancelButton}>
            <FormattedMessage id="actions.back" />
          </SecondaryButton>
          <SecondaryButton onClick={this.redirectToSelectionGuide} className={style.cancelButton}>
            <FormattedMessage id="selectionGuide.newSelection" />
          </SecondaryButton>
        </div>
      </>
    );
  };

  private fetchRecommendations = async (
    values: GetIndependentRecommendationsPayload
  ): Promise<CategoryRecommendation[]> => {
    return (await this.props.api.products.getIndependentRecommendations(values)).data;
  };

  private goBack = () => {
    if (this.roomDetails) {
      this.props.history.replace({
        pathname: "/selection-guide/room",
        search: qs.stringify(this.roomDetails),
      });
    }
  };

  private redirectToSelectionGuide = () => {
    this.props.history.push("/selection-guide");
  };

  private createProject = (recommendation: CategoryRecommendationVariant) => {
    if (!this.roomDetails) {
      return;
    }

    const productSkus = recommendationToProductSpecs(recommendation);
    setAwaitingProject({ roomDetails: this.roomDetails, productSkus });
    this.props.history.push("/projects/continue");
  };

  private parseSelectionGuideValuesFromUrl = () => {
    try {
      const urlParams = qs.parse(this.props.history.location.search);
      const values = selectionGuideValuesSchema.validateSync(urlParams);
      return values;
    } catch (error) {
      return null;
    }
  };

  private downloadBillOfMaterials = async ({
    products,
    comments,
  }: {
    products: CategoryRecommendationVariant;
    comments: string;
  }) => {
    if (!this.roomDetails) {
      return;
    }

    const productSkus = recommendationToProductSpecs(products).map((recommendation) =>
      pickBy(recommendation)
    ) as BOMProductSku[];

    try {
      const { area, areaType, floorConstruction, floorFinish, installableArea, projectName = "" } = this.roomDetails;
      const { user, api } = this.props;

      const payload = {
        countryCode: this.countryCode,
        productSkus,
        area,
        roomType: areaType,
        floorConstruction,
        floorFinish,
        installableArea,
        locale: this.props.app.locale,
        comments,
        projectName,
      };

      const { data } = await (user.isReady
        ? api.products.downloadProductOnlyBOMv2(payload)
        : api.products.downloadPublicProductOnlyBOMv2(payload));

      fileSaver.saveAs(data, "Bill_of_materials.pdf");
    } catch (error) {
      logger.error(error);
      this.props.notifications.createError(<FormattedMessage id="error.reportDownloadFailed" />);
    }
  };
}

export const RecommendationsStep = compose<RecommendationsStepInnerProps, RecommendationsStepProps>(
  withRouter,
  inject("api", "notifications", "products", "user", "app"),
  observer
)(RecommendationsStepInner);
