import { inject, observer } from "mobx-react";
import { FunctionComponent } from "react";
import { compose } from "recompose";

import AppStore from "nvent-web/stores/App";
import { LocaleString } from "nvent-web/types/LocaleString";

interface TranslatedStringProps {
  value: LocaleString;
}

interface TranslatedStringPropsInner extends TranslatedStringProps {
  app: AppStore;
}

const TranslatedString: FunctionComponent<TranslatedStringPropsInner> = ({ app, value }) => {
  return <span>{value[app.locale]}</span>;
};

export default compose<TranslatedStringPropsInner, TranslatedStringProps>(inject("app"), observer)(TranslatedString);
