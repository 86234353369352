import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { compose } from "recompose";

import { ProjectSearchFilters } from "nvent-web/stores/ProjectsSearch";
import { TeamInstallersStore } from "nvent-web/stores/TeamInstallers";
import { UserStore } from "nvent-web/stores/User";

import style from "./ProjectsTabs.module.scss";
import { TeamAccessModal } from "./TeamAccessModal";

type Owner = ProjectSearchFilters["owner"];

interface ProjectsTabsProps {
  selected: Owner;
  onSelect: (owner: Owner) => void;
  counts: { [K in Owner]: number };

  classNames?: {
    tabs?: string;
    tabList?: string;
    tab?: string;
  };
}

interface ProjectsTabsPropsInner extends ProjectsTabsProps, InjectedIntlProps {
  user: UserStore;
  teamInstallers: TeamInstallersStore;
}

interface ProjectsTabsState {
  showCproRequiredModal: boolean;
  showTeamMembersRequiredModal: boolean;
}

class ProjectsTabs extends Component<ProjectsTabsPropsInner, ProjectsTabsState> {
  private tabOwners: Owner[] = ["my", "team"];

  constructor(props: ProjectsTabsPropsInner) {
    super(props);

    this.state = {
      showTeamMembersRequiredModal: false,
      showCproRequiredModal: false,
    };
  }

  componentDidMount() {
    this.props.teamInstallers.loadInstallers();
  }

  render() {
    const { counts, selected, classNames = {} } = this.props;
    const { showTeamMembersRequiredModal, showCproRequiredModal } = this.state;
    const tabIndex = this.tabOwners.indexOf(selected);

    return (
      <>
        <Tabs selectedIndex={tabIndex} onSelect={this.onSelect} className={classNames.tabs}>
          <TabList className={classnames(style.tabList, classNames.tabList)}>
            {this.tabOwners.map((owner) => (
              <Tab key={owner} className={classNames.tab}>
                <FormattedMessage id={`projects.tabs.${owner}Projects`} values={{ count: counts[owner] }} />
              </Tab>
            ))}
          </TabList>

          {this.tabOwners.map((owner) => (
            <TabPanel key={owner} />
          ))}
        </Tabs>

        <TeamAccessModal
          isOpen={showCproRequiredModal}
          handleClose={this.hideCproRequiredModal}
          messages={{
            info: "projects.modal.notcpro.info",
            contact: "projects.modal.notcpro.contact",
          }}
        />

        <TeamAccessModal
          isOpen={showTeamMembersRequiredModal}
          handleClose={this.hideTeamMembersRequiredModal}
          messages={{
            info: "projects.modal.cpro.info",
            contact: "projects.modal.cpro.contact",
          }}
        />
      </>
    );
  }

  private onSelect = (index: number) => {
    const owner = this.tabOwners[index];

    const {
      user: { certifiedProNumber },
      teamInstallers: { hasMultipleInstallers },
      onSelect,
    } = this.props;

    if (owner === "team" && !certifiedProNumber) {
      return this.setState({ showCproRequiredModal: true });
    }

    if (owner === "team" && !hasMultipleInstallers) {
      return this.setState({ showTeamMembersRequiredModal: true });
    }

    return onSelect(owner);
  };

  private hideCproRequiredModal = () => {
    this.setState({ showCproRequiredModal: false });
  };

  private hideTeamMembersRequiredModal = () => {
    this.setState({ showTeamMembersRequiredModal: false });
  };
}

export default compose<ProjectsTabsPropsInner, ProjectsTabsProps>(
  injectIntl,
  inject("user", "teamInstallers"),
  observer
)(ProjectsTabs);
