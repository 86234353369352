import { History } from "history";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";

import ProjectForm from "nvent-web/components/ProjectForm";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { NewProjectFormValues } from "nvent-web/types/NewProjectFormValues";

interface NewProjectFormInnerProps {
  projects: ProjectsStore;
  history: History;
}

const initialValues: NewProjectFormValues = {
  project: {
    name: "",
    addressAttributes: {
      streetAddress: "",
      postalCode: "",
      locality: "",
    },
    clientName: "",
    clientCompany: "",
    clientEmail: "",
    clientPhoneNumber: "",
  },
};

class NewProjectFormInner extends Component<NewProjectFormInnerProps> {
  render() {
    const { projects } = this.props;

    return (
      <ProjectForm
        initialValues={initialValues}
        onCancel={this.handleCancel}
        handleSubmit={projects.createProject}
        isSubmitting={projects.isProjectLoading}
      />
    );
  }

  private handleCancel = () => {
    this.props.history.goBack();
  };
}

const NewProjectForm = compose<NewProjectFormInnerProps, Record<string, unknown>>(
  inject("projects"),
  withRouter,
  observer
)(NewProjectFormInner);

export default NewProjectForm;
