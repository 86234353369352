import { FunctionComponent } from "react";

import style from "./UserInitials.module.scss";

interface UserInitialsProps {
  firstName: string;
  lastName: string;
  className?: string;
}

const UserInitials: FunctionComponent<UserInitialsProps> = ({ firstName = "", lastName = "", className = "" }) => (
  <span className={`${style.default} ${className}`}>{firstName.slice(0, 1) + lastName.slice(0, 1)}</span>
);

export default UserInitials;
