import classnames from "classnames";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { ReactComponent as ArrowRightIcon } from "nvent-web/assets/svg/arrow-right.svg";
import { BuildingType } from "nvent-web/types/BuildingType";
import { ProjectInsulationItem } from "nvent-web/types/ProjectInsulationItem";

import style from "./ProjectInsulationCard.module.scss";

interface ProjectInsulationCardParams {
  projectId: string;
  roomId: string;
}

export interface ProjectInsulationCardProps
  extends ProjectInsulationItem,
    RouteComponentProps<ProjectInsulationCardParams> {
  selected: boolean;
  onSelect: (state: BuildingType) => void;
  containerClassName?: string;
}

class ProjectInsulationCard extends Component<ProjectInsulationCardProps> {
  render() {
    const { icon, title, description, selected, containerClassName } = this.props;

    return (
      <div className={style.wrapper}>
        <div
          onClick={this.onChooseProjectIsolation}
          role="link"
          tabIndex={0}
          className={classnames(style.container, containerClassName, { [style.selected]: selected })}
        >
          <div className={classnames(style.iconContainer, { [style.selected]: selected })}>{icon}</div>
          <div className={style.content}>
            <h3 className={classnames(style.title, { [style.selected]: selected })}>{title}</h3>
            <p className={style.description}>{description}</p>
          </div>
          <div className={classnames(style.arrowIconContainer, { [style.selected]: selected })}>
            <ArrowRightIcon className={style.arrowIcon} />
          </div>
        </div>
      </div>
    );
  }

  private onChooseProjectIsolation = () => {
    this.props.onSelect(this.props.buildingType);
  };
}

export default withRouter(ProjectInsulationCard);
