import { decamelizeKeys } from "humps";

import Resource from "nvent-web/services/resources/Resource";
import { Role } from "nvent-web/types/Role";
import { Installer } from "nvent-web-admin/types/Installer";
import { Page } from "nvent-web-admin/types/Page";

interface FetchUsersOptions {
  q: string;
  page?: number;
  pageSize?: number | "all";
  sortBy?: string;
  sortDir?: "asc" | "desc";
  startDate?: string;
  endDate?: string;
  countryCodes?: string[];
}

export default class UsersResource extends Resource {
  getByPage(options: FetchUsersOptions) {
    return this.http.get<Page<Installer>>("admin/installers", {
      params: decamelizeKeys(options),
    });
  }

  getOne(id: string) {
    return this.http.get(`admin/installers/${id}`);
  }

  updateAdmin(id: string, role: Role) {
    return this.http.patch(`admin/installers/${id}`, { role });
  }

  async downloadReport() {
    await this.client.download({
      url: "admin/reports.xls",
      params: {
        reportType: "installers",
      },
    });
  }

  uploadXls(file: File) {
    const data = new FormData();
    data.append("file", file);
    return this.http.post<void>(`admin/certified_installers`, data);
  }
}
