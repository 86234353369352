import { Component } from "react";
import Lightbox from "react-image-lightbox";
import { FormattedMessage } from "react-intl";

import DeleteIcon from "nvent-web/components/DeleteIcon/DeleteIcon";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { Photo } from "nvent-web/types/Photo";

import style from "./PhotoGrid.module.scss";

interface PhotoGridProps {
  removeImage?: (removeImageId: number) => void;
  photos: Photo[];
  deleteDisabled?: boolean;
}

interface PhotoGridState {
  photoIndex: number;
  isLightBoxOpen: boolean;
  isRemoveConfirmationOpen: boolean;
  removeImageId: number;
}

class PhotoGrid extends Component<PhotoGridProps, PhotoGridState> {
  state = {
    photoIndex: 0,
    isLightBoxOpen: false,
    isRemoveConfirmationOpen: false,
    removeImageId: 0,
  };

  render() {
    const { deleteDisabled = false } = this.props;
    const { isLightBoxOpen, photoIndex } = this.state;
    const { photos } = this.props;

    const thumbnailUrls = photos.map((photo) => ({
      id: photo.id,
      thumbnail: photo.urls.thumbnail,
    }));
    const photoUrls = photos.map((photo) => photo.urls.original);
    return (
      <>
        {!deleteDisabled && (
          <ConfirmationModal
            isOpen={this.state.isRemoveConfirmationOpen}
            handleClose={this.closeRemoveConfirmation}
            center
            title={<FormattedMessage id={"rooms.removeImage.title"} />}
            description={<FormattedMessage id={"rooms.removeImage.description"} />}
            onCancel={this.closeRemoveConfirmation}
            onConfirm={this.handleRemove}
          />
        )}
        <div className={style.photosWrapper}>
          {thumbnailUrls.map((photo, i) => (
            <div key={photo.id} className={style.photoWrapper}>
              {!deleteDisabled && (
                <DeleteIcon
                  onDelete={() => {
                    this.openRemoveConfirmation(photo.id);
                  }}
                  className={style.deleteIcon}
                />
              )}
              <div
                className={style.photo}
                onClick={() => this.onPhotoClick(i)}
                style={{ backgroundImage: `url(${photo.thumbnail})` }}
              />
            </div>
          ))}
          {isLightBoxOpen && (
            <Lightbox
              mainSrc={photoUrls[photoIndex]}
              nextSrc={photoUrls[(photoIndex + 1) % photoUrls.length]}
              prevSrc={photoUrls[(photoIndex + photoUrls.length - 1) % photoUrls.length]}
              onCloseRequest={this.onLighboxClose}
              onMovePrevRequest={this.onPreviousPhoto}
              onMoveNextRequest={this.onNextPhoto}
            />
          )}
        </div>
      </>
    );
  }

  private openRemoveConfirmation = (photoId: number) => {
    this.setState({
      isRemoveConfirmationOpen: !this.state.isRemoveConfirmationOpen,
      removeImageId: photoId,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({ isRemoveConfirmationOpen: !this.state.isRemoveConfirmationOpen });
  };

  private handleRemove = () => {
    if (this.props.deleteDisabled) {
      return;
    }
    const { removeImage } = this.props;

    if (removeImage) {
      removeImage(this.state.removeImageId);
    }

    this.closeRemoveConfirmation();
  };

  private onPhotoClick = (photoIndex: number) => this.setState({ photoIndex, isLightBoxOpen: true });

  private onNextPhoto = () => {
    const { photoIndex } = this.state;
    const { photos } = this.props;
    const photoUrls: string[] = photos.map((photo) => photo.urls.original);

    this.setState({
      photoIndex: (photoIndex + 1) % photoUrls.length,
    });
  };

  private onPreviousPhoto = () => {
    const { photoIndex } = this.state;
    const { photos } = this.props;
    const photoUrls: string[] = photos.map((photo) => photo.urls.original);

    this.setState({
      photoIndex: (photoIndex + photoUrls.length - 1) % photoUrls.length,
    });
  };

  private onLighboxClose = () => this.setState({ isLightBoxOpen: false });
}

export default PhotoGrid;
