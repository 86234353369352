import classNames from "classnames";
import { inject } from "mobx-react";
import qs from "query-string";
import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import { KnownLocale, knownLocales } from "nvent-web/i18n";
import { UserStore } from "nvent-web/stores/User";

import style from "./IndependentSelectionGuide.module.scss";
import { InsulationStep } from "./InsulationStep";
import { RecommendationsStep } from "./RecommendationsStep";
import { RoomDetailsStep } from "./RoomDetailsStep";

interface IndependentSelectionGuideProps {
  fullWidth?: boolean;
  createProjectDisabled?: boolean;
}

interface IndependentSelectionGuideInnerProps extends RouteComponentProps, IndependentSelectionGuideProps {
  user: UserStore;
}

const IndependentSelectionGuideInner: FunctionComponent<IndependentSelectionGuideInnerProps> = ({
  match,
  user,
  fullWidth,
  createProjectDisabled,
  location,
}) => {
  useEffect(() => {
    const { lang } = qs.parse(location.search);

    if (!lang || Array.isArray(lang) || lang === user.locale) {
      return;
    }

    const newLocale = lang.toLowerCase() as KnownLocale;

    if (knownLocales.includes(newLocale)) {
      user.setLocale(newLocale);
    }
  }, [location.search, user]);

  return (
    <div className={classNames(style.container, { [style.fullWidthContainer]: fullWidth })}>
      <PageTitle>
        <FormattedMessage id="selectionGuide.title" />
      </PageTitle>
      <div className={style.content}>
        <Switch>
          <Route path={`${match.path}/insulation`} exact render={() => <InsulationStep fullWidth={fullWidth} />} />
          <Route path={`${match.path}/room`} exact component={RoomDetailsStep} />
          <Route
            path={`${match.path}/recommendations`}
            exact
            render={() => <RecommendationsStep createProjectDisabled={createProjectDisabled} />}
          />
          <Redirect to={`${match.url}/insulation`} />
        </Switch>
      </div>
    </div>
  );
};

export const IndependentSelectionGuide = compose<IndependentSelectionGuideInnerProps, IndependentSelectionGuideProps>(
  inject("user"),
  withRouter
)(IndependentSelectionGuideInner);
