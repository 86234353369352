import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { FunctionComponent } from "react";
import { compose } from "recompose";

import { ReactComponent as CloseIcon } from "nvent-web/assets/svg/side-menu/close.svg";
import AppStore from "nvent-web/stores/App";
import { Notification as NotificationModel } from "nvent-web/stores/Notifications";

import style from "./Notification.module.scss";

export interface NotificationProps {
  notification: NotificationModel;
}

export const NotificationInner: FunctionComponent<NotificationProps> = ({ notification }) => {
  const close = () => {
    notification.close();
  };

  return (
    <div className={classNames(style.root, style[notification.theme])}>
      <span className={style.label}>{notification.message}</span>
      <button type="button" className={style.closeButton} onClick={close}>
        <CloseIcon />
      </button>
    </div>
  );
};

export const Notification = compose<NotificationProps, NotificationProps>(
  inject(({ app }: { app: AppStore }) => ({ locale: app.locale })),
  observer
)(NotificationInner);
