import { ProjectCommissionReportsGeneration } from "../../../types/ProjectsCommissionReportsGeneration";
import Resource from "../../resources/Resource";

export default class CommissionReportGenerationResource extends Resource {
  get(projectId: string, projectToken: string) {
    return this.http.get<ProjectCommissionReportsGeneration>(`projects/${projectId}/commission_reports_generation`, {
      headers: { "Project-Authorization-Token": projectToken },
      baseURL: process.env.REACT_APP_PUBLIC_API_V3_URL,
    });
  }

  create(projectId: string, projectToken: string) {
    return this.http.post<ProjectCommissionReportsGeneration>(
      `projects/${projectId}/commission_reports_generation`,
      {},
      {
        headers: { "Project-Authorization-Token": projectToken },
        baseURL: process.env.REACT_APP_PUBLIC_API_V3_URL,
      }
    );
  }
}
