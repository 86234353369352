import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { FunctionComponent, ReactNode, useCallback, useRef } from "react";

import { useBaseUrl } from "nvent-web/hooks/useBaseUrl";
import { AppAuth0Context } from "nvent-web/services/auth0/AppAuth0Context";
import {
  apiAudience,
  apiScope,
  auth0ClientId,
  auth0Domain,
  auth0LoginCallbackPath,
} from "nvent-web/services/auth0/auth0Config";
import { AppAuth0, useCreateAppAuth0 } from "nvent-web/services/auth0/useCreateAppAuth0";

export interface AppAuth0ProviderProps {
  children: ReactNode;
}

export const AppAuth0Provider = (props: AppAuth0ProviderProps) => {
  const { children } = props;
  const baseUrl = useBaseUrl();
  const auth0Ref = useRef<AppAuth0 | null>(null);

  const setAuth0 = (auth0: AppAuth0) => {
    auth0Ref.current = auth0;
  };

  const redirect = useCallback((appState?: AppState) => {
    const auth0 = auth0Ref.current;

    if (!auth0) {
      throw new Error("Missing auth0Ref.current");
    }

    auth0.handleRedirectCallback(appState);
  }, []);

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: apiAudience,
        scope: apiScope,
        redirect_uri: `${baseUrl}${auth0LoginCallbackPath}`,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
      onRedirectCallback={redirect}
    >
      <InternalAppAuth0Provider setAuth0={setAuth0}>{children}</InternalAppAuth0Provider>
    </Auth0Provider>
  );
};

const InternalAppAuth0Provider: FunctionComponent<{ setAuth0(auth0: AppAuth0): void; children: ReactNode }> = ({
  setAuth0,
  children,
}) => {
  const value = useCreateAppAuth0();

  setAuth0(value);

  return <AppAuth0Context.Provider value={{ value }}>{children}</AppAuth0Context.Provider>;
};
