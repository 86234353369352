import keyBy from "lodash-es/keyBy";
import { action, autorun, computed, observable, when } from "mobx";
import { fromPromise, IPromiseBasedObservable, PENDING } from "mobx-utils";

import Api from "nvent-web/services/Api";
import { ProductCatalog } from "nvent-web/services/resources/ProductCatalogResource";
import { CategoryType } from "nvent-web/types/CategoryType";
import { NewProductSpec } from "nvent-web/types/NewProductSpec";
import { Product } from "nvent-web/types/Product";
import { ProductBase } from "nvent-web/types/ProductBase";
import { ProductCategory } from "nvent-web/types/ProductCategory";
import { ProductSku } from "nvent-web/types/ProductSku";
import { createInitialFromPromise } from "nvent-web/utils/createInitialFromPromise";
import { enumIndex, groupByEnum } from "nvent-web/utils/enum";

import { RoomsStore } from "./Rooms";
import { UserStore } from "./User";

const emptyCatalog: ProductCatalog = {
  products: [],
  productSkus: [],
  productCategories: [],
};

export class ProductsStore {
  @observable newProductPromise?: IPromiseBasedObservable<void>;
  @observable newProductTestPromise?: IPromiseBasedObservable<Product>;
  @observable productsCatalogPromise = createInitialFromPromise<ProductCatalog>(emptyCatalog);
  @observable productPromise?: IPromiseBasedObservable<Product>;
  @observable removeProductPromise?: IPromiseBasedObservable<void>;
  @observable removeThermostatPromise?: IPromiseBasedObservable<void>;
  @observable removeAccessoryPromise?: IPromiseBasedObservable<void>;
  @observable productBatchNumberPromise?: IPromiseBasedObservable<Product>;
  @observable singleProduct?: Product;

  constructor(
    private user: UserStore,
    private rooms: RoomsStore,
    private api: Api
  ) {
    when(
      () => user.isReady,
      () => {
        this.loadProductsCatalog();
      }
    );

    autorun(() => {
      if (this.productPromise) {
        this.singleProduct = this.productPromise.case({ fulfilled: (r) => r }) || this.singleProduct;
      }
    });
  }

  @computed
  get isProductCatalogLoading(): boolean {
    return this.productsCatalogPromise.state === PENDING;
  }

  @computed
  get productCatalog(): ProductCatalog {
    return this.productsCatalogPromise.case({ fulfilled: (catalog) => catalog }) || emptyCatalog;
  }

  @computed
  get productCategories(): ProductCategory[] {
    return this.productCatalog.productCategories;
  }

  @computed
  get productSkus(): ProductSku[] {
    return this.productCatalog.productSkus;
  }

  @computed
  get availableProductSkus(): ProductSku[] {
    return this.productSkus.filter(({ availableCountries }) =>
      availableCountries.includes(this.user.companyAddress.countryCode)
    );
  }

  @computed
  get availableProducts(): ProductBase[] {
    const productIds = new Set(this.availableProductSkus.map(({ productId }) => productId));
    return this.productCatalog.products.filter(({ id }) => productIds.has(id));
  }

  @computed
  get availableProductCategories(): ProductCategory[] {
    const categoryIds = new Set(this.availableProducts.map(({ productCategoryId }) => productCategoryId));
    return this.productCategories.filter(({ id }) => categoryIds.has(id));
  }

  @computed
  get productCategoriesByType() {
    return groupByEnum(CategoryType, this.productCategories, ({ type }) => type);
  }

  @computed
  get availableProductCategoriesByType() {
    return groupByEnum(CategoryType, this.availableProductCategories, ({ type }) => type);
  }

  @computed
  get products(): ProductBase[] {
    return this.productCatalog.products;
  }

  @computed
  get availableThermostatSkus(): ProductSku[] {
    const thermostatCategory = this.availableProductCategories.find(({ type }) => type === CategoryType.thermostat);
    const thermostatCategoryId = thermostatCategory ? thermostatCategory.id : null;

    if (!thermostatCategoryId) {
      return [];
    }

    const thermostats = this.availableProducts.filter(
      ({ productCategoryId }) => productCategoryId === thermostatCategoryId
    );

    const thermostatsById = keyBy(thermostats, ({ id }) => id);

    return this.availableProductSkus.filter(({ productId }) => productId in thermostatsById);
  }

  @computed
  get productsByType() {
    const categoriesById = keyBy(this.productCategories, (c) => c.id);

    const byType = enumIndex(CategoryType, () => [] as ProductBase[]);

    this.products.forEach((product) => {
      const category = categoriesById[product.productCategoryId];
      byType[category.type].push(product);
    });

    return byType;
  }

  @computed
  get availableProductsByType() {
    const categoriesById = keyBy(this.availableProductCategories, (c) => c.id);

    const byType = enumIndex(CategoryType, () => [] as ProductBase[]);

    this.availableProducts.forEach((product) => {
      const category = categoriesById[product.productCategoryId];
      byType[category.type].push(product);
    });

    return byType;
  }

  @computed
  get product(): Product | undefined {
    return this.productPromise && this.productPromise.case({ fulfilled: (product) => product });
  }

  @action.bound
  loadProductsCatalog() {
    this.productsCatalogPromise = fromPromise(this.api.productCatalog.getCatalog().then(({ data }) => data));
  }

  @action.bound
  loadProductsCatalogForCountry(countryCode: string) {
    this.productsCatalogPromise = fromPromise(
      this.api.productCatalog.getCatalogForCountry(countryCode).then(({ data }) => data)
    );
  }

  @computed
  get isProductCreating(): boolean {
    return Boolean(this.newProductPromise && this.newProductPromise.state === PENDING);
  }

  @action.bound
  async createProductSkus(projectId: number, roomId: number, specs: NewProductSpec[]) {
    this.newProductPromise = fromPromise(
      this.api.products.createSkus(projectId, roomId, specs).then(({ data }) => data)
    );

    await this.newProductPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.newProductPromise;
  }

  @action.bound
  async createProductSku(projectId: number, roomId: number, spec: NewProductSpec) {
    return this.createProductSkus(projectId, roomId, [spec]);
  }

  @computed
  get isProductLoading(): boolean {
    return Boolean(this.productPromise && this.productPromise.state === PENDING);
  }

  @action.bound
  getProduct(projectId: number, roomId: number, productId: number) {
    this.productPromise = fromPromise(this.api.products.getOne(projectId, roomId, productId).then(({ data }) => data));

    return this.productPromise;
  }

  @action.bound
  async testProduct(
    projectId: number,
    roomId: number,
    productId: number,
    heatResistance: number,
    insulationResistance: number,
    testType: string
  ) {
    this.newProductTestPromise = fromPromise(
      this.api.products
        .test(projectId, roomId, productId, heatResistance, insulationResistance, testType)
        .then(({ data }) => data)
    );

    return this.newProductTestPromise;
  }

  @action.bound
  async setProductBatchNumber({
    projectId,
    roomId,
    productId,
    batchNumber,
    reflectaSerialNumber,
  }: {
    projectId: number;
    roomId: number;
    productId: number;
    batchNumber: string | null;
    reflectaSerialNumber: string | null;
  }) {
    this.productBatchNumberPromise = fromPromise(
      this.api.products
        .setBatchNumber({ projectId, roomId, productId, batchNumber, reflectaSerialNumber })
        .then(({ data }) => data)
    );

    return this.productBatchNumberPromise;
  }

  @action.bound
  async removeProduct(projectId: number, roomId: number, productId: number) {
    this.removeProductPromise = fromPromise(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.api.products.removeProduct(projectId, roomId, productId).then(({ data }) => data)
    );
    await this.removeProductPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.removeProductPromise;
  }

  @action.bound
  async removeThermostat(projectId: number, roomId: number, thermostatId: number) {
    this.removeThermostatPromise = fromPromise(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.api.thermostat.remove(projectId, roomId, thermostatId).then(({ data }) => data)
    );
    await this.removeThermostatPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.removeThermostatPromise;
  }

  @action.bound
  async removeAccessory(projectId: number, roomId: number, accessoryId: number) {
    this.removeAccessoryPromise = fromPromise(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.api.accessories.remove(projectId, roomId, accessoryId).then(({ data }) => data)
    );
    await this.removeAccessoryPromise;
    await this.rooms.getRoom(projectId, roomId);

    return this.removeAccessoryPromise;
  }
  @action.bound
  async removeAllProducts(projectId: number, roomId: number) {
    await this.api.products.deleteAllProducts(projectId, roomId);
    await this.rooms.getRoom(projectId, roomId);
  }
}
