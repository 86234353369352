import { FunctionComponent } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import Modal, { ModalProps } from "nvent-web/components/Modal";

import style from "./TeamAccessModal.module.scss";

export interface TeamAccessModalProps extends Omit<ModalProps, "center"> {
  messages: {
    info: string;
    contact: string;
  };
}

export const TeamAccessModal: FunctionComponent<TeamAccessModalProps> = (props) => (
  <Modal center {...props}>
    <div className={style.content}>
      <p className={style.info}>
        <FormattedHTMLMessage id={props.messages.info} values={{ domain: style.domain }} />
      </p>
      <p className={style.contact}>
        <FormattedMessage id={props.messages.contact} />
      </p>
    </div>
  </Modal>
);
