import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { compose } from "recompose";

import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProductsStore } from "nvent-web/stores/Products";

import style from "./AddProductForm.module.scss";
import ByCategoryForm from "./components/ByCategoryForm";
import ByNumberForm from "./components/ByNumberForm";

export interface AddProductParams {
  projectId: string;
  roomId: string;
}

interface AddProductFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

interface AddProductFormInnerProps extends AddProductFormProps, RouteComponentProps<AddProductParams> {
  products: ProductsStore;
  notifications: NotificationsStore;
}

class AddProductFormInner extends Component<AddProductFormInnerProps> {
  componentDidMount() {
    this.props.products.loadProductsCatalog();
  }

  render() {
    const { onClose } = this.props;

    return (
      <>
        <h3 className={style.title}>
          <FormattedMessage id="product.add.title" />
        </h3>

        <Tabs>
          <TabList className={style.tabList}>
            <Tab>
              <FormattedMessage id="product.add.byCategory" />
            </Tab>
            <Tab>
              <FormattedMessage id="product.add.byNumber" />
            </Tab>
          </TabList>

          <TabPanel>
            <ByCategoryForm onClose={onClose} onSubmit={this.submitSku} />
          </TabPanel>
          <TabPanel>
            <ByNumberForm onClose={onClose} onSubmit={this.submitSku} />
          </TabPanel>
        </Tabs>
      </>
    );
  }

  private submitSku = async (productSkuId: number, length?: number) => {
    const { match, onClose, onSuccess, notifications } = this.props;
    const { projectId, roomId } = match.params;
    try {
      await this.props.products.createProductSku(Number(projectId), Number(roomId), { productSkuId, length });
      onSuccess();
      onClose();
    } catch (error) {
      logger.error(error);
      notifications.createError(<FormattedMessage id="error.productUnavailable" />);
    }
  };
}

export const AddProductForm = compose<AddProductFormInnerProps, AddProductFormProps>(
  inject("products", "notifications"),
  withRouter,
  observer
)(AddProductFormInner);

export default AddProductForm;
