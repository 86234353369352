import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { ChangeEvent, Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { DropdownField } from "nvent-web/components/form/DropdownField";
import AppStore from "nvent-web/stores/App";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { createOption } from "nvent-web/utils/create-option";

interface FloorFinishFieldProps {
  name?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  onChange?: (floorFinish: string) => void;
  initialValue?: string;
}

interface FloorFinishFieldInnerProps extends FloorFinishFieldProps, InjectedIntlProps {
  app: AppStore;
  rooms: RoomsStore;
}

class FloorFinishFieldInner extends Component<FloorFinishFieldInnerProps> {
  @computed
  private get floorFinishOptions(): DropdownOption[] {
    const { floorFinishOptions } = this.props.rooms;
    const { locale } = this.props.app;

    return floorFinishOptions
      .filter((option) => option.id === this.props.initialValue || !option.deprecated)
      .map(({ name, id }) => createOption({ id, name }, locale));
  }

  render() {
    const { intl, name = "floorFinish", label, placeholder, required } = this.props;

    const labelValue = label || <FormattedMessage id="form.floorFinish" />;
    const placeholderValue = placeholder || intl.formatMessage({ id: "form.floorFinish.selectPlaceholder" });

    return (
      <DropdownField
        name={name}
        label={labelValue}
        options={this.floorFinishOptions}
        placeholder={placeholderValue}
        required={required}
        onChange={this.handleFloorFinishChange}
      />
    );
  }

  private handleFloorFinishChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event.target.value);
    }
  };
}

const FloorFinishField = compose<FloorFinishFieldInnerProps, FloorFinishFieldProps>(
  injectIntl,
  inject("app", "rooms"),
  observer
)(FloorFinishFieldInner);

export default FloorFinishField;
