import { FunctionComponent, lazy, Suspense } from "react";

import { LoadingSection } from "nvent-web/components/LoadingSection";

export const LazyApp = lazy(() => import("./App"));

export const AsyncApp: FunctionComponent = () => (
  <Suspense fallback={<LoadingSection />}>
    <LazyApp />
  </Suspense>
);
