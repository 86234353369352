import { DetailedRoom } from "nvent-web/types/DetailedRoom";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";
import { Room } from "nvent-web/types/Room";
import { RoomCommissionReportGeneration } from "nvent-web/types/RoomCommissionReportGeneration";

import Resource from "./Resource";

export default class RoomsResource extends Resource {
  errors = {
    roomHasNoPhotos: 422,
  };
  create({ data, projectId, levelId }: { data: NewRoomFormValues; projectId: number; levelId?: number }) {
    return this.http.post<Room>(`projects/${projectId}/rooms`, { ...data, levelId });
  }
  getOne(projectId: number, roomId: number) {
    return this.http.get<DetailedRoom>(`projects/${projectId}/rooms/${roomId}`);
  }
  remove(projectId: number, roomId: number) {
    return this.http.delete(`projects/${projectId}/rooms/${roomId}`);
  }
  updateOne(data: Partial<NewRoomFormValues>, projectId: number, roomId: number) {
    return this.http.patch<Room>(`projects/${projectId}/rooms/${roomId}`, data);
  }
  copy(projectId: number, roomId: number) {
    return this.http.post(`projects/${projectId}/rooms/${roomId}/duplicate`);
  }
  commission(roomId: number) {
    return this.http.post(`/rooms/${roomId}/commission`);
  }
  generateReport(roomId: number) {
    return this.http.post(`rooms/${roomId}/commission_report`);
  }
  fetchReportStatus(roomId: number) {
    return this.http.get<RoomCommissionReportGeneration>(`/rooms/${roomId}/commission_report`);
  }
  getBOM(roomId: number) {
    return this.http.post<{ url: string }>(
      `/rooms/${roomId}/bill_of_materials`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_URL,
      }
    );
  }
}
