import { AxiosRequestTransformer, AxiosResponseTransformer } from "axios";

function toArray<T>(value: T | T[] | undefined) {
  if (Array.isArray(value)) {
    return value;
  }

  return value ? [value] : [];
}

export function buildTransformers<T extends AxiosRequestTransformer | AxiosResponseTransformer>(
  ...values: Array<T | T[] | undefined>
): T[] {
  return values.map(toArray).reduce((result, array) => {
    result.push(...array);

    return result;
  }, []);
}
