import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { PrimaryBlueButton, SecondaryButton } from "nvent-web/components/Button";

import style from "./SubmitCancelButtons.module.scss";

interface SubmitCancelButtonsProps {
  cancelLabel?: React.ReactNode;
  submitLabel?: React.ReactNode;
  onCancel: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

class SubmitCancelButtons extends Component<SubmitCancelButtonsProps> {
  render() {
    const {
      cancelLabel = <FormattedMessage id="actions.cancel" />,
      submitLabel = <FormattedMessage id="actions.save" />,
      onCancel,
      onSubmit,
      disabled,
      loading,
    } = this.props;

    return (
      <>
        <SecondaryButton className={style.cancelBtn} onClick={onCancel}>
          {cancelLabel}
        </SecondaryButton>
        <PrimaryBlueButton
          disabled={disabled}
          loading={loading}
          className={style.yesBtn}
          onClick={onSubmit}
          type="submit"
        >
          {submitLabel}
        </PrimaryBlueButton>
      </>
    );
  }
}

export default SubmitCancelButtons;
