import { inject } from "mobx-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";

import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { RoomItemCard } from "nvent-web/components/RoomItemCard";
import { useGenerateReport } from "nvent-web/hooks/useGenerateReport";
import { useGetBOM } from "nvent-web/hooks/useGetBOM";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { Room } from "nvent-web/types/Room";
import { ROOM_STOP_POLLING_STATUSES } from "nvent-web/utils/polling";

import { ContextMenu, ContextMenuAction } from "../ContextMenu/ContextMenu";

import style from "./RoomItem.module.scss";

type RoomItemOuterProps = {
  projectId: number;
  data: Room;
  index?: number;
  levelId?: number;
  isCopyRoomSubmitting: boolean;
  onCommission: (id: number) => void;
  onRemove: (id: number) => void;
  onEdit: (id: number) => void;
  onCopy: (id: number) => void;
  isProjectFinished: boolean;
  nestLevelIndex: number;
  isCommissionSubmitting: boolean;
};

type RoomItemProps = RoomItemOuterProps &
  RouteComponentProps<{ projectId: string }> & {
    api: Api;
    notifications: NotificationsStore;
  };

const RoomItemInner = ({
  data,
  projectId,
  index = 0,
  levelId,
  nestLevelIndex,
  isProjectFinished,
  onEdit,
  onRemove,
  onCommission,
  onCopy,
  api,
  notifications,
  isCopyRoomSubmitting,
  isCommissionSubmitting,
}: RoomItemProps) => {
  const [isRemoveConfirmationOpen, setRemoveConfirmationOpen] = useState(false);
  const [isCommissionConfirmationOpen, setIsCommissionConfirmationOpen] = useState(false);
  const { id, progress, finishedAt } = data;

  const { isGenerateReportSubmitting, generateReport } = useGenerateReport({
    notificationsStore: notifications,
    id,
    fetchReportStatusCallback: api.rooms.fetchReportStatus.bind(api.rooms),
    generateReportCallback: api.rooms.generateReport.bind(api.rooms),
    isBlobResponse: false,
    stopPollingStatuses: ROOM_STOP_POLLING_STATUSES,
    pollingInterval: 1000,
  });

  const { getBOM, isGetBOMSubmitting } = useGetBOM({
    id,
    getBOMCallback: api.rooms.getBOM.bind(api.rooms),
    notifications,
  });

  const handleEdit = () => onEdit(id);
  const handleRemove = () => onRemove(id);
  const handleCopy = () => onCopy(id);
  const handleCommission = () => onCommission(id);

  const isRoomCommissioned = Boolean(finishedAt);

  const commissionElement = !isRoomCommissioned
    ? [
        {
          type: "button" as const,
          labelId: "actions.commissioning",
          isDisabled: progress < 1,
          onClick: () => setIsCommissionConfirmationOpen(true),
        },
      ]
    : [];

  const removeElement = !isRoomCommissioned
    ? [
        {
          type: "button" as const,
          labelId: "actions.remove",
          onClick: () => setRemoveConfirmationOpen(true),
          className: style.removeAction,
        },
      ]
    : [];

  const actions: ContextMenuAction[] = [
    {
      type: "button",
      labelId: "actions.copy",
      isLoading: isCopyRoomSubmitting,
      onClick: handleCopy,
    },
    {
      type: "button",
      labelId: "actions.getBOM",
      isLoading: isGetBOMSubmitting,
      onClick: async (closeMenu) => {
        await getBOM();
        closeMenu && closeMenu();
      },
    },
    ...commissionElement,
    {
      type: "button",
      labelId: "actions.getPDF",
      isDisabled: !isRoomCommissioned,
      isLoading: isGenerateReportSubmitting,
      onClick: generateReport,
    },
    ...removeElement,
  ];

  return (
    <>
      <RoomItemCard
        link={`/projects/${projectId}/rooms/${id}`}
        data={data}
        index={index}
        levelId={levelId}
        onEdit={handleEdit}
        isProjectFinished={isProjectFinished}
        nestLevelIndex={nestLevelIndex}
      >
        {!isProjectFinished && <ContextMenu buttonClassName={style.button} actions={actions} />}
      </RoomItemCard>
      <ConfirmationModal
        isOpen={isRemoveConfirmationOpen}
        handleClose={() => setRemoveConfirmationOpen(false)}
        center
        title={<FormattedMessage id={"rooms.removeModal.title"} />}
        description={<FormattedMessage id={"rooms.removeModal.description"} />}
        onCancel={() => setRemoveConfirmationOpen(false)}
        onConfirm={handleRemove}
      />
      <ConfirmationModal
        center
        isOpen={isCommissionConfirmationOpen}
        handleClose={() => setIsCommissionConfirmationOpen(false)}
        title={<FormattedMessage id="room.commissionConfirmationModal.title" />}
        description={<FormattedMessage id="room.commissionConfirmationModal.description" />}
        onCancel={() => setIsCommissionConfirmationOpen(false)}
        loading={isCommissionSubmitting}
        onConfirm={handleCommission}
      />
    </>
  );
};

export const RoomItem = compose<RoomItemProps, RoomItemOuterProps>(
  inject("api", "notifications"),
  withRouter
)(RoomItemInner);
