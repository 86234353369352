import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import style from "./TestAction.module.scss";

export type TestActionProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const TestAction: FunctionComponent<TestActionProps> = ({ className, ...buttonProps }) => (
  <button {...buttonProps} type="button" className={classNames(style.testButton, className)}>
    <FormattedMessage id="actions.test" />
  </button>
);
