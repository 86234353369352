import { createBrowserHistory, History } from "history";
import { computed } from "mobx";
import { observer, Provider } from "mobx-react";
import { Component, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import ProjectCommissionReport from "nvent-web/components/ProjectCommissionReport";
import { messages } from "nvent-web/i18n";
import { IndependentSelectionGuide } from "nvent-web/scenes/IndependentSelectionGuide/IndependentSelectionGuide";
import Api from "nvent-web/services/Api";
import { AppAuth0Provider } from "nvent-web/services/auth0/AppAuth0Provider";
import { auth0LoginCallbackPath, auth0LogoutCallbackPath } from "nvent-web/services/auth0/auth0Config";
import PublicApi from "nvent-web/services/PublicApi";
import AppStore from "nvent-web/stores/App";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { PhotosStore } from "nvent-web/stores/Photos";
import { ProductsStore } from "nvent-web/stores/Products";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { PromotionsStore } from "nvent-web/stores/Promotions";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { TeamInstallersStore } from "nvent-web/stores/TeamInstallers";
import { UserStore } from "nvent-web/stores/User";
import { Role } from "nvent-web/types/Role";
import { isFeatureEnabled } from "nvent-web/utils/features";
import { AsyncApp as AdminApp } from "nvent-web-admin/AsyncApp";
import { RequiredRoleRoute } from "nvent-web-admin/components/RequiredRole";

import { AppAuth0Connector } from "../services/auth0/AppAuth0Connector";

import { AnonymousApp } from "./components/AnonymousApp";
import { AuthenticatedApp } from "./components/AuthenticatedApp";

interface Injectables {
  api: Api;
  publicApi: PublicApi;
  history: History;
  app: AppStore;
  user: UserStore;
  projects: ProjectsStore;
  promotions: PromotionsStore;
  notifications: NotificationsStore;
  rooms: RoomsStore;
  products: ProductsStore;
  photos: PhotosStore;
  teamInstallers: TeamInstallersStore;
}

@observer
export class App extends Component {
  private injectables: Injectables;

  @computed
  get isLoading() {
    return this.injectables.user.isLoading;
  }

  constructor(props: Record<string, unknown>) {
    super(props);

    const history = createBrowserHistory({
      basename: process.env.REACT_APP_BASENAME,
    });

    const notifications = new NotificationsStore();

    const api = new Api(process.env.REACT_APP_API_URL || "", notifications);
    const publicApi = new PublicApi(process.env.REACT_APP_API_URL || "", notifications);

    const user = new UserStore(api);
    const rooms = new RoomsStore(api, history);
    const app = new AppStore(user);

    this.injectables = {
      api,
      publicApi,
      history,
      user,
      app,
      projects: new ProjectsStore(user, api, history),
      promotions: new PromotionsStore(user, api),
      notifications,
      rooms,
      products: new ProductsStore(user, rooms, api),
      photos: new PhotosStore(rooms, api),
      teamInstallers: new TeamInstallersStore(api),
    };
  }

  render() {
    const { app, history } = this.injectables;

    return (
      <Provider {...this.injectables}>
        <IntlProvider locale={app.locale} messages={messages[app.locale]}>
          <Router history={history}>
            <AppAuth0Provider>
              <AppAuth0Connector />
              {this.renderContent()}
            </AppAuth0Provider>
          </Router>
        </IntlProvider>
      </Provider>
    );
  }

  private renderContent(): ReactNode {
    if (this.isLoading) {
      return <LoadingSection />;
    }

    const { user } = this.injectables;

    return (
      <Switch>
        {/* Auth0 callbacks which need to always work */}
        <Route path={auth0LoginCallbackPath} exact={true}>
          <Redirect to="/" />
        </Route>

        <Route path={auth0LogoutCallbackPath} exact={true}>
          <Redirect to="/auth/login" />
        </Route>

        {user.isReady ? (
          <Route>
            <Switch>
              <RequiredRoleRoute userRole={Role.admin} path="/admin" exact={false} component={AdminApp} />
              <Route component={AuthenticatedApp} />
            </Switch>
          </Route>
        ) : (
          <Route>
            <Switch>
              <Route path="/projects/:projectId/commission-report" exact={true} component={ProjectCommissionReport} />
              <Route
                path="/selection-guide"
                render={() => (
                  <IndependentSelectionGuide
                    createProjectDisabled={!isFeatureEnabled("selection-guide-create-project")}
                  />
                )}
              />
              <AnonymousApp />
            </Switch>
          </Route>
        )}
      </Switch>
    );
  }
}

export default App;
