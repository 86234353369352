import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";

import PageTitle from "nvent-web/App/components/PageTitle";

import ProductRecommendationsStep from "./components/ProductRecommendationsStep/ProductRecommendationsStep";
import ProjectInsulationStep from "./components/ProjectInsulationStep/ProjectInsulationStep";
import RoomDetailsStep from "./components/RoomDetailsStep/RoomDetailsStep";
import style from "./SelectionGuide.module.scss";

const SelectionGuide: FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <div className={style.container}>
      <PageTitle>
        <FormattedMessage id="selectionGuide.title" />
      </PageTitle>

      <Switch>
        <Route path={`${match.path}/room`} exact component={RoomDetailsStep} />
        <Route path={`${match.path}/insulation`} exact component={ProjectInsulationStep} />
        <Route path={`${match.path}/recommendations`} exact component={ProductRecommendationsStep} />
        <Redirect to={`${match.url}/insulation`} />
      </Switch>
    </div>
  );
};

export default SelectionGuide;
