import { KnownLocale } from "nvent-web/i18n/index";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { Option } from "nvent-web/types/Option";

export const createOption = (option: Option, locale: KnownLocale): DropdownOption => {
  const { id, name } = option;

  return {
    title: name[locale],
    key: id,
  };
};
