import memoize from "lodash-es/memoize";

import { loadScript } from "nvent-web/utils/script";

declare const Munchkin: munchkin.Global;

export function shouldLoad() {
  return Boolean(process.env.REACT_APP_MARKETO_ID);
}

export const load = memoize(async (): Promise<munchkin.Global> => {
  const id = process.env.REACT_APP_MARKETO_ID;

  if (!id) {
    throw new Error("Missing REACT_APP_MARKETO_ID");
  }

  await loadScript("//munchkin.marketo.net/munchkin.js");
  Munchkin.init(id);

  return Munchkin;
});

export async function get(): Promise<munchkin.Global | undefined> {
  try {
    return await load();
  } catch {
    return undefined;
  }
}
