import { ButtonHTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";

export type AddActionProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const AddAction = (props: AddActionProps) => (
  <button {...props} type="button">
    <FormattedMessage id="actions.add" />
  </button>
);
