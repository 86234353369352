import { Auth0Error } from "auth0-js";

export function isAuth0Error(error: any): error is Auth0Error {
  // Auth0 sometimes returns the error code as .error, sometimes as .code...
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return typeof error.error === "string" || typeof error.code === "string";
}

export function auth0ErrorCode(error: Auth0Error) {
  return error.error || error.code || "";
}

export function auth0ErrorDescription(error: Auth0Error) {
  return error.errorDescription || error.error_description || error.description || "";
}
