import { FunctionComponent } from "react";

import ThermostatForm from "nvent-web/components/AddThermostatForm/AddThermostatForm";
import Modal, { ModalProps } from "nvent-web/components/Modal";
import { DropdownOption } from "nvent-web/types/DropdownOption";

interface ThermostatModalProps extends ModalProps {
  onSubmit: (values: { productSkuId: string }) => void;
  thermostatSkuOptions: DropdownOption[];
}

const ThermostatModal: FunctionComponent<ThermostatModalProps> = (props) => (
  <Modal {...props}>
    <ThermostatForm
      onCancel={props.handleClose}
      onSubmit={props.onSubmit}
      thermostatOptions={props.thermostatSkuOptions}
    />
  </Modal>
);

export default ThermostatModal;
