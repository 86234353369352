import classnames from "classnames";
import { LocationDescriptor } from "history";
import { Children, cloneElement, Component, ReactElement } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowDownIcon } from "nvent-web/assets/svg/arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "nvent-web/assets/svg/arrowUp.svg";
import Progress from "nvent-web/components/Progress";

import style from "./Card.module.scss";

interface CardProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  image?: React.ReactNode;
  progress?: number;
  to?: LocationDescriptor;
  actions: React.ReactNode[];
  classNames?: {
    card?: string;
    header?: string;
  };
  toggleExpanded: () => void;
  isExpanded: boolean;
  isFinished?: boolean;
}

class Card extends Component<CardProps> {
  render() {
    const { children, progress, to, actions, classNames = {}, isFinished } = this.props;

    const actionButtons = Children.map(actions, (item) => {
      const itemProps = { className: style.button };
      return cloneElement(item as ReactElement, itemProps);
    });

    return (
      <div className={classnames(style.wrapper, classNames.card)}>
        <div className={classnames(style.header, classNames.header)}>
          {to ? (
            <Link to={to} className={style.link}>
              {this.renderTitles()}
            </Link>
          ) : (
            <div>{this.renderTitles()}</div>
          )}
          {this.renderImage()}
          {this.renderDropdownIcon()}
        </div>

        {this.props.isExpanded ? (
          <>
            <div>{children}</div>
            <div className={style.footer}>{actionButtons}</div>
          </>
        ) : null}

        {!isFinished && progress !== undefined && <Progress progress={progress} />}
      </div>
    );
  }

  private renderDropdownIcon() {
    return (
      <div className={style.arrowWrapper} onClick={this.toggle} role="button" tabIndex={0}>
        {this.props.isExpanded ? <ArrowUpIcon className={style.up} /> : <ArrowDownIcon className={style.down} />}
      </div>
    );
  }

  private toggle = () => {
    this.props.toggleExpanded();
  };

  private renderTitles() {
    const { title, subtitle } = this.props;

    return (
      <>
        <span className={style.title}>{title}</span>
        {subtitle && <div className={style.subtitle}>{subtitle}</div>}
      </>
    );
  }

  private renderImage() {
    const { image } = this.props;

    if (!image) {
      return null;
    }

    return <div className={style.imageWrapper}>{image}</div>;
  }
}

export default Card;
