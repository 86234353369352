import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import SimpleCard from "nvent-web/components/SimpleCard";

import { BundledAccessory } from "../../types";

import style from "./AccessoryItem.module.scss";

interface AccessoryItemProps {
  accessory: BundledAccessory;
  onRemove: (id: number) => void;
  disableActions: boolean;
  quantity?: number;
}

interface AccessoryItemState {
  isRemoveConfirmationOpen: boolean;
}

class AccessoryItem extends Component<AccessoryItemProps, AccessoryItemState> {
  state = { isRemoveConfirmationOpen: false };

  render() {
    const { isRemoveConfirmationOpen } = this.state;
    const { description, source } = this.props.accessory;
    const { quantity = 1 } = this.props;
    const accessoryActions = this.props.disableActions
      ? []
      : [<RemoveAction onClick={this.openRemoveConfirmation} key="remove" />];
    return (
      <>
        <SimpleCard
          title={
            <>
              {description} {source && <small>- {source.description}</small>}{" "}
              {quantity > 1 && <span className={style.quantity}> x {quantity}</span>}
            </>
          }
          actions={accessoryActions}
        />
        <ConfirmationModal
          isOpen={isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"accessory.removeModal.title"} />}
          description={<FormattedMessage id={"accessory.removeModal.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.handleRemove}
        />
      </>
    );
  }

  private handleRemove = () => {
    const {
      onRemove,
      accessory: { id },
    } = this.props;

    onRemove(id);
  };

  private openRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: true,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: false,
    });
  };
}

export default AccessoryItem;
