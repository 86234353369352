export function buildScript(src: string, async = true): HTMLScriptElement {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = async;
  script.src = src;
  return script;
}

export function loadScript(src: string, async = true): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = buildScript(src, async);
    script.onload = () => resolve();
    script.onerror = (_1, _2, _3, _4, error) => reject(error);
    document.head.appendChild(script);
  });
}
