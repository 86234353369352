import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { SecondaryBlueButton } from "nvent-web/components/Button";

import style from "./ItemList.module.scss";

interface ItemListProps {
  title: React.ReactNode;
  onAdd?: () => void;
  disableAdd?: boolean;
  required?: boolean;
}

class ItemList extends Component<ItemListProps> {
  render() {
    const { title, onAdd, disableAdd, children, required } = this.props;

    return (
      <>
        <div className={style.itemListHeader}>
          <p className={style.itemListTitle}>
            {title}
            {required && "*"}
          </p>
          {onAdd && !disableAdd && (
            <SecondaryBlueButton onClick={onAdd} className={style.button}>
              <FormattedMessage id="actions.add" />
            </SecondaryBlueButton>
          )}
        </div>
        {children}
      </>
    );
  }
}

export default ItemList;
