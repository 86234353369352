import { Component } from "react";
import { FormattedMessage } from "react-intl";

import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import { TestAction } from "nvent-web/components/Actions/TestAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import SimpleCard from "nvent-web/components/SimpleCard";
import { Product } from "nvent-web/types/Product";

interface ProductItemProps {
  product: Product;
  onRemove: (id: number) => void;
  onTest: (id: number) => void;
  disableActions: boolean;
}

interface ProductItemState {
  isRemoveConfirmationOpen: boolean;
}

class ProductItem extends Component<ProductItemProps, ProductItemState> {
  state = { isRemoveConfirmationOpen: false };

  render() {
    const { isRemoveConfirmationOpen } = this.state;
    const { description, tests, cableLength, customLength } = this.props.product;
    const { disableActions } = this.props;
    const productActions = disableActions
      ? []
      : [
          <RemoveAction onClick={this.openRemoveConfirmation} key="remove" />,
          <TestAction onClick={this.handleTest} key="test" />,
        ];

    const productDescription = customLength === true ? `${description} - ${cableLength || 0}m` : description;

    return (
      <>
        <SimpleCard
          title={productDescription}
          progress={((100 / 3) * tests.filter((test) => test.completed).length) / 100}
          actions={productActions}
          isFinished={disableActions}
        />
        <ConfirmationModal
          isOpen={isRemoveConfirmationOpen}
          handleClose={this.closeRemoveConfirmation}
          center
          title={<FormattedMessage id={"products.removeModal.title"} />}
          description={<FormattedMessage id={"products.removeModal.description"} />}
          onCancel={this.closeRemoveConfirmation}
          onConfirm={this.handleRemove}
        />
      </>
    );
  }

  private handleTest = () => {
    const {
      onTest,
      product: { id },
    } = this.props;

    onTest(id);
  };

  private handleRemove = () => {
    const {
      onRemove,
      product: { id },
    } = this.props;

    onRemove(id);
  };

  private openRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: true,
    });
  };

  private closeRemoveConfirmation = () => {
    this.setState({
      isRemoveConfirmationOpen: false,
    });
  };
}

export default ProductItem;
