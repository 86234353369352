type Value<E> = (string | number) & E[keyof E];

export function enumKeys<E extends Record<string, unknown>>(e: E) {
  return Object.keys(e).filter((key) => !key.match(/^\d/)) as Array<keyof E>;
}

export function enumValues<E extends Record<string, unknown>, V extends Value<E>>(e: E): V[] {
  return enumKeys(e).map((key) => e[key] as V);
}

export function enumEntries<E extends Record<string, unknown>>(e: E): Array<[keyof E, E[keyof E]]> {
  return enumKeys(e).map((key) => [key, e[key]]);
}

export function enumIndex<E extends Record<string, unknown>, V extends Value<E>, T>(e: E, initial: (value: V) => T) {
  const index = {} as { [P in V]: T };
  enumValues(e).forEach((value) => (index[value as V] = initial(value as V)));
  return index;
}

export function groupByEnum<E extends Record<string, unknown>, V extends Value<E>, T>(
  e: E,
  items: T[],
  getValue: (item: T) => V
) {
  const byEnum = enumIndex(e, () => [] as T[]);

  items.forEach((item) => {
    const value = getValue(item);
    byEnum[value].push(item);
  });

  return byEnum;
}
