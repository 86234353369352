import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import style from "./RemoveAction.module.scss";

export type RemoveActionProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const RemoveAction: FunctionComponent<RemoveActionProps> = ({ className, ...buttonProps }) => (
  <button {...buttonProps} type="button" className={classNames(style.removeButton, className)}>
    <FormattedMessage id="actions.remove" />
  </button>
);
