import { AxiosResponse } from "axios";

import { Project } from "nvent-web/types/Project";

import { ProjectFilters } from "./ProjectResource";
import Resource from "./Resource";

export class TeamProjectsResource extends Resource {
  async getAll(): Promise<AxiosResponse<Project[]>> {
    return this.search({});
  }

  async search({ state, search }: ProjectFilters): Promise<AxiosResponse<Project[]>> {
    return this.http.get<Project[]>("/team/projects", {
      params: {
        state,
        q: search,
      },
    });
  }
}
