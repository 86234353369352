import { Form, Formik } from "formik";
import { History } from "history";
import { inject } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import * as Yup from "yup";

import { TextField } from "nvent-web/components/form/TextField";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import { RequiredFieldLabel } from "nvent-web/components/RequiredFieldLabel";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { Address } from "nvent-web/types/Address";
import { NoopOuterProps } from "nvent-web/types/NoopOuterProps";

import style from "./EditLevelForm.module.scss";

type EditLevelFormParams = {
  projectId: string;
  id: string;
};

type EditLevelFormInnerProps = RouteComponentProps<EditLevelFormParams> &
  InjectedIntlProps & {
    api: Api;
    history: History;
    notifications: NotificationsStore;
  };

type EditLevelFormValues = Pick<Address, "streetAddress" | "postalCode" | "locality">;
class EditLevelFormInner extends Component<EditLevelFormInnerProps> {
  schema = Yup.object({
    streetAddress: Yup.string().required(),
    postalCode: Yup.string(),
    locality: Yup.string().required(),
  });

  state = {
    address: {
      streetAddress: "",
      postalCode: "",
      locality: "",
    },
    isLevelFetching: false,
    isLevelSubmitting: false,
  };

  async componentDidMount() {
    try {
      this.setState({ isLevelFetching: true });
      const { data: level } = await this.props.api.levels.getOne({
        projectId: parseInt(this.props.match.params.projectId, 10),
        levelId: parseInt(this.props.match.params.id, 10),
      });
      this.setState({
        address: {
          streetAddress: level.address.streetAddress,
          postalCode: level.address.postalCode,
          locality: level.address.locality,
        },
      });
    } catch (err) {
      logger.error(err);
      this.props.notifications.createError(<FormattedMessage id="error.getLevelFailed" />);
    } finally {
      this.setState({ isLevelFetching: false });
    }
  }

  render() {
    const { isLevelFetching, isLevelSubmitting, address } = this.state;

    if (isLevelFetching) {
      return <LoadingSection />;
    }

    return (
      <>
        <Formik validationSchema={this.schema} initialValues={address} onSubmit={this.handleSubmit} enableReinitialize>
          {() => (
            <Form noValidate={true} className={style.form}>
              <div className={style.doubleColumn}>
                <TextField required type="text" name="streetAddress" label={<FormattedMessage id="form.address" />} />
              </div>

              <TextField type="text" name="postalCode" label={<FormattedMessage id="form.zipCode" />} />
              <TextField required type="text" name="locality" label={<FormattedMessage id="form.locality" />} />

              <RequiredFieldLabel />

              <div className={style.actions}>
                <SubmitCancelButtons
                  disabled={isLevelSubmitting}
                  loading={isLevelSubmitting}
                  onCancel={() => this.props.history.goBack()}
                  submitLabel={<FormattedMessage id="actions.save" />}
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  private handleSubmit = async (formValues: EditLevelFormValues) => {
    try {
      this.setState({ isLevelSubmitting: true });
      const projectId = parseInt(this.props.match.params.projectId, 10);
      const levelId = parseInt(this.props.match.params.id, 10);

      await this.props.api.levels.edit({ projectId, levelId, data: { addressAttributes: formValues } });
      this.props.history.push(`/projects/${projectId}`);
    } catch (err) {
      logger.error(err);
      this.props.notifications.createError(<FormattedMessage id="error.editLevelFailed" />);
    } finally {
      this.setState({ isLevelSubmitting: false });
    }
  };
}

export const EditLevelForm = compose<EditLevelFormInnerProps, NoopOuterProps>(
  inject("api", "notifications"),
  withRouter
)(EditLevelFormInner);
