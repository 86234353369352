import { Form, Formik } from "formik";
import keyBy from "lodash-es/keyBy";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import * as yup from "yup";

import { DropdownField } from "nvent-web/components/form/DropdownField";
import { NumberField } from "nvent-web/components/form/NumberField";
import SubmitCancelButtons from "nvent-web/components/SubmitCancelButtons";
import { buildMessage } from "nvent-web/i18n/yup";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProductsStore } from "nvent-web/stores/Products";
import { Accessory } from "nvent-web/types/Accessory";
import { AddProductParams } from "nvent-web/types/AddProductParams";
import { CategoryType } from "nvent-web/types/CategoryType";
import { DropdownOption } from "nvent-web/types/DropdownOption";
import { ProductCategory } from "nvent-web/types/ProductCategory";
import { ProductSku } from "nvent-web/types/ProductSku";

import style from "./AddAccessoryForm.module.scss";

interface FormValues {
  productSkuId: string;
  quantity: number;
}

const initialValues: FormValues = {
  productSkuId: "",
  quantity: 1,
};

interface AddAccessoryFormProps {
  onClose: () => void;
}

interface AddAccessoryFormInnerProps
  extends AddAccessoryFormProps,
    RouteComponentProps<AddProductParams>,
    InjectedIntlProps {
  products: ProductsStore;
  notifications: NotificationsStore;
}

class AddAccessoryFormInner extends Component<AddAccessoryFormInnerProps> {
  @computed
  private get categoryOptions(): DropdownOption[] {
    const { products } = this.props;

    const accessories = products.availableProductCategories.filter(
      (category) => category.type === CategoryType.accessory
    );

    return accessories.map((acc) => ({
      title: acc.identifier,
      key: acc.id.toString(),
    }));
  }

  @observable
  private selectedCategoryId?: number;

  @computed
  private get selectedCategory(): ProductCategory | undefined {
    const { products } = this.props;

    return products.availableProductCategories.find(({ id }) => id === this.selectedCategoryId);
  }

  @computed
  private get products(): Accessory[] {
    return this.props.products.availableProducts.filter(
      ({ productCategoryId }) => productCategoryId === this.selectedCategoryId
    );
  }

  @computed
  private get productSkus(): ProductSku[] {
    const productsById = keyBy(this.products, ({ id }) => id);

    return this.props.products.availableProductSkus.filter(({ productId }) => productId in productsById);
  }

  @computed
  private get productSkuOptions(): DropdownOption[] {
    return this.productSkus.map(({ id, description }) => ({
      title: description,
      key: id.toString(),
    }));
  }

  @computed
  private get schema() {
    return yup.object({
      productSkuId: yup.string().required(),
      quantity: yup
        .number()
        .max(100, buildMessage("error.tooManyAccessories"))
        .min(1, buildMessage("error.notEnoughAccessories")),
    });
  }

  componentDidMount() {
    this.props.products.loadProductsCatalog();
  }

  render() {
    const { intl, products } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={this.schema}>
        {({ dirty, isValid, resetForm }) => (
          <Form noValidate={true}>
            <h3 className={style.title}>{intl.formatMessage({ id: "accessory.add.title" })}</h3>
            <p className={style.description}>{intl.formatMessage({ id: "accessory.add.description" })}</p>

            <p className={style.notice}>{intl.formatMessage({ id: "accessory.add.notice" })}</p>

            <DropdownField
              name="category"
              label={intl.formatMessage({ id: "form.accessoryCategory" })}
              options={this.categoryOptions}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                this.onCategoryChange(event);
                resetForm();
              }}
              value={this.selectedCategoryId ? this.selectedCategoryId : ""}
              placeholder={intl.formatMessage({ id: "form.accessoryCategory.selectPlaceholder" })}
              required
            />

            {this.selectedCategory && (
              <>
                <DropdownField
                  name="productSkuId"
                  label={intl.formatMessage({ id: "form.accessoryName" })}
                  options={this.productSkuOptions}
                  placeholder={intl.formatMessage({ id: "form.accessoryName.selectPlaceholder" })}
                  required
                />
                <NumberField
                  name="quantity"
                  label={intl.formatMessage({ id: "form.accessoriesQuantity.label" })}
                  placeholder={intl.formatMessage({ id: "form.accessoriesQuantity.placeholder" })}
                />
              </>
            )}

            <div className={style.actions}>
              <SubmitCancelButtons
                onCancel={this.handleCancel}
                disabled={!dirty || !isValid}
                loading={products.isProductCreating}
                submitLabel={<FormattedMessage id="actions.add" />}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  private onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.selectedCategoryId = Number(event.target.value);
  };

  private handleCancel = () => {
    this.props.onClose();
  };

  private handleSubmit = async ({ productSkuId, quantity }: FormValues) => {
    const {
      products,
      match: {
        params: { projectId, roomId },
      },
      notifications,
    } = this.props;
    try {
      await products.createProductSku(Number(projectId), Number(roomId), {
        productSkuId: Number(productSkuId),
        quantity: Number(quantity),
      });
      this.props.onClose();
    } catch (error) {
      logger.error(error);
      notifications.createError(<FormattedMessage id="error.productUnavailable" />);
    }
  };
}

const AddAccessoryForm = compose<AddAccessoryFormInnerProps, AddAccessoryFormProps>(
  injectIntl,
  inject("products", "notifications"),
  withRouter,
  observer
)(AddAccessoryFormInner);

export default AddAccessoryForm;
