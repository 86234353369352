import classNames from "classnames";
import { Field, FieldConfig } from "formik";
import { FunctionComponent } from "react";

import { ReactComponent as CheckMark } from "nvent-web/assets/svg/check-mark.svg";
import { ReactComponent as CrossMark } from "nvent-web/assets/svg/cross-mark.svg";

import { HelpTip } from "../HelpTip";

import style from "./AbstractField.module.scss";
import { FieldError } from "./FieldError";

type InputProps = Omit<React.InputHTMLAttributes<HTMLElement>, keyof FieldConfig | "className">;

export interface AbstractFieldProps extends InputProps, FieldConfig {
  label: React.ReactNode;
  className?: {
    wrapper?: string;
    label?: string;
    input?: string;
    inputContainer?: string;
    errorContainer?: string;
    errorMessage?: string;
  };
  prompt?: React.ReactNode;
  required?: boolean;
  helpTip?: React.ReactNode;
  hideErrorMessage?: boolean;
  showMark?: boolean;
  stateColor?: boolean;
  success?: boolean;
}

export const AbstractField: FunctionComponent<AbstractFieldProps> = ({
  name,
  label,
  helpTip,
  placeholder,
  prompt,
  className = {},
  required,
  hideErrorMessage,
  showMark,
  stateColor,
  success,
  ...fieldProps
}) => (
  <div className={classNames(style.wrapper, className.wrapper)}>
    <label className={classNames(style.label, className.label)} htmlFor={name}>
      <span>
        {label}
        {required && "*"}
      </span>
      {helpTip && (
        <HelpTip direction="up" inline={false} className={style.helpTip}>
          {helpTip}
        </HelpTip>
      )}
    </label>

    <div className={classNames(style.inputContainer, className.inputContainer)}>
      <Field
        className={classNames(style.input, className.input, {
          [style.isCorrect]: stateColor && success,
          [style.isInvalid]: stateColor && !success,
        })}
        id={name}
        name={name}
        placeholder={placeholder}
        {...fieldProps}
      />
      {prompt && <p className={style.prompt}>{prompt}</p>}
      {!hideErrorMessage && <FieldError name={name} />}
      {showMark && success && <CheckMark className={style.mark} />}
      {showMark && !success && <CrossMark className={style.mark} />}
    </div>
  </div>
);
