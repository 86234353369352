import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import style from "./AddRoom.module.scss";
import AddRoomForm from "./components/AddRoomForm";

export const AddRoom: FunctionComponent = () => (
  <div className={style.container}>
    <PageTitle>
      <FormattedMessage id="room.add.title" />
    </PageTitle>
    <AddRoomForm />
  </div>
);

export default AddRoom;
