import { AxiosResponse } from "axios";

import { Installer } from "nvent-web/types/Installer";

import Resource from "./Resource";

export class TeamInstallersResource extends Resource {
  async getAll(): Promise<AxiosResponse<Installer[]>> {
    return this.http.get<Installer[]>("/team/installers");
  }
}
