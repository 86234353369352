import { Component } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import UserSettingsForm from "./components/UserSettingsForm";

class UserSettings extends Component {
  render() {
    return (
      <>
        <PageTitle>
          <FormattedMessage id="user.info" />
        </PageTitle>
        <UserSettingsForm />
      </>
    );
  }
}

export default UserSettings;
