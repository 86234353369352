import { History } from "history";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import { LoadingSection } from "nvent-web/components/LoadingSection";
import RoomForm from "nvent-web/components/RoomForm";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { NewRoomFormValues } from "nvent-web/types/NewRoomFormValues";
import { Room } from "nvent-web/types/Room";

interface EditRoomParams {
  projectId: string;
  roomId: string;
}

interface EditRoomFormInnerProps extends RouteComponentProps<EditRoomParams>, InjectedIntlProps {
  rooms: RoomsStore;
  history: History;
}

class EditRoomFormInner extends Component<EditRoomFormInnerProps> {
  @computed
  get projectId(): number {
    return parseInt(this.props.match.params.projectId, 10);
  }

  @computed
  get roomId(): number {
    return parseInt(this.props.match.params.roomId, 10);
  }

  componentDidMount() {
    this.props.rooms.getRoom(this.projectId, this.roomId);
  }

  render() {
    const { roomDetails, areRoomDetailsLoading, isRoomCreating } = this.props.rooms;

    if (areRoomDetailsLoading) {
      return <LoadingSection />;
    }

    return <>{roomDetails && this.renderForm(roomDetails, isRoomCreating)}</>;
  }

  private initialValues(room: Room): NewRoomFormValues {
    return {
      name: room.name,
      floorConstruction: room.floorConstruction || null,
      floorFinish: room.floorFinish || null,
      area: room.area,
      installableArea: room.installableArea,
      areaType: room.areaType,
    };
  }

  private renderForm(values: Room, isRoomCreating: boolean) {
    return (
      <RoomForm
        isSubmitting={isRoomCreating}
        onCancel={this.handleCancel}
        initialValues={this.initialValues(values)}
        handleSubmit={this.handleSubmit}
      />
    );
  }

  private handleSubmit = async (formValues: NewRoomFormValues) => {
    const { rooms, history } = this.props;

    await rooms.updateRoom(formValues, this.projectId, this.roomId);
    history.goBack();
  };

  private handleCancel = () => {
    this.props.history.goBack();
  };
}

const EditRoomForm = compose<EditRoomFormInnerProps, Record<string, unknown>>(
  inject("rooms"),
  injectIntl,
  withRouter,
  observer
)(EditRoomFormInner);

export default EditRoomForm;
