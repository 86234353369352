import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";

type ContentDispositionType = "inline" | "attachment";

export class BlobResponse {
  private response: AxiosResponse;
  private contentDisposition: { dispositionType: ContentDispositionType; filename: string | null };

  constructor(response: AxiosResponse) {
    const contentDispositionHeader = response.headers["content-disposition"] as string | null;

    this.response = response;
    this.contentDisposition = this.parseContentDisposition(contentDispositionHeader || "");
  }

  get filename() {
    return this.contentDisposition.filename;
  }

  saveAs(fallbackFilename: string) {
    const filename = this.filename || fallbackFilename;
    const fileType = filename.split(".").pop();
    const blob = new Blob([this.response.data], { type: fileType });
    saveAs(blob, filename);
  }

  // This method parses Content-Disposition header value such as 'attachment; filename*="filename.jpg"'
  // into { dispositionType: "attachment", filename: "filename.jpg" } object.
  // this method is not compatible with values like "Content-Disposition: form-data; ..."
  // as they should not take place in blob responses.
  private parseContentDisposition(rawContentDisposition: string) {
    const filenameRegex = new RegExp(`filename*?="(?<filename>.+)"`);
    const fieldsWithValues = rawContentDisposition.split(";");
    const type = (fieldsWithValues[0] || "inline") as ContentDispositionType;
    const extractedGroups = filenameRegex.exec(fieldsWithValues[1])?.groups;
    const filename = (extractedGroups && extractedGroups["filename"]) ?? null;

    return { dispositionType: type, filename };
  }
}
