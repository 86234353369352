import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import PageTitle from "nvent-web/App/components/PageTitle";

import EditRoomForm from "./components/EditRoomForm";
import style from "./EditRoom.module.scss";

const EditRoom: FunctionComponent = () => (
  <div className={style.container}>
    <PageTitle>
      <FormattedMessage id="rooms.edit.title" />
    </PageTitle>
    <EditRoomForm />
  </div>
);

export default EditRoom;
