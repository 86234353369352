import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import LevelSrc from "nvent-web/assets/png/level.png";
import RoomSrc from "nvent-web/assets/png/room.png";

import { LoadingSpinner } from "../LoadingSpinner";
import Modal from "../Modal";

import style from "./AddLevelOrRoomModal.module.scss";

type AddLevelOrRoomModalProps = {
  projectId: number;
  parentLevelId?: number;
  isOpen: boolean;
  onClose: () => void;
  onAddLevel: (id?: number) => void;
  isAddLevelLoading: boolean;
  disableLevels?: boolean;
};
export const AddLevelOrRoomModal = ({
  isOpen,
  projectId,
  parentLevelId,
  isAddLevelLoading,
  disableLevels,
  onClose,
  onAddLevel,
}: AddLevelOrRoomModalProps) => {
  const url = new URL(`/projects/${projectId}/rooms/new`, window.location.origin);
  if (parentLevelId) {
    url.searchParams.append("levelId", parentLevelId.toString());
  }

  const linkTo = {
    pathname: url.pathname,
    search: url.search,
  };

  return (
    <Modal isOpen={isOpen} center handleClose={onClose} hasCloseButton innerClass={style.modal}>
      <h2 className={style.heading}>
        <FormattedMessage id="addLevelOrRoomModal.title" />
      </h2>
      <div className={style.content}>
        <button
          className={classNames(style.button, style.tile, { [style.disabled]: isAddLevelLoading || disableLevels })}
          disabled={disableLevels || isAddLevelLoading}
          onClick={() => onAddLevel(parentLevelId)}
        >
          {isAddLevelLoading ? (
            <LoadingSpinner className={style.spinner} />
          ) : (
            <>
              <img src={LevelSrc} className={style.image} alt="" />
              <div>
                <h3 className={style.tileTitle}>
                  <FormattedMessage id="addLevelOrRoomModal.level.title" />
                </h3>
                <p className={style.tileDescription}>
                  <FormattedMessage id="addLevelOrRoomModal.level.description" />
                </p>
              </div>
            </>
          )}
        </button>
        <Link to={linkTo} className={classNames(style.link, style.tile, { [style.disabled]: isAddLevelLoading })}>
          <img src={RoomSrc} className={style.image} alt="" />
          <div>
            <h3 className={style.tileTitle}>
              <FormattedMessage id="addLevelOrRoomModal.room.title" />
            </h3>
            <p className={style.tileDescription}>
              <FormattedMessage id="addLevelOrRoomModal.room.description" />
            </p>
          </div>
        </Link>
      </div>
    </Modal>
  );
};
