import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useBaseUrl } from "nvent-web/hooks/useBaseUrl";

import { toAppAuth0User } from "./AppAuth0User";
import { auth0LogoutCallbackPath } from "./auth0Config";
import { Auth0User } from "./Auth0User";

export type AppAuth0 = ReturnType<typeof useCreateAppAuth0>;

export function useCreateAppAuth0() {
  const history = useHistory();
  const baseUrl = useBaseUrl();

  const auth0 = useAuth0();

  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout: baseLogout,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = auth0;

  const baseUser = auth0.user as Auth0User | undefined;
  const user = useMemo(() => baseUser && toAppAuth0User(baseUser), [baseUser]);

  const handleRedirectCallback = useCallback(
    (appState?: { returnTo?: string }) => {
      const { returnTo = "/" } = appState ?? {};

      history.replace(returnTo);
    },
    [history]
  );

  const logout = useCallback(async () => {
    return baseLogout({ logoutParams: { returnTo: `${baseUrl}${auth0LogoutCallbackPath}` } });
  }, [baseLogout, baseUrl]);

  const getApiTokenSilently = useCallback(async () => {
    return getAccessTokenSilently();
  }, [getAccessTokenSilently]);

  const getIdToken = useCallback(async () => {
    return (await getIdTokenClaims())?.__raw;
  }, [getIdTokenClaims]);

  return {
    isLoading,
    isAuthenticated,
    user,
    error,
    loginWithRedirect,
    handleRedirectCallback,
    logout,
    getApiTokenSilently,
    getIdToken,
    getIdTokenClaims,
  };
}
