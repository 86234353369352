import { FunctionComponent } from "react";
import { FormattedHTMLMessage } from "react-intl";

import { ReactComponent as Logo } from "nvent-web/assets/svg/app-logo.svg";

import style from "./AppLogo.module.scss";

export const AppLogo: FunctionComponent = () => (
  <div className={style.logoWrapper}>
    <Logo className={style.logo} />
    <span className={style.subtitle}>
      <FormattedHTMLMessage id="login.logoSubtitle" />
    </span>
  </div>
);
