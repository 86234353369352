import { inject } from "mobx-react";
import { MouseEventHandler, useState } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import dropdownStyles from "nvent-web/components/form/DropdownField.module.scss";
import Api from "nvent-web/services/Api";
import { Level } from "nvent-web/types/Level";

import { PrimaryBlueButton, SecondaryButton } from "../Button";

import style from "./MoveLevelFinishedRoomsForm.module.scss";

interface MoveLevelFinishedRoomsFormInnerProps {
  projectId: number;
  projectLevels: Level[];
  levelId: number;
  onCancel: () => void;
  onSave: (newLevelId?: number) => void;
}
interface MoveLevelFinishedRoomsFormOuterProps extends MoveLevelFinishedRoomsFormInnerProps, InjectedIntlProps {
  api: Api;
}

type SelectedLevelIds = [number?, number?, number?, number?];
type LevelOption = { label: string; value: number };
type LevelOptions = [Array<LevelOption>, Array<LevelOption>, Array<LevelOption>, Array<LevelOption>];

const MAX_DEPTH_INDEX = 3;

export const MoveLevelFinishedRoomsFormInner = ({
  projectId,
  projectLevels,
  levelId,
  onSave,
  onCancel,
  intl,
  api,
}: MoveLevelFinishedRoomsFormOuterProps) => {
  const [selectedLevelIds, setSelectedLevelIds] = useState<SelectedLevelIds>([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [levelOptions, setLevelOptions] = useState<LevelOptions>([
    transformResponseDataToOptions(projectLevels.filter(({ id }) => id !== levelId)),
    [],
    [],
    [],
  ]);
  const [isLoadingMoreLevels, setIsLoadingMoreLevels] = useState<boolean>(false);
  const firstNotSelectedLevelIndex = firstUndefinedElementIndex(selectedLevelIds);
  const levelIndexToBeSelected = firstNotSelectedLevelIndex !== undefined ? firstNotSelectedLevelIndex : 3;

  const handleSelectChange = async (selectIndex: number, selectValue?: number) => {
    setSelectedLevelIds(
      transformSelectedLevelsIds({
        indexThatChanges: selectIndex,
        currentSelectedLevels: selectedLevelIds,
        newLevelIdValue: selectValue,
      })
    );

    if (selectIndex === MAX_DEPTH_INDEX) return;

    // Empty options for levels deeper than select's level
    const resetLevelOptions = levelOptions.map((oldLevelOption, index) =>
      index > selectIndex ? [] : oldLevelOption
    ) as LevelOptions;
    setLevelOptions(resetLevelOptions);

    // Replace options for select with fetched ones
    setIsLoadingMoreLevels(true);
    const { data } = selectValue
      ? await api.levels.getOne({ projectId, levelId: selectValue })
      : { data: { levels: [] } };
    const nextLevelChildren: Level[] = data.levels.filter(({ id }) => id !== levelId);
    const newLevelOptions: LevelOptions = [...levelOptions];

    newLevelOptions[selectIndex + 1] = transformResponseDataToOptions(nextLevelChildren);
    for (let i = selectIndex + 2; i < MAX_DEPTH_INDEX; i++) {
      newLevelOptions[i] = [];
    }

    setIsLoadingMoreLevels(false);
    setLevelOptions(newLevelOptions);
  };

  const handleCancelClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onCancel();
  };

  const handleSaveClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (levelIndexToBeSelected === 0) onSave(undefined);
    if (levelIndexToBeSelected === MAX_DEPTH_INDEX) onSave(selectedLevelIds[MAX_DEPTH_INDEX]);

    return onSave(selectedLevelIds[levelIndexToBeSelected - 1]);
  };

  return (
    <form className={style.form}>
      <p className={style.formTitle}>
        <FormattedMessage id={"form.transferRoomsLevelTitle"} />
      </p>
      <p className={style.formDescription}>
        <FormattedMessage id={"form.transferRoomsLevelDescription"} />
      </p>

      {selectedLevelIds.map((value, index) => {
        const isDeeperThanLevelToBeSelected = index > levelIndexToBeSelected;
        const waitsForLevelsToBeFetched = index !== 0 && index === levelIndexToBeSelected && isLoadingMoreLevels;
        const isDisabled = index !== 0 && (isDeeperThanLevelToBeSelected || waitsForLevelsToBeFetched);

        return (
          <select
            key={`move-rooms-form-select-level-${index}`}
            onChange={({ target }) => handleSelectChange(index, parseInt(target.value))}
            value={value || ""}
            disabled={isDisabled}
            className={dropdownStyles.select}
          >
            <option value={""} className={dropdownStyles.placeholder}>
              {intl.formatMessage({
                id: waitsForLevelsToBeFetched ? "form.loading" : "form.selectLevelPlaceholder",
              })}
            </option>
            {levelOptions[index].map(({ value, label }) => (
              <option key={`move-rooms-form-select-level-${index}-option-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        );
      })}

      <p className={style.noLevelDescription}>
        <FormattedMessage id={"form.transferRoomsToNoLevelDescription"} />
      </p>

      <div className={style.buttonsContainer}>
        <SecondaryButton onClick={handleCancelClick}>Close</SecondaryButton>
        <PrimaryBlueButton onClick={handleSaveClick}>Save</PrimaryBlueButton>
      </div>
    </form>
  );
};

const transformSelectedLevelsIds = ({
  indexThatChanges,
  newLevelIdValue,
  currentSelectedLevels,
}: {
  indexThatChanges: number;
  newLevelIdValue?: number;
  currentSelectedLevels: SelectedLevelIds;
}): SelectedLevelIds => {
  if (indexThatChanges === -1) return [undefined, undefined, undefined];

  return currentSelectedLevels.map((levelId, index) => {
    if (index > indexThatChanges) return undefined;
    if (index === indexThatChanges) return newLevelIdValue;

    return levelId;
  }) as SelectedLevelIds;
};

const transformResponseDataToOptions = (data: Array<Level>) => data.map(({ id, name }) => ({ value: id, label: name }));

const firstUndefinedElementIndex = <T,>(array: Array<T>) => {
  const firstUndefinedElementIndex = array.findIndex((el) => !el);

  return firstUndefinedElementIndex !== -1 ? firstUndefinedElementIndex : undefined;
};

export const MoveLevelFinishedRoomsForm = compose<
  MoveLevelFinishedRoomsFormOuterProps,
  MoveLevelFinishedRoomsFormInnerProps
>(
  injectIntl,
  inject("api")
)(MoveLevelFinishedRoomsFormInner);
