import * as yup from "yup";

import { NewProductSpec } from "nvent-web/types/NewProductSpec";

const AWAITING_PROJECT_KEY = "awaitingProject";

export interface IndependentSelectionGuideRoomDetails {
  floorConstruction: string;
  floorFinish: string;
  area: number;
  installableArea: number;
  areaType: string;
  projectName?: string;
}

export const selectionGuideRoomDetailsSchema = yup
  .object({
    floorConstruction: yup.string().required(),
    floorFinish: yup.string().required(),
    area: yup.number().required(),
    installableArea: yup.number().required(),
    areaType: yup.string().required(),
    projectName: yup.string(),
  })
  .required();

const selectionGuideProductSkusSchema = yup
  .array(
    yup
      .object({
        productSkuId: yup.number().required(),
        quantity: yup.number().default(undefined),
        length: yup.number().default(undefined),
      })
      .required()
  )
  .required();

const awaitingProjectSchema = yup
  .object({
    roomDetails: selectionGuideRoomDetailsSchema,
    productSkus: selectionGuideProductSkusSchema,
  })
  .required();

interface AwaitingProject {
  roomDetails: IndependentSelectionGuideRoomDetails;
  productSkus: NewProductSpec[];
}

export const setAwaitingProject = (values: AwaitingProject) => {
  localStorage.setItem(AWAITING_PROJECT_KEY, JSON.stringify(values));
};

export const clearAwaitingProject = () => {
  localStorage.removeItem(AWAITING_PROJECT_KEY);
};

export const getAwaitingProject = (): AwaitingProject | null => {
  const awaitingRoomValues = localStorage.getItem(AWAITING_PROJECT_KEY);

  if (!awaitingRoomValues) {
    return null;
  }

  try {
    return awaitingProjectSchema.validateSync(JSON.parse(awaitingRoomValues));
  } catch (error) {
    return null;
  }
};
