import addMilliseconds from "date-fns/addMilliseconds";
import { action, computed, observable } from "mobx";
import { now } from "mobx-utils";
import { ReactNode } from "react";

import { auth0ErrorCode, auth0ErrorDescription, isAuth0Error } from "nvent-web/services/auth0/auth0Error";

export class NotificationsStore {
  @observable all: Notification[] = [];

  @computed
  get visible(): Notification[] {
    return this.all.filter((n) => n.isVisible);
  }

  @action
  push(notification: Notification) {
    this.all.push(notification);
  }

  @action
  create(message: ReactNode, options?: NotificationOptions): Notification {
    const notification = new Notification(message, options);
    this.push(notification);

    return notification;
  }

  @action
  createInfo(message: ReactNode) {
    return this.create(message, { theme: "info" });
  }

  @action
  createSuccess(message: ReactNode) {
    return this.create(message, { theme: "success" });
  }

  @action
  createWarning(message: ReactNode) {
    return this.create(message, { theme: "warning" });
  }

  @action
  createError(message: ReactNode) {
    return this.create(message, { theme: "danger" });
  }

  @action
  createGenericError(error: any, message: ReactNode = "Error") {
    if (isAuth0Error(error)) {
      return this.createError(
        <>
          {message} ({auth0ErrorCode(error)}): {auth0ErrorDescription(error)}
        </>
      );
    } else {
      return this.createError(
        <>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          {message}: {error.toString()}
        </>
      );
    }
  }
}

export interface NotificationOptions {
  theme?: "success" | "danger" | "warning" | "info";
  expiresIn?: number;
  expiresAt?: Date;
}

export class Notification {
  private static nextKey = 0;

  readonly key = Notification.nextKey++;
  readonly message: ReactNode;

  @observable theme = "info";
  @observable isClosed = false;
  @observable expirationDate?: Date;

  @computed
  get hasExpired() {
    return this.expirationDate && this.expirationDate.getTime() < now();
  }

  @computed
  get isVisible() {
    return !this.isClosed && !this.hasExpired;
  }

  constructor(message: ReactNode, { theme = "info", expiresIn = 4000, expiresAt }: NotificationOptions = {}) {
    this.message = message;
    this.theme = theme;

    if (expiresIn) {
      this.expirationDate = addMilliseconds(new Date(), expiresIn);
    } else if (expiresAt) {
      this.expirationDate = expiresAt;
    }
  }

  @action
  close = () => (this.isClosed = true);
}
