import { FunctionComponent } from "react";

import style from "./LoadingSection.module.scss";
import { LoadingSpinner } from "./LoadingSpinner";

export const LoadingSection: FunctionComponent = () => (
  <section className={style.section}>
    <LoadingSpinner className={style.spinner} />
  </section>
);
