import { ComponentType, FunctionComponent } from "react";

export function bindProps<P extends object, K extends keyof P>(
  Component: ComponentType<P>,
  boundProps: Pick<P, K>
): FunctionComponent<Omit<P, K>> {
  return (props) => {
    const componentProps = { ...props, ...boundProps } as P;

    return <Component {...componentProps} />;
  };
}
