import { decamelizeKeys } from "humps";

import Resource from "nvent-web/services/resources/Resource";
import { AdminProject, AdminProjectDetails } from "nvent-web-admin/types/AdminProject";
import { Page } from "nvent-web-admin/types/Page";
import { ProjectStatus } from "nvent-web-admin/types/ProjectStatus";

type FilterOption = "finished_at" | "created_at";

export interface DownloadReportOptions {
  state: ProjectStatus;
  pageSize: number | "all";
  filterBy?: FilterOption;
  startDate?: string;
  endDate?: string;
  countryCodes?: string[];
}

export interface FetchProjectsOptions {
  q: string;
  state: ProjectStatus;
  page?: number;
  pageSize?: number | "all";
  sortBy?: string;
  sortDir?: "asc" | "desc";
  startDate?: string;
  endDate?: string;
  filterBy?: FilterOption;
  countryCodes?: string[];
}

export default class ProjectsResource extends Resource {
  getByPage(options: FetchProjectsOptions) {
    return this.http.get<Page<AdminProject>>("/admin/projects", {
      params: { ...decamelizeKeys(options) },
    });
  }
  async getOne(id: string | number) {
    return this.http.get<AdminProjectDetails>(`/admin/projects/${id}`);
  }

  async downloadProjectsReport(options: DownloadReportOptions) {
    await this.client.download({
      url: "admin/projects.xls",
      params: options,
    });
  }

  async downloadProjectReport(projectId: number) {
    return this.http.get<{ url: string }>(`admin/projects/${projectId}/report`);
  }
  async downloadProjectReportV2(projectId: number) {
    return this.http.post<{ url: string }>(
      `admin/projects/${projectId}/commission_report`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_URL,
      }
    );
  }

  async changeProjectOwner(projectId: number, installerId: string) {
    return this.http.patch(`admin/projects/${projectId}`, {
      project: {
        installer_id: installerId,
      },
    });
  }
}
