import classNames from "classnames";
import { ButtonHTMLAttributes, FunctionComponent } from "react";

import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";
import { bindProps } from "nvent-web/utils/component";

import style from "./Button.module.scss";

type ButtonSize = "extra-small" | "xs" | "small" | "sm" | "medium" | "md" | "large" | "lg";
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit";
  size?: ButtonSize;
  fixedWidth?: boolean;
  loading?: boolean;
  theme?: string;
}

export function buttonClassNames(props: Pick<ButtonProps, "size" | "fixedWidth" | "theme">) {
  const { size = "medium", theme, fixedWidth } = props;

  return classNames(style.button, style[size], theme && style[theme], {
    [style.fixedWidth]: fixedWidth,
  });
}

export const Button: FunctionComponent<ButtonProps> = ({
  type = "button",
  size,
  children,
  className,
  loading,
  theme,
  fixedWidth,
  ...buttonProps
}) => (
  <button {...buttonProps} type={type} className={classNames(buttonClassNames({ size, theme, fixedWidth }), className)}>
    {children}
    {loading && <LoadingSpinner className={style.spinner} small />}
  </button>
);

export const PrimaryRedButton = bindProps(Button, { theme: "primaryRed" });
export const PrimaryGreenButton = bindProps(Button, { theme: "primaryGreen" });
export const SecondaryRedButton = bindProps(Button, { theme: "secondaryRed" });
export const PrimaryBlueButton = bindProps(Button, { theme: "primaryBlue" });
export const SecondaryBlueButton = bindProps(Button, { theme: "secondaryBlue" });
export const SecondaryButton = bindProps(Button, { theme: "secondary" });
