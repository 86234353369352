import classnames from "classnames";
import { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

import style from "./HelpIcon.module.scss";

export const HelpIcon = (props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <button {...props} className={classnames(style.helpIcon, props.className)}>
    ?
  </button>
);
