import { Component } from "react";
import { FormattedMessage } from "react-intl";

import nventLogoUrl from "nvent-web/assets/png/nvent-logo.png";
import Modal from "nvent-web/components/Modal";
import { getPlatform } from "nvent-web/utils/get-platform";

import style from "./AddToHomeScreenPrompt.module.scss";

interface Dict {
  [key: string]: string;
}

export default class AddToHomeScreenPrompt extends Component {
  state = {
    dismissed:
      (getPlatform() === "android" || getPlatform() === "ios") &&
      !localStorage.getItem("addToHomeScreenPrompt.dismissed"),
  };

  render() {
    const platform = getPlatform();

    const menuIconUrls: Dict = {
      android: require("nvent-web/assets/png/android-dots.png"),
      ios: require("nvent-web/assets/png/ios-install-icon.png"),
    };

    const menuIconUrl = menuIconUrls[platform];

    if (!this.state.dismissed) {
      return null;
    }

    return (
      <Modal center isOpen={this.state.dismissed} handleClose={this.handleClose}>
        <div className={style.closeIconWrapper}>
          <div onClick={this.handleClose} className={style.closeIcon}>
            &#10005;
          </div>
        </div>
        <div className={style.modalContent}>
          <h2 className={style.heading}>
            <FormattedMessage id="prompt.addToHomeScreen.title" />
          </h2>
          <p className={style.info}>
            <FormattedMessage id="prompt.addToHomeScreen.description" />
          </p>
          <div className={style.rect}>
            <img alt="" className={style.logo} src={nventLogoUrl} />
          </div>
          <div className={style.instruction}>
            <p className={style.info}>
              <FormattedMessage
                id="prompt.addToHomeScreen.instructions"
                values={{ menuIcon: <img alt="" className={style.addIcon} src={menuIconUrl} /> }}
              />
            </p>
          </div>
        </div>
      </Modal>
    );
  }

  handleClose = () => {
    this.setState({ dismissed: false });
    localStorage.setItem("addToHomeScreenPrompt.dismissed", String(true));
  };
}
