import { Field, FieldProps } from "formik";
import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { Checkbox } from "../Checkbox/Checkbox";

import style from "./AreaTypeCheckboxField.module.scss";

type AreaTypeCheckboxFieldProps = {
  className?: string;
};

interface AreaTypeCheckboxFieldPropsInner extends AreaTypeCheckboxFieldProps, InjectedIntlProps {}

const AreaTypeCheckboxFieldInner: FunctionComponent<AreaTypeCheckboxFieldPropsInner> = (props) => {
  return (
    <div className={props.className}>
      <Field
        name="areaType"
        render={(fieldProps: FieldProps) => (
          <Checkbox
            name={fieldProps.field.name}
            value={fieldProps.field.value === "wet"}
            type="checkbox"
            className={style.checkbox}
            onChange={(value) => {
              fieldProps.form.setFieldValue(fieldProps.field.name, value ? "wet" : "dry");
            }}
            label={<FormattedMessage id="room.areaTypeCheckbox" />}
          />
        )}
      />
    </div>
  );
};

export const AreaTypeCheckboxField = compose<AreaTypeCheckboxFieldPropsInner, AreaTypeCheckboxFieldProps>(injectIntl)(
  AreaTypeCheckboxFieldInner
);
