import noop from "lodash-es/noop";

export function delay(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function never<T = void>() {
  return new Promise<T>(noop);
}
