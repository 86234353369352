import classNames from "classnames";
import identity from "lodash-es/identity";
import { ChangeEvent, FunctionComponent, InputHTMLAttributes, useCallback, useMemo } from "react";

import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";

import { buttonClassNames, ButtonProps } from "..";

import style from "./FileButton.module.scss";

type ForwardedButtonKeys = "size" | "fixedWidth" | "theme" | "loading";
type ForwardedButtonProps = Pick<ButtonProps, ForwardedButtonKeys>;
type ForwardedInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange" | ForwardedButtonKeys>;

export interface ValidatedFile {
  file: File;
  isValid: boolean;
  errors: {
    type: boolean;
    size: boolean;
  };
}

export const defaultFileType = "image/png,image/jpeg";
export const defaultMaxSize = 10_000_000;

interface FileButtonProps extends ForwardedButtonProps, ForwardedInputProps {
  fileType?: string;
  maxSize?: number;
  onChange(files: ValidatedFile[]): void;
}

const FileButton: FunctionComponent<FileButtonProps> = ({
  children,
  size,
  fixedWidth,
  theme,
  className,
  loading,
  fileType = defaultFileType,
  maxSize = defaultMaxSize,
  onChange,
  ...inputProps
}) => {
  const validTypes = useMemo(() => new Set(fileType.split(",").map((type) => type.trim())), [fileType]);
  const validateFile = useCallback(
    (file: File): ValidatedFile => {
      const errors = {
        type: !validTypes.has(file.type),
        size: file.size > maxSize,
      };
      const hasError = Object.values(errors).some(identity);

      return { file, isValid: !hasError, errors };
    },
    [maxSize, validTypes]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      onChange(files.map(validateFile));
    },
    [onChange, validateFile]
  );

  return (
    <label className={classNames(buttonClassNames({ size, theme, fixedWidth }), style.label, className)}>
      {children}
      <input value="" onChange={handleChange} className={style.input} type="file" accept={fileType} {...inputProps} />
      {loading && <LoadingSpinner className={style.spinner} small />}
    </label>
  );
};

export default FileButton;
