import classNames from "classnames";
import { AnchorHTMLAttributes, FunctionComponent } from "react";

import { buttonClassNames, ButtonProps } from "..";

import style from "./AnchorButton.module.scss";

interface AnchorButtonProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    Pick<ButtonProps, "size" | "fixedWidth" | "theme"> {}

const AnchorButton: FunctionComponent<AnchorButtonProps> = ({
  size,
  theme,
  fixedWidth,
  className,
  children,
  ...anchorProps
}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    {...anchorProps}
    className={classNames(buttonClassNames({ size, theme, fixedWidth }), style.link, className)}
  >
    {children}
  </a>
);

export default AnchorButton;
