import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import ThermostatForm from "nvent-web/components/AddThermostatForm/AddThermostatForm";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProductsStore } from "nvent-web/stores/Products";
import { AddProductParams } from "nvent-web/types/AddProductParams";

interface FormValues {
  productSkuId: string;
}
interface AddThermostatFormProps {
  onClose: () => void;
}

interface AddThermostatFormInnerProps
  extends AddThermostatFormProps,
    RouteComponentProps<AddProductParams>,
    InjectedIntlProps {
  products: ProductsStore;
  notifications: NotificationsStore;
}

class AddThermostatFormInner extends Component<AddThermostatFormInnerProps> {
  @computed
  get thermostatSkuOptions() {
    return this.props.products.availableThermostatSkus.map(({ id, description }) => ({
      key: id.toString(),
      title: description,
    }));
  }

  componentDidMount() {
    this.props.products.loadProductsCatalog();
  }

  render() {
    const { products } = this.props;
    const isSubmitting = products.isProductCreating;

    return (
      <ThermostatForm
        isSubmitting={isSubmitting}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        thermostatOptions={this.thermostatSkuOptions}
      />
    );
  }

  private handleCancel = () => {
    this.props.onClose();
  };

  private handleSubmit = async ({ productSkuId }: FormValues) => {
    const {
      products,
      match: {
        params: { projectId, roomId },
      },
      notifications,
    } = this.props;

    try {
      await products.createProductSku(Number(projectId), Number(roomId), { productSkuId: Number(productSkuId) });
      this.props.onClose();
    } catch (error) {
      logger.error(error);
      notifications.createError(<FormattedMessage id="error.productUnavailable" />);
    }
  };
}

const AddThermostatForm = compose<AddThermostatFormInnerProps, AddThermostatFormProps>(
  injectIntl,
  inject("products", "notifications"),
  withRouter,
  observer
)(AddThermostatFormInner);

export default AddThermostatForm;
