import { inject, observer } from "mobx-react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import AnchorButton from "nvent-web/components/Button/AnchorButton";
import AppStore from "nvent-web/stores/App";
import { catalogLinks } from "nvent-web/utils/catalogLinks";

import style from "./CatalogBar.module.scss";

interface CatalogBarPropsInner {
  app: AppStore;
}

const CatalogBarInner: FunctionComponent<CatalogBarPropsInner> = ({ app }: CatalogBarPropsInner) => (
  <section className={style.signUpBar}>
    <div className={style.signUpBarContent}>
      <h2 className={style.signUpTitle}>
        <FormattedMessage id="certifiedPage.catalog.title" />
      </h2>
      <AnchorButton className={style.signUpButton} href={catalogLinks[app.locale]}>
        <FormattedMessage id="certifiedPage.catalog.button" />
      </AnchorButton>
    </div>
  </section>
);

export const CatalogBar = compose<CatalogBarPropsInner, Record<string, unknown>>(
  inject("app"),
  observer
)(CatalogBarInner);
