import { inject } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { compose } from "recompose";

import Api from "nvent-web/services/Api";
import { useAppAuth0 } from "nvent-web/services/auth0/AppAuth0Context";
import { UserStore } from "nvent-web/stores/User";

export interface AppAuth0ConnectorInnerProps {
  api: Api;
  user: UserStore;
}

export const AppAuth0ConnectorInner: FunctionComponent<AppAuth0ConnectorInnerProps> = (props) => {
  const { api, user: userStore } = props;
  const auth0 = useAppAuth0();

  const { isAuthenticated, isLoading: isAuthenticating, user } = auth0;

  useEffect(() => {
    if (!auth0.isLoading) {
      api.setAuth0(auth0);
    }
  }, [api, auth0]);

  useEffect(() => {
    userStore.auth0 = auth0;
  }, [auth0, userStore]);

  useEffect(() => {
    userStore.isAuthenticated = isAuthenticated;
    userStore.isAuthenticating = isAuthenticating;
    userStore.auth0User = user || null;
  }, [isAuthenticated, isAuthenticating, user, userStore]);

  return null;
};

export const AppAuth0Connector = compose<AppAuth0ConnectorInnerProps, Record<string, unknown>>(inject("api", "user"))(
  AppAuth0ConnectorInner
);
