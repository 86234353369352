import { ReactNode } from "react";
import ReactTooltip from "react-tooltip-lite";

import style from "./Tooltip.module.scss";

type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
  arrowSize?: number;
  className?: string;
  direction?: "top" | "bottom" | "left" | "right";
};

export const Tooltip = ({ content, children, arrowSize = 5 }: TooltipProps) => {
  return (
    <ReactTooltip content={content} arrowSize={arrowSize} tipContentClassName={style.content}>
      {children}
    </ReactTooltip>
  );
};
