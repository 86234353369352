export type Feature = "cable-spacing-warning" | "selection-guide" | "selection-guide-create-project";

export const enabledFeatures = (process.env.REACT_APP_FEATURES || "").split(/\s*,\s*/g) as Feature[];
export const enabledFeaturesSet = new Set(enabledFeatures);

export function isFeatureEnabled(name: Feature) {
  return enabledFeaturesSet.has(name);
}

export function addFeatureClasses(element: HTMLElement = document.body) {
  enabledFeatures.forEach((name) => element.classList.add(`feature-${name}`));
}
