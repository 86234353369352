import { FunctionComponent } from "react";

import CertifiedSrc from "nvent-web/assets/png/certified.png";
import { ReactComponent as LogoNventHorizontal } from "nvent-web/assets/svg/side-menu/logo-nvent-horizontal.svg";
import { ReactComponent as SparkIcon } from "nvent-web/assets/svg/spark.svg";

import style from "./Hero.module.scss";

interface HeroProps {
  imageUrl?: string;
  title?: React.ReactNode;
  descriptionLines?: React.ReactNode[];
}

const Hero: FunctionComponent<HeroProps> = ({ imageUrl, title, descriptionLines = [] }) => {
  return (
    <section className={style.wrapper}>
      <div className={style.photo} style={{ backgroundImage: imageUrl && `url('${imageUrl}')` }}>
        <SparkIcon className={style.spark} />
        <LogoNventHorizontal className={style.nventLogo} />
        <img src={CertifiedSrc} className={style.certified} alt="Certified PRO" />
        <div className={style.content}>
          {title && <h2 className={style.title}>{title}</h2>}
          {descriptionLines.map((description, index) => (
            <p className={style.description} key={index}>
              {description}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
