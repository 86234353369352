import classNames from "classnames";
import { FunctionComponent } from "react";

import style from "./CountryFlag.module.scss";

export const CountryFlag: FunctionComponent<{ countryCode: string; className?: string }> = ({
  countryCode,
  className = "",
}) => {
  const getFlagSvg = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return require(`nvent-web/assets/svg/country-flags/${countryCode.toLowerCase()}.svg`);
    } catch (error) {
      return "";
    }
  };

  return <img src={getFlagSvg()} alt={countryCode} className={classNames(style.flag, className)} />;
};
