export const countryCodes = [
  "SE",
  "GB",
  "AL",
  "AD",
  "AT",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IL",
  "IT",
  "KZ",
  "LV",
  "LI",
  "LT",
  "LU",
  "MK",
  "MD",
  "MC",
  "ME",
  "NO",
  "PL",
  "PT",
  "RO",
  "RU",
  "RS",
  "SK",
  "ES",
  "CH",
  "NL",
  "TR",
  "UA",
];
