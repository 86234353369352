import { FunctionComponent } from "react";

import { ButtonProps, PrimaryRedButton } from "nvent-web/components/Button";

import style from "./SubmitButton.module.scss";

export interface RequiredFieldLabelProps {
  className?: string;
}

export const SubmitButton: FunctionComponent<ButtonProps> = (props) => (
  <PrimaryRedButton type="submit" size="large" fixedWidth className={style.submitButton} {...props}>
    {props.children}
  </PrimaryRedButton>
);
