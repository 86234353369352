import classnames from "classnames";
import { FunctionComponent } from "react";

import { ReactComponent as CloseIcon } from "nvent-web/assets/svg/delete.svg";

import style from "./DeleteIcon.module.scss";

interface DeleteIconProps {
  onDelete: () => void;
  className?: string;
}

const DeleteIcon: FunctionComponent<DeleteIconProps> = ({ onDelete, className }) => {
  return <CloseIcon onClick={onDelete} className={classnames(style.closeIcon, className)} />;
};

export default DeleteIcon;
