import { useEffect, useRef } from "react";
import { useInterval } from "react-use";

/**
 * Custom hook that creates an interval that invokes a callback function.
 * First, it triggers callback on mount then performs the interval invocations.
 * @param callback - The function to be invoked at mount an then at each interval.
 * @param interval - The time, in milliseconds, between each invocation of the callback, 5000ms is default.
 * @public
 * @example
 * ```tsx
 * const [randomNumber, setRandomNumber] = useState();
 * const setIntervalActive = useInterval(() => setRandomNumber(Math.random()), 1000);
 *
 * const handleButtonClick = () => {
 *   setIntervalActive(false);
 * }
 *
 * return (
 *   <>
 *     <p>Your random number is: {randomNumber}</p>
 *     <button onClick={handleButtonClick}>Stop shuffling</button>
 *   </>
 * );
 * ```
 */

type PollingParams = {
  isActive?: boolean;
  interval?: number;
  fetchOnMount?: boolean;
};

export const usePolling = (callback: () => void, params: PollingParams = {}) => {
  const savedCallback = useRef(callback);
  const { isActive = true, interval = 5000, fetchOnMount = true } = params;
  const shouldFetch = useRef(fetchOnMount);
  savedCallback.current = callback;

  useEffect(() => {
    if (shouldFetch.current) {
      savedCallback.current();
      shouldFetch.current = false;
    }
  }, []);

  useInterval(savedCallback.current, isActive ? interval : null);
};
